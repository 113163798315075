import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import {history} from "../store/global-store";
import languageReducer from "./languageReducer";
import loginReducer from "./loginReducer";
import appReducer from "./appReducer";
import dashboardReducer from "./dashboard";
import showQRCodeReducer from "./showQRCodeReducer";
import manageAccountReducer from "./manageAccountReducer";
import enrollReducer from "./enrollReducer";
import dischargeReducer from "./dischargeReducer";
import transferPatientReducer from "./transferPatientReducer";
import reportReducer from "./reportReducer";
import thresholdReducer from "./thresholdReducer";
import liveTileReducer from "./liveTileReducer";
import showOspMgrQrCodeReducer from "./osp-mgr/showQRCodeReducer";
import setupWifiReducer from "./osp-mgr/setupWifiReducer";
import cuBindingReducer from "./osp-mgr/cuBinding";

export default () => {
    return combineReducers({
        router: connectRouter(history),
        languageReducer,
        loginReducer,
        appReducer,
        dashboardReducer,
        showQRCodeReducer,
        manageAccountReducer,
        enrollReducer,
        dischargeReducer,
        transferPatientReducer,
        reportReducer,
        thresholdReducer,
        liveTileReducer,
        showOspMgrQrCodeReducer,
        setupWifiReducer,
        cuBindingReducer,
    });
}