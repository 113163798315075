import React, {useState} from "react";
import "./IrespDropdown.css";
import {translateDefaultOption, translateValueOption} from "../../utils/message-wrapper";

export function IrespDropdown({options, handleSelect, value}) {
    const [state, setState] = useState({});

    const handleChange = (val) => {
        setState({selected: true, value: val.target.value});
        handleSelect(val.target.value);
    };

    return (
        <div className="iresp-dropdown">
            <div className="select">
                <select
                    value={value || state.value}
                    className={state.selected || value ? "selected" : ""}
                    onChange={handleChange}>
                    {options.map((o, idx) => {
                        if (idx === 0 && typeof o === 'string') return translateDefaultOption(o);
                        return o;
                    })}
                    {options.length <= 1 ? translateValueOption(`No record found`, "", true) : undefined }
                </select>
                <div className="select-icon"/>
            </div>
        </div>
    );
}