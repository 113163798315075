import {connect} from 'react-redux'
import {Login} from "./Login";
import {loginActions} from "../../redux/actions/loginAction";
import {appAction} from "../../redux/actions/appAction";

const mapStateToProps = state => ({
    error: state.loginReducer.error,
    isDuplicatedLogin: state.loginReducer.isDuplicatedLogin,
    // esp_country_code: state.loginReducer.country_code,
    roles: [
        "ADMIN",
        "MANAGER"
    ],
});

function mapDispatchToProps(dispatch) {
    return ({
        doLogin: (params, pusher) => {
            dispatch(loginActions.doLogin(params, pusher))
        },
        checkLogin: (params, pusher) => {
            dispatch(loginActions.checkLogin(params, pusher))
        },
        getCountryCode: () => dispatch(loginActions.getCountryCode()),
        setCountryCode: (countryCode) => dispatch(loginActions.setCountryCode(countryCode)),
        setError: (error) => dispatch(loginActions.setError(error)),
        duplicateLoginResponse: (response) => dispatch(loginActions.duplicateLoginResponse(response)),
        requestResetPassword: (info) => dispatch(loginActions.requestResetPassword(info)),
        openLoading: () => dispatch(appAction.openLoading()),
        closeLoading: () => dispatch(appAction.closeLoading()),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);