import React, {useEffect, useLayoutEffect, useState} from "react";
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";

import "./PrintPreview.css";

// external css
import "../../../../libs/css/index.css";
import "../../../../libs/css/layout.css";
import "../../../../libs/css/sleep_trend.css";
import "../../../../libs/css/vital_sign.css";

// external js
import {updateChart, clearChart} from "../../../../libs/js/sleep_trend";
import {zoom} from "../../../../libs/js/zoom";
import {updateVitalSignChart, clearVitalSignChart} from "../../../../libs/js/listener";

export function PrintPreview(props) {

    useEffect(() => {
        // zoom();
    }, []);

    const pressPrint = () => {
        const doc = new jsPDF("landscape", "mm", "a4");
        const el = document.getElementById("patient-report");
        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();

        html2canvas(el, {width: 2000, height: 1400}).then(canvas => {
            let img = canvas.toDataURL("image/png,0.5");
            doc.addImage(img, 'JPEG', 0, 0, width, height);
            doc.save(`OAC_Report_${moment().format("YYYYMMDD HH:mm:ss")}`);

        });
    };

    return (
        <div className="print-preview">
            <div id="report-content" className="report-content">
                <div className={props.typeOfReport !== 1 ? "" : "hidden"}>
                    <div className="rept-header">
                        <div className="rept-header-left">
                            <div className="center-name">Fujimoto Nursing Care</div>
                            <div className="patient-name">
                                {/*<span className="patient-name-txt">{t("Resident name:")}&nbsp;</span>*/}
                                <span className="patient-name-val">Akira Yamaguchi</span>
                            </div>
                        </div>
                        <div className="rept-header-center">
                            <div>OAC RESIDENT REPORT</div>
                        </div>
                        <div className="rept-header-right">
                            <div className="bed-name">
                                <span className="bed-name-txt">Bed name: </span>
                                <span className="bed-name-val">R1-B2</span>
                            </div>
                            <div className="date-report">
                                <span className="date-report-txt">Date/Time of report: </span>
                                <span className="date-report-val">2022-05-08 / 13:25</span>
                            </div>
                        </div>
                    </div>
                    <div className="sleep-pharse-trend">

                    </div>
                    <div className="sleep-trend">

                    </div>
                </div>
                <div className={props.typeOfReport === 2 ? "" : "hidden"}>
                    <div className="rept-header">
                        <div className="rept-header-left">
                            <div className="center-name">Fujimoto Nursing Care</div>
                            <div className="patient-name">
                                <span className="patient-name-txt">Resident name: </span>
                                <span className="patient-name-val">Akira Yamaguchi</span>
                            </div>
                        </div>
                        <div className="rept-header-center">
                            <div>OAC RESIDENT REPORT</div>
                        </div>
                        <div className="rept-header-right">
                            <div className="bed-name">
                                <span className="bed-name-txt">Bed name: </span>
                                <span className="bed-name-val">R1-B2</span>
                            </div>
                            <div className="date-report">
                                <span className="date-report-txt">Date/Time of report: </span>
                                <span className="date-report-val">2022-05-08 / 13:25</span>
                            </div>
                        </div>
                    </div>
                    <div className="rept-content">

                    </div>
                </div>
            </div>
        </div>
    );
}