import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import "./EspDropdown.css";
import {FormattedMessage} from "react-intl";

export function EspDropdown(props) {
    return <FormattedMessage id={props.placeholder}>
        {
            placeholder => <Select
                styles={{...props.style}}
                isSearchable={false}
                options={props.options}
                value={props.value}
                placeholder={placeholder}
                className="esp-select"
                classNamePrefix="esp-select"
                onChange={props.onChange}
                noOptionsMessage={() => props.noOptionsMessage || ""}
            />
        }
    </FormattedMessage>;
}

EspDropdown.propTypes = {
    options: PropTypes.array,
    value: PropTypes.object,
    onChange: PropTypes.func,
};