import {dataProvider} from "../../connections/net";
import {appAction} from "./appAction";

export const SET_ERROR = "manageAccountAction/action/SET_ERROR";
export const SET_STATE = "manageAccountAction/action/SET_STATE";
export const GET_NURSES = "manageAccountAction/action/GET_DATA";
export const CLEAR_DATA = "manageAccountAction/action/CLEAR_DATA";

export const manageAccountAction = {
    getListOfNurses: () => async dispatch => {
        dispatch(appAction.openLoading());
        try {
            const {status_code, oah} = await dataProvider.getListOfNurses();
            if (status_code === 0 && oah) {
                dispatch({type: GET_NURSES, payload: oah});
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
        dispatch(appAction.closeLoading());
    },
    addNurse: (nurse) => async dispatch => {
        try {
            const {status_code} = await dataProvider.addNurse(nurse);
            if (status_code === 0) {
                dispatch(manageAccountAction.getListOfNurses());
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
    },
    removeNurse: (nurse) => async dispatch => {
        try {
            const {status_code} = await dataProvider.removeNurse(nurse);
            if (status_code === 0) {
                dispatch(manageAccountAction.getListOfNurses());
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
    },
    editNurse: (nurse) => async dispatch => {
        try {
            const {status_code} = await dataProvider.updateNurse(nurse);
            if (status_code === 0) {
                dispatch(manageAccountAction.getListOfNurses());
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
    },
    assignBed: (bed) => async dispatch => {
        try {
            const {status_code} = await dataProvider.assignBed(bed);
            if (status_code === 0) {

                // dispatch(manageAccountAction.getListOfNurses());
            }
            return true;
        } catch (e) {
            console.log("Error:", e.message);
        }
    },
    setError: (error) => dispatch => dispatch({type: SET_ERROR, payload: error}),
    setState: (state) => dispatch => dispatch({type: SET_STATE, payload: state}),
    clearData: () => dispatch => dispatch({type: CLEAR_DATA})
};