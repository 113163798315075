import React, {useEffect, useState} from "react";
import {useAuth} from "../../../connections/net";
import {messageWrapper as t, translateValueOption} from "../../../utils/message-wrapper";
import {IrespSortForTable} from "../../common/IrespSortForTable";
import {IrespDropdown} from "../../common/IrespDropdown";
import {EspTable} from "../../common/EspTable";
import _ from "lodash";
import {IrespFilterDate} from "../../common/IrespFilterDate";
import {Sort} from "../../../constants/sort";
import "./DischargeHistory.css";
import moment from "moment";
import {formatDisplayDate} from "../../../utils/format";
import {setZoom} from "../../../utils/set-zoom";

const TextFields = ["Resident's Name", "Transfer Date", "Transfer Time", "Transfer From", "Transfer To"];

export function DischargeHistory(props) {

    useAuth();

    // set zoom
    setZoom();

    const [state, setState] = useState({tab: 1});

    useEffect(() => {
        props.getDischargeHistory();
    }, []);

    useEffect(() => {
        if (props.history?.length > 0) {
            setState({
                ...state,
                filteredHistory: props.history,
            });
        }
    }, [props.history]);

    useEffect(() => {
        if (props.history && props.history.length > 0) {
            const filteredHistory = props.history
                .filter(b => {
                    if (!state.filterValue || state.filterValue === "ALL") return true;

                    if ((state.filterField.indexOf("discharged") > -1 || state.filterField.indexOf("enroll") > -1)) {
                        return moment(b[state.filterField]).format("YYYYMMDD") === moment(state.filterValue).format("YYYYMMDD");
                    }

                    if (state.filterField && state.filterField.indexOf("status") > -1) {
                        return b[state.filterField]?.indexOf(state.filterValue) > -1;
                    }

                    if (state.filterField && state.filterField.indexOf("nurse_id") > -1) {
                        return b.nurse_names?.indexOf(state.filterValue) > -1;
                    }

                    if (state.filterField && TextFields.indexOf(state.filterField) > -1) {
                        return b[state.filterField] && b[state.filterField].toUpperCase().indexOf(state.filterValue.toUpperCase()) > -1;
                    }

                    return b[state.filterField] === state.filterValue;
                });
            setState({...state, filteredHistory: filteredHistory});
        }
    }, [state.filterValue]);

    const generateDischargeHistory = () => {
        const generateHeader = () => {
            return <tr>
                <td onClick={() => sortProcess("patient_name")}>
                    {t(`Resident's Name`)}
                    {/*<IrespSortForTable name="patient_name" sortState={state.sort}/>*/}
                </td>
                <td onClick={() => sortProcess("bed")}>
                    {t(`Date Discharged`)}
                    {/*<IrespSortForTable name="bed" sortState={state.sort}/>*/}
                </td>
                <td>
                    {t(`Time Discharged`)}
                </td>
                <td>
                    {t(`Date Enrolled`)}
                </td>
                <td>
                    {t(`Time Enrolled`)}
                </td>
            </tr>
        };
        const generateBody = () => {
            if (!state.filteredHistory || state.filteredHistory.length < 1) {
                return <tr>
                    <td className="max">{t(`No record found`)}</td>
                </tr>
            } else {
                return state.filteredHistory.map(b => {
                    return <tr>
                        <td>{b.name}</td>
                        <td>{formatDisplayDate(b.discharged)}</td>
                        <td>{moment(b.discharged).format("HH:mm")}</td>
                        <td>{formatDisplayDate(b.enroll)}</td>
                        <td>{moment(b.enroll).format("HH:mm")}</td>
                    </tr>
                });
            }
        };

        return <>
            <div className="table-descr">
                <div className="inline">
                    {t(`Filter Field:`)}
                    <IrespDropdown options={[
                        `Select Field`,
                        translateValueOption("Resident's Name", "name"),
                        translateValueOption("Date Discharged", "discharged"),
                        translateValueOption("Date Enrolled", "enroll"),
                    ]} handleSelect={(val) => setState({...state, filterField: val, filterValue: undefined})}/>
                </div>
                <div className="inline ml90">
                    {t(`Filter Value:`)}
                    {generateValues()}
                </div>
            </div>
            <EspTable className="history mt-lg-5" renderHeader={generateHeader} renderBody={generateBody}/>
        </>
    };

    const generateAction = () => {
        return <a className="link" onClick={undefined}>{t(`Transfer`)}</a>
    };

    const dropdownValues = () => {
        if (!state.filterField) return [];
        return _.uniq(props.history.map(b => b[state.filterField]), state.filterField);
    };

    const generateValues = () => {
        if (TextFields.indexOf(state.filterField) > -1) {
            return <input className="filter" value={state.filterValue || ""}
                          onChange={e => setState({...state, filterValue: e.target.value})}/>
        }

        if (state.filterField && (state.filterField.indexOf("discharged") > -1 || state.filterField.indexOf("enroll") > -1)) {
            return <IrespFilterDate value={state.filterValue}
                                    handleSelect={(val) => setState({...state, filterValue: val})}/>;
        }

        return <IrespDropdown
            value={state.filterValue}
            options={[
                `Select Value`,
                translateValueOption("All", "ALL"),
                ...dropdownValues().sort((a, b) => a.toUpperCase() > b.toUpperCase() ? 1 : -1).map(val => {
                    if (state.filterField === "response_status") {
                        return translateValueOption(val, val);
                    }
                    return <option value={val}>{val}</option>;
                })
            ]} handleSelect={(val) => setState({...state, filterValue: val})}/>;
    };

    const sortProcess = (name) => {
        if (!state.sort || state.sort.name !== name) {
            return setState({...state, sort: {name: name, value: Sort.Descending}});
        } else if (state.sort.name === name && state.sort.value === Sort.Descending) {
            return setState({...state, sort: {name: name, value: Sort.Ascending}});
        }
        setState({...state, sort: undefined});
    };

    return (
        <div className="discharge-history">
            <div className="table-content">
                <div>{generateDischargeHistory()}</div>
            </div>
        </div>
    );
}