import {connect} from 'react-redux'
import {ChangePw} from "./ChangePw";
import {loginActions} from "../../redux/actions/loginAction";

const mapStateToProps = state => ({
    success: state.loginReducer.success,
    error: state.loginReducer.error
});

function mapDispatchToProps(dispatch) {
    return ({
        changePassword: async (oldPassword, newPassword) => dispatch(loginActions.changePassword(oldPassword, newPassword)),
        setError: async (error) => dispatch(loginActions.setError(error)),
        clearData: () => dispatch(loginActions.clearData()),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePw);