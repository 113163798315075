/* eslint-disable no-restricted-globals */
import $ from "jquery";

export const zoomReport = () => {
    let offset = 0;
    let places = 100;
    const el = document.getElementById("patient-report");
    const  width = $(el).width() - 10;

    if(width >= 1800 && width < 1900) {
        offset = -0.009;
        places = 1000;
    } else if(width >= 1700) {
        offset = -0.0085;
        places = 1000;
    } else if(width >= 1600) {
        offset = -0.0080;
        places = 1000;
    } else if(width >= 1500) {
        offset = -0.0075;
        places = 1000;
    } else if(width >= 1400) {
        offset = -0.0070;
        places = 1000;
    } else if(width >= 1300) {
        offset = -0.0060;
        places = 1000;
    } else if(width >= 1200) {
        offset = -0.0055;
        places = 1000;
    } else if(width >= 1100) {
        offset = -0.0050;
        places = 1000;
    } else if(width >= 1000) {
        offset = -0.0045;
        places = 1000;
    } else if(width >= 900) {
        offset = -0.004;
        places = 1000;
    } else if(width >= 800) {
        offset = -0.0035;
        places = 1000;
    } else if(width >= 700) {
        offset = -0.003;
        places = 1000;
    } else if(width >= 600) {
        offset = -0.0025;
        places = 1000;
    } else if(width >= 500) {
        offset = -0.002;
        places = 1000;
    } else if(width >= 400) {
        offset = -0.003;
        places = 1000;
    }


    let rate = width / 1904 + offset + 0.005;

    rate = Math.floor(rate * places) / places;

    let printRate = 1904 / 3508;

    document
        .getElementById("report-content")
        .style
        .setProperty("--report-zoom", rate);

    // document
    //     .body
    //     .style
    //     .setProperty("--print-zoom", printRate);

    // document
    //     .documentElement
    //     .style
    //     .setProperty("--main-width", width + "px");
};