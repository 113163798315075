import React, {useEffect, useState} from "react";
import {useAuth} from "../../../connections/net";
import {messageWrapper as t} from "../../../utils/message-wrapper";
import "./ChangePassword.css";
import {IrespModal} from "../../common/IrespModal";

export function ChangePassword(props) {

    useAuth();

    const role = sessionStorage.getItem("OMNI2_ROLE");

    const [state, setState] = useState({confirm: false});

    useEffect(() => {
    }, []);

    const handleResetPasswordCancel = () => {
        props.history.goBack();
    };

    const handleResetPasswordConfirm = async () => {
        const res = await props.resetPassword({
            "username": localStorage.getItem(`${role}_OMNI2_USER`),
            "oah_user": role
        });
        // const res = true;
        if (res) {
            setState({confirm: true});
        }
    };

    const handleResetPasswordOk = () => {
        props.history.goBack();
    };

    return (
        <div className="reset-password">
            {
                !state.confirm
                    ? <IrespModal
                        clazz="reset-password-modal"
                        title={t(`Reset Password`)}
                        contents={[
                            <div className="m35">
                                {t(`Are you sure you want to change your password?`)}
                            </div>,
                            <div className="m35 error">
                                {props.error ? <div>{props.error}</div> : undefined}
                            </div>,
                        ]}
                        buttons={[
                            <button onClick={handleResetPasswordCancel}>{t(`Cancel`)}</button>,
                            <button onClick={handleResetPasswordConfirm}>{t(`Confirm`)}</button>,
                        ]}
                    />
                    : undefined
            }
            {
                state.confirm
                    ? <IrespModal
                        clazz="reset-password-modal"
                        title={t(`Reset Password`)}
                        contents={[
                            <div className="m35">
                                {t(`An email to reset your password has been sent to the registered email address of`)}
                                <b>&nbsp;{localStorage.getItem(`${role}_OMNI2_USER`)}.</b>
                            </div>,
                            <div className="mt15">
                                {t(`Please click on the link in the email and follow the instructions to reset your password.`)}
                            </div>,
                        ]}
                        buttons={[
                            <button onClick={handleResetPasswordOk}>{t(`OK`)}</button>,
                        ]}
                    />
                    : undefined
            }
        </div>
    );
}