import {connect} from 'react-redux'
import {Manage} from "./Manage";
import {appAction} from "../../../redux/actions/appAction";
import {manageAccountAction} from "../../../redux/actions/manageAccountAction";

const mapStateToProps = state => ({
    unassignedBeds: [],
    assignedBeds: [],
    assignedBeds2: [],
    unassignedBeds2: [],
    nurses: state.manageAccountReducer.nurses,
    beds: state.manageAccountReducer.beds,
    license_count: state.manageAccountReducer.license_count,
});

function mapDispatchToProps(dispatch) {
    return ({
        openLoading: () => dispatch(appAction.openLoading()),
        getListOfNurses: () => dispatch(manageAccountAction.getListOfNurses()),
        addNurse: (nurse) => dispatch(manageAccountAction.addNurse(nurse)),
        removeNurse: (nurse) => dispatch(manageAccountAction.removeNurse(nurse)),
        editNurse: (nurse) => dispatch(manageAccountAction.editNurse(nurse)),
        assignBed: (nurse) => dispatch(manageAccountAction.assignBed(nurse)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Manage);