import React, {useState, useEffect} from "react";
import {messageWrapper as t} from "../../utils/message-wrapper";
import {IrespModal} from "../common/IrespModal";
import {Configs} from "../../constants/configs";
import {Versions} from "../../constants/versions";

export function About({handleShowAboutClose, versions}) {
    const [openPrivate, setOpenPrivate] = useState(false);
    useEffect(()=> {
        const fiveClickEvt = function (evt) {
            if (evt.detail === 5) {
                setOpenPrivate(true);
            }
        };

        const el = document.getElementById('about-title');

        if (el) {
            el.addEventListener('click', fiveClickEvt);
        }

        return () => {
            el.removeEventListener('click', fiveClickEvt);
        };
    }, [openPrivate]);

    if(!openPrivate) {
        return <IrespModal
            clazz="about-modal"
            title={t(`About`)}
            contents={[
                <div>
                    {/*<p className="font-weight-bold text-center">{t(`${versions.components[0].name}`)}: {Versions.iresp_build}</p>*/}
                    <p className="font-weight-bold">{t(`OAC (Ospicon Aged Care) software:`)}<br/><br/>{Versions.iresp_build}</p>
                </div>,
            ]}
            buttons={[
                <button onClick={handleShowAboutClose}>{t(`OK`)}</button>,
            ]}
        />
    } else {
        return <IrespModal
            clazz="about-modal"
            title={t(`About`)}
            contents={[
                <div>
                    {/*<p className="font-weight-bold text-center">{t(`${versions.components[0].name}`)}: {Versions.iresp_build}</p>*/}
                    <p className="font-weight-bold">{t(`OAC (Ospicon Aged Care) software:`)}<br/><br/>{Versions.iresp_build_internal}</p>
                </div>,
            ]}
            buttons={[
                <button onClick={handleShowAboutClose}>{t(`OK`)}</button>,
            ]}
        />
    }
}