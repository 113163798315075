import {connect} from 'react-redux'
import {Reset} from "./Reset";
import {loginActions} from "../../redux/actions/loginAction";

const mapStateToProps = state => ({
    error: state.loginReducer.error,
    role: state.loginReducer.role,
    email: state.loginReducer.email,
    roles: [
        "ADMIN",
        "MANAGER"
    ],
});

function mapDispatchToProps(dispatch) {
    return ({
        doReset: async (params) => dispatch(loginActions.doResetPassword(params)),
        setError: (error) => dispatch(loginActions.setError(error)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Reset);