import { POLLING_DATA } from "../actions/liveTileAction";
import moment from "moment";
import { PATIENT_STATUS } from "../../utils/pos-status";

const initialState = {};

const offSoundTemporary = {};

const DUMMIES = [
  { br: 0x19, hr: 0x4f, pos: 0x00, status: 0x00 },
  { br: 0x1e, hr: 0x6e, pos: 0x00, status: 0x00 },
  { br: 0xff, hr: 0xff, pos: 0x02, status: 0x02 },
  { br: 0x19, hr: 0x4f, pos: 0x00, status: 0x04 },
  { br: 0x0f, hr: 0x3c, pos: 0x00, status: 0x03 },
  { br: 0x00, hr: 0x00, pos: 0x00, status: 0x0f },
  { br: 0xff, hr: 0xff, pos: 0x00, status: 0x05 },
  { br: 0xff, hr: 0xff, pos: 0x00, status: 0x05 },
  { br: 0x19, hr: 0x4f, pos: 0x00, status: 0x03 },
  { br: 0x19, hr: 0x4f, pos: 0x01, status: 0x10 },
  { br: 0xff, hr: 0xff, pos: 0x02, status: 0x02 },
  { br: 0xfb, hr: 0xfb, pos: 0xfb, status: 0xfb },
  { br: 0xfb, hr: 0xfb, pos: 0xfb, status: 0xfb },
  { br: 0xfb, hr: 0xfb, pos: 0xfb, status: 0xfb },
  { br: 0xfb, hr: 0xfb, pos: 0xfb, status: 0xfb },
  { br: 0xfb, hr: 0xfb, pos: 0xfb, status: 0xfb },
  { br: 0xfb, hr: 0xfb, pos: 0xfb, status: 0xfb },
  { br: 0xfb, hr: 0xfb, pos: 0xfb, status: 0xfb },
];

const armedMap = {};

const ARMED_STATE = {
  NOT_ARMED: "NOT_ARMED",
  ARMED: "ARMED",
  PAUSED: "PAUSED",
  TRIGGERED: "TRIGGERED",
};
//
let count = 0;
let timeOffPillow = {};
let timezone = Number(localStorage.getItem("ADMIN_OAH_TIMEZONE"));

function printTimeInHHMM(minutesIndex) {
  const hours = Math.floor(minutesIndex / 60);
  const minutes = minutesIndex % 60;
  // Pad the hours and minutes with leading zeros if necessary
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
}

const liveTileReducer = (state = initialState, action) => {
  switch (action.type) {
    case POLLING_DATA:
      if (!action.payload) {
        return { ...state, time: new Date() };
      }

      const { tiles, nurses, patients, server_time } = action.payload;

      // mapping patient
      let patientMap = state.patientMap || {};
      // if(!patientMap) {
      //     patientMap = {};
      patients.forEach((p) => {
        patientMap[p.id] = p.name;
      });
      // }

      // mapping nurse
      // let nurseMap = state.nurseMap;
      // if(!nurseMap) {
      //     nurseMap = {};
      //     nurses.forEach(n => {
      //         nurseMap[n.id] = n.name;
      //     });
      // }

      // tile extract
      const newTiles = [...tiles]
        .filter(
          (t, idx) =>
            t.patient_id && t.patient_id !== "" && t.data && t.bcu_wifi_mac
        )
        // .map((t, idx) => {
        //     // return {...t, data: {...t.data, bed: DUMMIES[idx]}}
        //     if (t.bed_name === "001") {
        //         return {
        //             ...t,
        //             fw_cdmcu: -1,
        //             alarm_status: [{"type": "OFF_BED", "sound": false, "status": false}, {
        //                 "type": "EOL",
        //                 "sound": true,
        //                 "status": true
        //             }, {"type": "AWAKE", "sound": false, "status": false}],
        //             data: {...t.data, bed: {br: 255, hr: 255, pos: 0, status: 15, last_update: 1704340978485}}
        //         }
        //     }
        //
        //     // if (t.bed_name === "002") {
        //     //     return {...t, fw_cdmcu: -1, data: {...t.data, bed: {br: 255, hr: 255, pos: 240, status: 2}}}
        //     // }
        //     //
        //     // if (t.bed_name === "003") {
        //     //     return {...t, fw_cdmcu: -1, data: {...t.data, bed: {}}}
        //     // }
        //
        //     // if (t.bed_name === "ET3") {
        //     //     return {...t, fw_cdmcu: -1, data: {...t.data, bed: {br: 0, hr: 0, pos: 1, status: 16}}}
        //     // }
        //
        //     // if (t.bed_name === "ET3") {
        //     //     return {
        //     //         ...t,
        //     //         alarm_status: [{"type": "OFF_BED", "sound": true, "status": true}, {
        //     //             "type": "EOL",
        //     //             "sound": false,
        //     //             "status": false
        //     //         }, {"type": "AWAKE", "sound": false, "status": false}],
        //     //         data: {...t.data, bed: {br: 0, hr: 0, pos: 2, status: 2}}
        //     //     }
        //     // }
        //     return t;
        // })
        // .map(t => {
        //     if (t.bed_name === "S325") {
        //         return {...t, data: {...t.data, bed: {br: 255, hr: 255, pos: 2, status: 2}}}
        //     }
        //     if (t.bed_name === "ET2") {
        //         return {
        //             ...t,
        //             alarm_status: [{"type": "OFF_BED", "sound": false, "status": false}, {
        //                 "type": "EOL",
        //                 "sound": true,
        //                 "status": true
        //             }, {"type": "AWAKE", "sound": false, "status": false}],
        //             data: {...t.data, bed: {br: 15, hr: 60, pos: 0, status: 15}}
        //         }
        //     }
        //     if (t.bed_name === "ET5") {
        //         return {...t, fw_cdmcu: 2, data: {...t.data, bed: {br: 15, hr: 60, pos: 0, status: 3}}}
        //     }
        //     if (t.bed_name === "ET2") {
        //         return {...t, data: {...t.data, bed: {br: 15, hr: 90, pos: 0, status: 4}}}
        //     }
        //     return t;
        // })
        .map((t) => {
          let status = t.data?.bed;

          // armed
          // const arm = armedMap[t.id] || {
          //     // previousState: undefined,
          //     currentState: undefined,
          //     armed: ARMED_STATE.NOT_ARMED,
          // };

          // arm.previousState = arm.currentState;
          // arm.currentState = status.status;

          // if (
          //     arm.armed === ARMED_STATE.NOT_ARMED
          //     // && (arm.previousState === PATIENT_STATUS.TimeToSleep || arm.previousState === PATIENT_STATUS.Judgement)
          //     && (arm.currentState === PATIENT_STATUS.Sleeping)) {
          //     arm.armed = ARMED_STATE.ARMED;
          // } else if (arm.armed === ARMED_STATE.ARMED && (arm.currentState === PATIENT_STATUS.Sleeping
          //     || arm.currentState === PATIENT_STATUS.Movement || arm.currentState === PATIENT_STATUS.Awake
          //     || arm.currentState === PATIENT_STATUS.Judgement || arm.currentState === PATIENT_STATUS.EOL)) {
          //     arm.armed = ARMED_STATE.ARMED;
          // } else if ((arm.armed === ARMED_STATE.ARMED) && arm.currentState === PATIENT_STATUS.Absent) {
          //     arm.armed = ARMED_STATE.TRIGGERED;
          //     // offSoundTemporary[t.id] = false;
          // } else if (arm.armed === ARMED_STATE.TRIGGERED) {
          //     const status = t.alarm_status.find(st => st.type === "OFF_BED");
          //     if (arm.currentState === PATIENT_STATUS.TimeToSleep || arm.currentState === PATIENT_STATUS.Judgement) {
          //         arm.armed = ARMED_STATE.PAUSED;
          //     } else if (status && !status.sound) {
          //         arm.armed = ARMED_STATE.NOT_ARMED;
          //     }
          // } else if (arm.armed === ARMED_STATE.PAUSED && arm.currentState === PATIENT_STATUS.Absent) {
          //     arm.armed = ARMED_STATE.TRIGGERED;
          //     // offSoundTemporary[t.id] = false;
          // } else if (arm.armed === ARMED_STATE.PAUSED && arm.currentState === PATIENT_STATUS.Sleeping) {
          //     arm.armed = ARMED_STATE.ARMED;
          // }
          //
          // armedMap[t.id] = arm;

          const mapTypeToKey = {
            EOL: "end_of_life_enabled",
            OFF_BED: "off_bed_enabled",
            AWAKE: "awake_enabled",
            VITAL: "vital_enabled",
          };

          // if (!t.data) {
          //     return {
          //         patient_name: patientMap[t.patient_id],
          //         bed_name: t.bed_name,
          //         config: t.config,
          //         status: {},
          //         alarm_status: t.alarm_status,
          //         bcu_ble_mac: t.bcu_ble_mac,
          //         bcu_wifi_mac: t.bcu_wifi_mac,
          //         tag_mac: t.tag_mac,
          //         create_on: t.create_on,
          //         last_update: t.last_update,
          //         mat_serial: t.mat_serial,
          //         hasError: false,
          //         isExceedHr: false,
          //         isExceedBr: false,
          //         hasSound: false,
          //         isCleared: false,
          //         audibleActionError: false,
          //         soundArray: false,
          //         enableAll: false,
          //         offSound: false
          //     };
          // }

          // check sound
          let hasSound = false,
            audibleActionError = false,
            soundArray = [],
            enableAll = false;
          let isAwake = false;

          // check exceed
          const isExceedHr =
            status.status === 0 ? isExceed("hr", status?.hr, t.config) : false;
          const isExceedBr =
            status.status === 0 ? isExceed("br", status?.br, t.config) : false;

          t.alarm_status.forEach((alarm) => {
            const config_key = mapTypeToKey[alarm.type];
            let enabled =
              t.config[config_key] === true || t.config[config_key] === "true";

            // validate off bed range time
            if (alarm.type === "OFF_BED" && enabled) {
              const now = new Date(); //moment().utcOffset(timezone);
              enabled = false;

              const nowIndex = now.getHours() * 60 + now.getMinutes();

              const obFromIndex =
                t.config.off_bed_from_hour * 60 + t.config.off_bed_from_minute;
              const obToIndex =
                t.config.off_bed_to_hour * 60 + t.config.off_bed_to_minute;

              // Case 1: obFrom is equal to obTo, the alarm should always be enabled
              if (obFromIndex === obToIndex) {
                enabled = true;
              }

              // Case 2: obFrom < obTo (within the same day)
              if (obFromIndex < obToIndex) {
                enabled = obFromIndex <= nowIndex && nowIndex <= obToIndex;
              }

              // Case 3: obFrom > obTo (spanning midnight)
              if (obFromIndex > obToIndex) {
                enabled = nowIndex <= obToIndex || obFromIndex <= nowIndex;
              }
              //   console.log(t.bed_name);
              //  if (t.bed_name == "323" || t.bed_name == "324") {
              console.log("--------------------------------- " + alarm.type);
              console.log(t.bed_name);
              console.log("now : " + now.getHours() + ":" + now.getMinutes());
              console.log("obFromIndex : " + printTimeInHHMM(obFromIndex));
              console.log("obToIndex : " + printTimeInHHMM(obToIndex));
              console.log("nowIndex : " + printTimeInHHMM(nowIndex));
              console.log("enabled : " + enabled);
              // }
              // This case should never be reached due to the comprehensive logic above

              // delay
              // if(t.bed_name === "ET5") {
              //     console.log(timeOffPillow[t.bed_name])
              //     console.log(enabled)
              //     console.log(t.config["off_bed_delay"])
              //     console.log(alarm.status)
              //     // console.log(alarm.sound)
              // }
              if (
                t.config["off_bed_delay"] &&
                t.config["off_bed_delay"] !== 0 &&
                !timeOffPillow[t.bed_name] &&
                alarm.status &&
                alarm.sound
              ) {
                enabled = false;
                timeOffPillow[t.bed_name] = moment();
              } else if (
                alarm.status &&
                alarm.sound &&
                t.config["off_bed_delay"] &&
                t.config["off_bed_delay"] !== 0 &&
                timeOffPillow[t.bed_name] &&
                moment().diff(timeOffPillow[t.bed_name], "seconds") <
                  t.config["off_bed_delay"]
              ) {
                enabled = false;
              } else if (!alarm.status) {
                delete timeOffPillow[t.bed_name];
              }

              // armed
              // if (arm.armed !== ARMED_STATE.TRIGGERED) {
              //     enabled = false;
              // }
            }

            // if(!enableAll && alarm.type !== "AWAKE") {
            if (!enableAll && alarm.type !== "AWAKE") {
              enableAll = enabled;
            }

            if (
              enabled &&
              !hasSound &&
              alarm.type !== "AWAKE" &&
              alarm.type !== "VITAL"
            ) {
              hasSound = alarm.sound;
            }

            if (
              enabled &&
              alarm.sound &&
              alarm.type !== "AWAKE" &&
              alarm.type !== "VITAL"
            ) {
              soundArray.push(
                alarm.type === "EOL" ? "Vital Signs not detected" : alarm.type
              );
            }

            if (
              !audibleActionError &&
              alarm.type !== "AWAKE" &&
              alarm.type !== "VITAL"
            ) {
              audibleActionError = alarm.status;
            }

            if (alarm.type === "AWAKE" && alarm.sound && alarm.status)
              isAwake = true;

            if (alarm.type === "VITAL" && alarm.sound && enabled) {
              // HR exceeded
              if (isExceedHr && status.status === 0) {
                hasSound = true;
                audibleActionError = true;
                soundArray.push("PR");
              }

              // BR exceeded
              if (isExceedBr && status.status === 0) {
                hasSound = true;
                audibleActionError = true;
                soundArray.push("RESP");
              }
            }

            if (alarm.type === "E1" && alarm.sound && alarm.status) {
              // E1 check
              audibleActionError = true;
              enableAll = true;
              hasSound = true;
              soundArray.push("E1");
            }
          });

          // offSoundTemporary
          // const offSound = offSoundTemporary[t.id];
          // if (hasSound && offSoundTemporary[t.id] !== true) {
          //     offSoundTemporary[t.id] = true;
          // } else if (!hasSound) {
          //     offSoundTemporary[t.id] = false;
          // }

          // check data not update
          // 13/06/2023
          let lastUpdate = status.last_update;

          // 17/01/2024
          const audio_status = t.audio?.status;
          const audio_last_update = t.audio?.update_time;
          if (audio_last_update && audio_last_update > lastUpdate) {
            lastUpdate = audio_last_update;
          }

          if (
            server_time - lastUpdate > 90000 &&
            (t.fw_cdmcu === 2 ||
              t.fw_cdmcu === 3 ||
              t.fw_cdmcu === 4 ||
              t.fw_cdmcu === 32)
          ) {
            status = undefined;
            hasSound = false;
          } else if (
            server_time - lastUpdate > 300000 &&
            (t.fw_smcu === 2 ||
              t.fw_smcu === 3 ||
              t.fw_smcu === 4 ||
              t.fw_smcu === 32)
          ) {
            status = undefined;
            hasSound = false;
          } else if (
            server_time - lastUpdate > 60000 &&
            t.fw_cdmcu !== 2 &&
            t.fw_cdmcu !== 3 &&
            t.fw_cdmcu !== 4 &&
            t.fw_cdmcu !== 32 &&
            t.fw_smcu !== 2 &&
            t.fw_smcu !== 3 &&
            t.fw_smcu !== 4 &&
            t.fw_smcu !== 32 &&
            audio_status !== true &&
            audio_status !== "true"
          ) {
            status = undefined;
            hasSound = false;
          }

          // disabled alert when upgrading fw
          // 02 Jua 2024
          if (
            t.fw_cdmcu === 2 ||
            t.fw_cdmcu === 3 ||
            t.fw_cdmcu === 4 ||
            t.fw_cdmcu === 32 ||
            t.fw_smcu === 2 ||
            t.fw_smcu === 3 ||
            t.fw_smcu === 4 ||
            t.fw_smcu === 32
          ) {
            hasSound = false;
          }

          // latest alert timestamp
          // let latestAlertTimestamp;
          // t.alarm_status.forEach(alert => {
          //     if (latestAlertTimestamp) {
          //         latestAlertTimestamp = alert.sound_started_ts;
          //     } else if (latestAlertTimestamp < alert.sound_started_ts) {
          //         latestAlertTimestamp = alert.sound_started_ts;
          //     }
          // });

          return {
            patient_name: patientMap[t.patient_id],
            bed_name: t.bed_name,
            config: t.config,
            // status: t.bed_name !== "S319" ? status : undefined,
            status: status,
            alarm_status: t.alarm_status,
            bcu_ble_mac: t.bcu_ble_mac,
            bcu_wifi_mac: t.bcu_wifi_mac,
            tag_mac: t.tag_mac,
            create_on: t.create_on,
            last_update: t.last_update,
            mat_serial: t.mat_serial,
            isExceedHr,
            isExceedBr,
            hasSound,
            audibleActionError,
            soundArray,
            enableAll,
            // offSound,
            isAwake,
            fw_cdmcu: t.fw_cdmcu,
            fw_smcu: t.fw_smcu,
            isEx:
              (status?.br > 240 && status?.br < 255) ||
              (status?.hr > 240 && status?.hr < 255),
            alarm_sorting_timestamp: t.alarm_sorting_timestamp,
          };
        })
        .sort((t1, t2) => {
          if (t1.hasSound && t2.hasSound) {
            return t1.alarm_sorting_timestamp < t2.alarm_sorting_timestamp
              ? 1
              : -1;
          } else if (t1.hasSound && !t2.hasSound) {
            return -1;
          } else if (!t1.hasSound && t2.hasSound) {
            return 1;
            // } else if (t1.isEx && t2.isEx) {
            //     return t1.bed_name > t2.bed_name ? 1 : -1;
            // } else if (t1.isEx && !t2.isEx) {
            //     return -1;
            // } else if (!t1.isEx && t2.isEx) {
            //     return 1;
            // } else if (!t1.status && !t2.status) {
            //     return t1.bed_name > t2.bed_name ? 1 : -1;
            // } else if (!t1.status && t2.status) {
            //     return -1;
            // } else if (t1.status && !t2.status) {
            //     return 1;
            // } else if ((t1.isExceedBr || t1.isExceedHr) && (t2.isExceedBr || t2.isExceedHr)) {
            //     return t1.bed_name > t2.bed_name ? 1 : -1;
            // } else if ((t1.isExceedBr || t1.isExceedHr) && !(t2.isExceedBr || t2.isExceedHr)) {
            //     return -1;
            // } else if (!(t1.isExceedBr || t1.isExceedHr) && (t2.isExceedBr || t2.isExceedHr)) {
            //     return 1;
          } else {
            return t1.bed_name > t2.bed_name ? 1 : -1;
          }
        });

      const hasSound = newTiles.filter((t) => t.hasSound);

      // alert image in main tab
      // const hasAlert = newTiles.filter(tile => {
      //     return tile.isExceedHr || tile.isExceedBr || tile.hasSound || tile.hasError || tile.audibleActionError;
      // }).length;

      if (hasSound.length > 0) {
        localStorage.setItem("OMNI2_ALERT", "true");
      } else {
        localStorage.setItem("OMNI2_ALERT", "false");
      }

      return {
        ...state,
        tiles: newTiles,
        time: new Date(),
        hasSound: hasSound.length > 0,
        armedMap,
      };
    default:
      return state;
  }
};

function isExceed(field, val, config) {
  switch (field) {
    case "hr":
      return (
        val <= 200 &&
        val >= 0 &&
        (val < config?.bpm_low ||
          (val > config?.bpm_high && config?.bpm_high !== 0))
      );
    case "br":
      return (
        val <= 100 &&
        val >= 0 &&
        val !== 240 &&
        (val < config?.rpm_low ||
          (val > config?.rpm_high && config?.rpm_high !== 0))
      );
    default:
      return false;
  }
}

export default liveTileReducer;
