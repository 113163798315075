import React, {useEffect, useState} from "react";
import {useAuth} from "../../../connections/net";
import {messageWrapper as t} from "../../../utils/message-wrapper";
import {EspDropdown} from "../../common/EspDropdown";
import QRCode from "react-qr-code";
import "./Dashboard.css";
import "./ShowQRCode.css";
import {setZoom} from "../../../utils/set-zoom";

export function ShowQRCode(props) {

    useAuth();

    // set zoom
    setZoom();

    const [state, setState] = useState({});

    useEffect(() => {
        props.getListOfNurses();
    }, []);

    const handleSelect = (selected) => {
        props.getQRData({username: selected.value});
        setState({...state, selectedNurse: selected});
    };

    return (
        <div className="dashboard-cc-admin">
            <div className="select-nurse">
                <div>
                    {t(`Select Bed Group:`)}
                </div>
                <div>
                    <EspDropdown
                        key={Math.random()}
                        value={state.selectedNurse}
                        placeholder={`Select Bed Group for login`}
                        options={
                            props.listOfNurses?.sort((a, b) => a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1).map(n => ({value: n.id, label: n.name}))
                        } onChange={handleSelect}
                    />
                </div>
            </div>
            {
                props.qrData
                    ? <div className="qr-code">
                        <div>
                            {t(`Scan this QR code with OAC APP to login for Bed Group operations.`)}
                        </div>
                        <div className="qrcode-div">
                            <QRCode
                                className="qrcode-img"
                                value={props.qrData}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                    </div>
                    : undefined
            }
        </div>
    );
}