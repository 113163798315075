import React, {useRef, useState} from "react";
import {messageWrapper as t} from "../../../utils/message-wrapper";
import {FontAwesomeIcon as FA} from "@fortawesome/react-fontawesome";
import {faCaretLeft, faCaretRight} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {OmniCalendar} from "../../common/OmniCalendar";
import "./ReportDatePicker.css";
import {useOutsideDetect} from "../../../utils/hooks";
import {formatDisplayDate} from "../../../utils/format";

export const ReportDatePicker = (props) => {

    const [state, setState] = useState({
        selectedDate: moment(props.selectedDate).add(props.plusDaysDisplay, "day").toDate(),
    });

    const dateRef = useRef(null);

    useOutsideDetect(dateRef, state.isOpenCalendar, () => setState({...state, isOpenCalendar: false}));

    const openCalendar = () => {
        setState({...state, isOpenCalendar: true});
    };

    const plusDays = props.plusDays || 0;
    const formatted1 = moment().add(plusDays, "days").format("YYYYMMDD");
    const formatted2 = moment(state.selectedDate).format("YYYYMMDD");
    const formatted3 = moment(props.minDate).format("YYYYMMDD");
    const disabledNext = moment(formatted1).diff(moment(formatted2), 'days') <= 0;
    const disabledPrevious = moment(formatted3).diff(moment(formatted2), 'days') >= 0;

    const handleGo = (num) => {
        const selectedDate = moment(state.selectedDate).add(num, 'days');
        const fromParam = {
            year: selectedDate.year(),
            month: selectedDate.month() + 1,
            day: selectedDate.date()
        };
        const toParam = {
            year: selectedDate.year(),
            month: selectedDate.month() + 1,
            day: selectedDate.date() + 1
        };

        // getReportData({
        //     fromParam,
        //     toParam
        // });
        setState({...state, selectedDate: selectedDate.toDate(), from: fromParam, to: toParam});
        if(props.handleChange) {
            props.handleChange(selectedDate.toDate());
        }
    };

    const handleSelectDate = (value) => {
        const date = moment(value);
        const fromParam = {
            year: date.year(),
            month: date.month() + 1,
            day: date.date()
        };
        const toParam = {
            year: date.year(),
            month: date.month() + 1,
            day: date.date() + 1
        };
        // getReportData({fromParam, toParam: fromParam});
        setState({
            ...state,
            selectedDate: value,
            isOpenCalendar: false,
            from: fromParam,
            to: toParam
        });

        if(props.handleChange) {
            props.handleChange(value);
        }
    };

    // if (window.location.href.indexOf("/cc-admin-data-view-archived-report") !== -1) {
    //     //     return <div/>;
    //     // }

    return <div className="report-date-picker inline">
        <div ref={dateRef} className="name-12" tabIndex="1">
            <span className="date-label">{t(`Report Date`)}:</span>
            <FA onClick={() => handleGo(-1)}
                className={disabledPrevious ? "caret-left" : "caret-left active"} icon={faCaretLeft}/>
            <span onClick={openCalendar}
                  className="date">{formatDisplayDate(state.selectedDate)}</span>
            <FA onClick={() => handleGo(1)}
                className={disabledNext ? "caret-left" : "caret-left active"} icon={faCaretRight}/>
            {
                state.isOpenCalendar
                    ? <OmniCalendar
                        onChange={(value) => handleSelectDate(value)}
                        value={state.selectedDate}
                        minDate={props.minDate}
                        plusDays={plusDays}
                    />
                    : ""
            }
        </div>
    </div>;
};