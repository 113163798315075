export const NAMES = {
    REPORT: "REPORT-",
    RESP_HIGH: "Tachypnea",
    RESP_LOW: "Bradypnea",
    PR_HIGH: "High Pr",
    PR_LOW: "Low Pr",
};

export const CHART_UNITS = 21600;

export const COLOR = {
    APNEA: "rgba(145, 228, 251, 0.5)",
    LOW_RESP: "rgba(145, 228, 251, 0.5)",
    HIGH_RESP: "rgba(255, 138, 132, 0.5)",
    LOW_PR: "rgba(52, 226, 92, 0.5)",
    HIGH_PR: "rgba(218, 166, 8, 0.5)",
};

export const SLEEP_PHARSE = {
    SLEEP_TIME: "SLEEP_TIME",
    AWAKE_ON_BED: "AWAKE_ON_BED",
    TIME_TO_SLEEP: "TIME_TO_SLEEP",
    LIGHT_SLEEP: "LIGHT_SLEEP",
    DEEP_SLEEP: "DEEP_SLEEP",
};

export const COLOR_SLEEP_PHARSE = {
    SLEEP_TIME: "rgb(244, 235, 73)",
    AWAKE_ON_BED: "rgb(252, 175, 74)",
    TIME_TO_SLEEP: "rgb(252, 175, 74)",
    LIGHT_SLEEP: "rgb(106, 225, 255)",
    DEEP_SLEEP: "rgb(11, 186, 231)",
    OFF_PILLOW: "#F5EA08",
};

export const HEIGHT_PHARSE = {
    SLEEP_TIME: 46,
    AWAKE_ON_BED: 46,
    TIME_TO_SLEEP: 46,
    LIGHT_SLEEP: 46,
    DEEP_SLEEP: 46,
    OFF_PILLOW: 46,
};

export const dayInWeek = function (lang, key) {
    if (lang !== "en") {
        const JA_LANG = {
            0: "日",
            1: "月",
            2: "火",
            3: "水",
            4: "木",
            5: "金",
            6: "土",
        };

        return JA_LANG[key];
    }

    const ENG_LANG = {
        0: "Sun",
        1: "Mon",
        2: "Tue",
        3: "Wed",
        4: "Thu",
        5: "Fri",
        6: "Sat",
    };

    return ENG_LANG[key];
};

export const Zoomed = {
    zoomed: 0,
    previousK: 1,
    previousX: 0,
    translatedX: 0
};