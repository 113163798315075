import React, {useState, useEffect} from "react";
import {messageWrapper as t, translateInput as ti} from "../../utils/message-wrapper";
import "./Login.css";
import {IrespModal} from "../common/IrespModal";
import {EspDropdown} from "../common/EspDropdown";
import AppIcon from "../../images/oac/favicon_io/favicon-192x192.png";
import cx from "classnames";
import {OACPasswordField} from "../common/oac/OACPasswordField";
import {setZoom} from "../../utils/set-zoom";
import {ROLES} from "../../constants/roles";

const ROLES_NAME_MAP = {
    ADMIN: "NURSE-ADMIN",
    MANAGER: "SYS-ADMIN",
};

const initialState = {
    isForgotPassword: false, step: 1,
    role: undefined,
    acceptPolicy: sessionStorage.getItem("OAC_ACCEPT_POLICY"),
};

export function Login(props) {
    const [state, setState] = useState({...initialState});

    // set zoom
    setZoom();

    let ch;
    let timer;
    try {
        ch = new BroadcastChannel('already');
        ch.addEventListener('message', function (e) {
            if (e.data?.indexOf("pong") > -1 && e.data?.indexOf(state.role?.value) > -1) {
                props.setError("You are already signed in on another tab");
                clearTimeout(timer);
                props.closeLoading();
            }
        });
    } catch (e) {
        console.log(e.message);
    }

    useEffect(() => {
        // const role = sessionStorage.getItem("OMNI2_ROLE");
        // localStorage.removeItem(`${role}_LOGGIN_ROLE`);
        // props.getCountryCode();
    }, []);

    const handleSignIn = (e) => {
        e.preventDefault();
        props.openLoading();

        // validate
        if (!state.username || !state.password) {
            props.setError("User_ID & Password cannot be empty");
            props.closeLoading();
            return;
        }

        // concurrence login
        // if (state.role?.value === localStorage.getItem(`${ROLES.NurseAdmin}_LOGGIN_ROLE`)
        //     || state.role?.value === localStorage.getItem(`${ROLES.OspMgr}_LOGGIN_ROLE`)) {
        //     props.setError("You are already signed in on another tab");
        //     return;
        // }
        ch.postMessage(`ping_${state.role?.value}`);

        timer = setTimeout(() => {
            props.checkLogin({
                username: state.username,
                password: state.password,
                role: state.role?.value
            }, props.history.push);
        }, 1000);

        // trigger action
        // if (!localStorage.getItem("token")) {
        // props.checkLogin({
        //     username: state.username,
        //     password: state.password,
        //     role: state.role?.value
        // }, props.history.push);
        // } else {
        //     window.location.href = "/cc-admin-dashboard";
        // }
    };

    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    };

    const handleDuplicateLoginCancel = () => {
        props.duplicateLoginResponse(false);
    };

    const handleDuplicateLoginConfirm = () => {
        props.duplicateLoginResponse(false);
        // props.doLogin({username: state.username, password: state.password, oah_user: "ADMIN"}, props.history.push);
        props.doLogin({
            username: state.username,
            password: state.password,
            oah_user: state.role?.value
        }, props.history.push);
    };

    const handleForgot = () => {
        setState({...state, isForgotPassword: true, step: 1});
    };

    const handleForgotStep1 = async () => {
        const res = await props.requestResetPassword({
            "username": state.email,
            "oah_user": state.role?.value,
        });
        if (res) {
            setState({...state, isForgotPassword: true, step: 2});
            props.setError(undefined);
        }
    };

    const UsernameInput = ti({type: "text", name: "username"}, `Enter User_ID`, handleChange);
    const EmailInput = ti({type: "email", name: "email"}, `Enter User_ID`, handleChange);

    return (
        <div id="login-container">
            {
                !state.isForgotPassword
                    ? <div id="login-form"
                           className={localStorage.getItem("OMNI2_PRIORITY_LANGUAGE") !== "en" ? " h475" : ""}>
                        <div className="login-header">
                            <img className="logo1" src={AppIcon}/>
                        </div>
                        <div className="login-title">
                            <span>{t(`Sign-in`)}</span>
                        </div>
                        <div className="login-body">
                            <form onSubmit={(e) => {
                                handleSignIn(e);
                            }}>
                                <div className="group-label"><span>{t(`Select role to login in:`)}</span></div>
                                <div>
                                    <EspDropdown
                                        key={Math.random()}
                                        value={state.role}
                                        placeholder={`Select Role`}
                                        options={props.roles?.map(b => ({value: b, label: ROLES_NAME_MAP[b]}))}
                                        // noOptionsMessage={}
                                        onChange={(val) => setState({...state, role: val})}
                                    />
                                </div>

                                <div className="group-label pt15"><span>{t(`Enter credentials to login`)}</span></div>
                                <div className="pt10">
                                    {UsernameInput}
                                </div>
                                <div className="pb5">
                                    <OACPasswordField
                                        placeholder="Enter Password"
                                        value={state.password}
                                        onChange={(val) => setState({...state, password: val})}/>
                                </div>
                                <button className="d-none" type="submit"></button>
                            </form>
                        </div>
                        <div className="login-footer">
                            <div>
                                {/*<div className="forget" onClick={()=>{}}>Forgot password</div>*/}
                                <button className="visible" onClick={() => {
                                    handleForgot()
                                }}>{t(`Forgot Password`)}</button>
                                <button onClick={handleSignIn}>{t(`Sign-in`)}</button>
                            </div>
                        </div>
                    </div>
                    : undefined
            }
            {
                state.isForgotPassword && state.step === 1
                    ? <div id="login-form">
                        <div className="login-header">
                            <img className="logo1" src={AppIcon}/>
                        </div>
                        <div className="login-title">
                            <span>{t(`Forgot password`)}</span>
                        </div>
                        <div className="login-body">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSignIn();
                            }}>
                                <div className="group-label"><span>{t(`Select role to login in:`)}</span></div>
                                <div>
                                    <EspDropdown
                                        key={Math.random()}
                                        value={state.role}
                                        placeholder={`Select Role`}
                                        options={props.roles?.map(b => ({value: b, label: ROLES_NAME_MAP[b]}))}
                                        // noOptionsMessage={}
                                        onChange={(val) => setState({...state, role: val})}
                                    />
                                </div>

                                <div className="group-label pt15"><span>{t(`Enter email address:`)}</span></div>
                                <div>
                                    {EmailInput}
                                </div>
                                {/*<button className="d-none" type="submit"></button>*/}
                            </form>
                        </div>
                        <div className="login-footer">
                            <div>
                                {/*<div className="forget" onClick={()=>{}}>Forgot password</div>*/}
                                <button className={cx(
                                    "proceed mt20",
                                    !state.email ? "disabled" : ""
                                )} onClick={() => {
                                    handleForgotStep1()
                                }}>{t(`Proceed`)}</button>
                            </div>
                        </div>
                    </div>
                    : undefined
            }
            {
                state.isForgotPassword && state.step === 2
                    ? <div id="login-form">
                        <div className="login-header">
                            <img className="logo1" src={AppIcon}/>
                        </div>
                        <div className="login-title">
                            <span>{t(`Email sent`)}</span>
                        </div>
                        <div className="login-body">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSignIn();
                            }}>
                                <div className="mt40">
                                    {t(`An email to reset your password has been sent to the registered email address of`)}
                                    <b>&nbsp;{state.role?.label}.</b>
                                </div>
                                <div className="mt10">
                                    {t(`Please click on the link in the email and follow the instructions to reset your password.`)}
                                </div>
                            </form>
                        </div>
                        <div className="login-footer">
                            <div>
                                <button className={cx(
                                    "proceed done",
                                )} onClick={() => {
                                    setState({...initialState})
                                }}>{t(`OK`)}</button>
                            </div>
                        </div>
                    </div>
                    : undefined
            }
            {
                props.error
                    ? <div className="login-error">
                        {t(`${props.error}`)}
                    </div>
                    : null
            }
            <a id="link-hidden" onClick={handleSignIn}></a>
            {
                props.isDuplicatedLogin
                    ? <IrespModal
                        clazz="duplicate-login-modal"
                        title={t(`Duplicate sign-in attempt`)}
                        contents={[
                            <div>
                                {t(`This will sign you out on other devices and you may lose any unsaved edits.`)}
                            </div>,
                        ]}
                        buttons={[
                            <button onClick={handleDuplicateLoginCancel}>{t(`Cancel`)}</button>,
                            <button onClick={handleDuplicateLoginConfirm}>{t(`Confirm`)}</button>,
                        ]}
                    />
                    : null
            }
        </div>
    );
}