import React, { useEffect, useState } from "react";
import { useAuth } from "../../../connections/net";
import {
  messageWrapper as t,
  translateInput as ti,
} from "../../../utils/message-wrapper";
import cx from "classnames";
import "./PatientReport.css";

import { InputDatePicker } from "../../common/InputDatePicker";
import moment from "moment";
import { useIntl } from "react-intl";

import dischargeBtn from "../../../images/discharge-patient.png";
import dischargeDisabledBtn from "../../../images/discharge-patient-disabled.png";
import editBtn from "../../../images/edit-patient.png";
import PrintoutWhite from "../../../images/PrintoutWhite.png";
import SleepTrendImg from "../../../images/oac/sleep-report-icon.png";
import VitalSignImg from "../../../images/oac/vital-sign-report-icon.png";
import ExportCSVImg from "../../../images/oac/OAC_File-1-White_NoTXT_png.png";
import { ReportDatePicker } from "../report-date-picker/ReportDatePicker";
import { IrespModal } from "../../common/IrespModal";
import { emptyToDash } from "../../../utils/format";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import _ from "lodash";
import $ from "jquery";

// external css
import "../../../libs/css/index.css";
import "../../../libs/css/layout.css";
import "../../../libs/css/layout2.css";
import "../../../libs/css/sleep_trend.css";
import "../../../libs/css/vital_sign.css";
import "../../../libs/css/media-devices.css";

// external js
import { updateChart, clearChart } from "../../../libs/js/sleep_trend";
import { zoomReport } from "../../../libs/js/zoom";
import {
  updateVitalSignChart,
  clearVitalSignChart,
} from "../../../libs/js/listener";
import { changeWidth } from "../../../utils/width";
import { PrintPreview } from "./report-print/PrintPreview";
import { setZoom } from "../../../utils/set-zoom";
import { Zoomed } from "../../../libs/js/constant";

export function PatientReport(props) {
  useAuth();

  const intl = useIntl();

  const selectedDate = sessionStorage.getItem("OAC_REPORT_SELECTED_DATE");

  const timezone = Number(localStorage.getItem(`ADMIN_OAH_TIMEZONE`) || 540);

  const [state, setState] = useState({
    isOpenCalendar: false,
    typeOfReport: 1,
    isOpenPrintPreview: false,
    selectedDate:
      selectedDate && selectedDate !== "Invalid date"
        ? moment(selectedDate, "YYYY_MM_DD").toDate()
        : new Date(),
    timezone: timezone,
  });

  const translations = {
    "SLEEP PHASE TREND": intl.formatMessage({ id: "SLEEP PHASE TREND" }),
    Date: intl.formatMessage({ id: "Date" }),
    Day: intl.formatMessage({ id: "Day" }),
    // "Sleep time (= Light Sleep + Deep Sleep)": intl.formatMessage({id: "Sleep time (= Light Sleep + Deep Sleep)"}),
    // "Awake on bed": intl.formatMessage({id: "Awake on bed"}),
    Awake: intl.formatMessage({ id: "Awake" }),
    // "Time to Sleep": intl.formatMessage({id: "Time to Sleep"}),
    // "Time to sleep": intl.formatMessage({id: "Time to Sleep"}),
    "Light Sleep": intl.formatMessage({ id: "Light Sleep" }),
    "Light sleep": intl.formatMessage({ id: "Light Sleep" }),
    "Deep Sleep": intl.formatMessage({ id: "Deep Sleep" }),
    "Deep sleep": intl.formatMessage({ id: "Deep Sleep" }),
    "SLEEP TREND": intl.formatMessage({ id: "SLEEP TREND" }),
    Time: intl.formatMessage({ id: "Time" }),
    "Min.RPM:": intl.formatMessage({ id: "Min.RPM:" }),
    "Max.RPM:": intl.formatMessage({ id: "Max.RPM:" }),
    "Apnea events:": intl.formatMessage({ id: "Apnea events:" }),
    // "Tachypnea": intl.formatMessage({id: "Tachypnea"}),
    // "Bradypnea": intl.formatMessage({id: "Bradypnea"}),
    // "Low Pr": intl.formatMessage({id: "Low PR"}),
    // "High Pr": intl.formatMessage({id: "High PR"}),
    "Key:": intl.formatMessage({ id: "Key:" }),
    lang: localStorage.getItem("OMNI2_PRIORITY_LANGUAGE"),
    "SLEEP STATISTICS": intl.formatMessage({ id: "Sleep Statistic" }),
    "Total Sleep Time": intl.formatMessage({ id: "Total Sleep Time" }),
    "Awake (+ Sleep Latency)": intl.formatMessage({
      id: "Awake (+ Sleep Latency)",
    }),
    "Min:": intl.formatMessage({ id: "Min:" }),
    "Max:": intl.formatMessage({ id: "Max:" }),
    "Ave:": intl.formatMessage({ id: "Ave:" }),
    "Lo. Threshold Exceeded:": intl.formatMessage({
      id: "Lo. Threshold Exceeded:",
    }),
    "Hi. Threshold Exceeded:": intl.formatMessage({
      id: "Hi. Threshold Exceeded:",
    }),
    Events: intl.formatMessage({ id: "Events" }),
    "Apnea events": intl.formatMessage({ id: "Apnea events" }),
    // "Expiration time": intl.formatMessage({id: "Expiration time"}),
    "CURSOR MEASUREMENT": intl.formatMessage({ id: "CURSOR MEASUREMENT" }),
    "Cursor reading": intl.formatMessage({ id: "Cursor reading" }),
    Thresholds: intl.formatMessage({ id: "Thresholds" }),
    "Hi:": intl.formatMessage({ id: "Hi:" }),
    "Lo:": intl.formatMessage({ id: "Lo:" }),
    STATISTICS: intl.formatMessage({ id: "STATISTICS" }),
    "(for displayed timeline)": intl.formatMessage({
      id: "(for displayed timeline)",
    }),
    // "Expiration time:": intl.formatMessage({id: "Expiration time:"}),
    "Off-Pillow": intl.formatMessage({ id: "Off-Pillow" }),
    "Time (Vital Signs not detected):": intl.formatMessage({
      id: "Time (Vital Signs not detected):",
    }),
    "Off-Pillow Events": intl.formatMessage({ id: "Off-Pillow Events" }),
    " (6pm-6am)": intl.formatMessage({ id: " (6pm-6am)" }),
  };

  const urlParams = new URLSearchParams(props.location.search);
  const patient_id = urlParams.get("patient_id");
  const enroll = urlParams.get("enroll");
  const premium = urlParams.get("premium");

  useEffect(() => {
    // change width
    changeWidth(98, 30);

    // main zoom
    setZoom();

    // zoom
    zoomReport();

    // const splitting = props.location.search?.split("=");
    // fetchSleepLog();

    return () => {
      sessionStorage.removeItem("OAC_REPORT_SELECTED_DATE");
    };
  }, []);

  useEffect(() => {
    clearChart();
    updateChart(props.sl, translations);
    clearVitalSignChart();
    updateVitalSignChart(props.vitalSign, translations);
    setState({
      ...state,
      patient_name: props.patient?.name,
      dob: moment(props.patient?.dob, "YYYY-MM-DD").toDate(),
      exportFrom: moment(props.patient?.enroll).toDate(),
    });
  }, [props.patient]);

  useEffect(() => {
    sessionStorage.setItem(
      "OAC_REPORT_SELECTED_DATE",
      moment(state.selectedDate).format("YYYY_MM_DD")
    );
    Zoomed.zoomed = 0;
    Zoomed.previousK = 1;
    Zoomed.previousX = 0;
    Zoomed.translatedX = 0;
    fetchSleepLog();

    $("#patient-report").on("click", "#reload-btn", function () {
      fetchSleepLog();
    });

    return () => {
      $("#patient-report").off("click");
    };
  }, [state.selectedDate]);

  const fetchSleepLog = () => {
    const start = getStartDate();
    const days = _.range(start, start - 8)
      .map((i) => {
        return moment(state.selectedDate).add(i, "days").format("YYYY-M-D");
      })
      .filter((d) => {
        return (
          d === moment(Number(enroll)).format("YYYY-M-D") ||
          moment(d, "YYYY-M-D").isAfter(moment(Number(enroll)))
        );
      });

    props.getSleepLog({
      patient_id: patient_id,
      days: days,
    });
  };

  const getStartDate = (isFetchData = true) => {
    const now = moment().utcOffset(state.timezone);
    const noon = moment(isFetchData ? state.selectedDate : undefined)
      .utcOffset(state.timezone)
      .hour(12)
      .minute(0)
      .second(0);

    let start = -1;
    if (now.isAfter(noon)) {
      start = 0;
    }
    return start;
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const backTxt = intl.formatMessage({ id: `Back` });
  const dischargeTxt = intl.formatMessage({ id: `Discharge Resident` });
  const transferTxt = intl.formatMessage({ id: `Transfer` });
  const editTxt = intl.formatMessage({ id: `Edit` });
  const printPreviewTxt = intl.formatMessage({ id: `Print Preview` });

  const handleDischargeModalCancel = () => {
    setState({ ...state, isOpenDischargeModal: false });
  };

  const handleDischargeModalConfirm = () => {
    setState({ ...state, isOpenDischargeReConfirmModal: true });
  };

  const handleDischargeModalReconfirmConfirm = async () => {
    await props.dischargePatient({
      patient_id: props.patient.id,
    });

    setTimeout(() => {
      props.history.goBack();
    }, 5000);

    // if (res) {
    //     setState({...state, isOpenDischargeModal: false, isOpenDischargeReConfirmModal: false});
    //     props.history.push("/");
    // }
  };

  const handleDischargeModalReconfirmCancel = () => {
    setState({ ...state, isOpenDischargeReConfirmModal: false });
  };

  const handleOpenDischargeModal = () => {
    setState({ ...state, isOpenDischargeModal: true });
  };

  const handleOpenEditModal = () => {
    setState({ ...state, isOpenEditModal: true });
  };

  const handleEditModalCancel = () => {
    setState({ ...state, isOpenEditModal: false });
  };

  const handleEditModalConfirm = async () => {
    props.editPatient({
      patient_id: props.patient?.id,
      name: state.patient_name,
      dob: moment(state.dob).format("YYYY-MM-DD"),
      patient_last_update: props.patient?.last_update,
    });

    setState({ ...state, isOpenEditModal: false });
  };

  const switchReport = (type) => {
    if (type === 1) {
      clearChart();
      updateChart(props.sl, translations);
    } else {
      clearVitalSignChart();
      updateVitalSignChart(props.vitalSign, translations);
    }
    setState({ ...state, typeOfReport: type });
  };

  const onPrintByChrome = () => {
    let prtContent = document.getElementsByTagName("html")[0];
    let WinPrint = window.open(
      "",
      "",
      "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
    );
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    // WinPrint.close();
  };

  const onPrint = () => {
    props.openLoading();
    // setState({...state, isOpenPrintPreview: true});
    let doc = new jsPDF("landscape", "px", "a4");
    const el = document.getElementById("report-content");
    $(".pharse-table tr").css("height", 50);
    $(".sleep-trend table td").css("height", 88);
    // id
    // let selection = ".report-content";
    // if (state.typeOfReport !== 1) {
    //     selection = selection + `-${state.typeOfReport}`;
    // }

    el.style.height = "1300px";
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();
    let canvasWidth = 2100;
    let canvasHeight = 1400;
    let left = 30,
      top = 15;

    // if (window.screen.width < 600) {
    //     canvasWidth = $(el).width() * 1.65;
    //     canvasHeight = $(el).height() * 1.5;
    //     el.style.height = "1400px";
    //     left = 10;
    //     top = 5;
    // }
    // var scale = 'scale(1)';
    // document.body.style.webkitTransform =  scale;    // Chrome, Opera, Safari
    // document.body.style.msTransform =   scale;       // IE 9
    // document.body.style.transform = scale;     // General
    // alert(`1.${width}, 2.${height}, 3.${canvasWidth}, 4.${canvasHeight}`);
    // $('meta[name=viewport]').remove();
    // $('head').append('<meta name="viewport" content="width=1600, maximum-scale=1.0, user-scalable=yes, target-densityDpi=device-dpi" />' );
    // const scale = window.outerWidth / window.innerWidth;
    // const scale2 = window.innerWidth / window.outerWidth;
    html2canvas(el, {
      width: canvasWidth,
      height: canvasHeight,
      scale: 1.5,
    }).then((canvas) => {
      let img = canvas.toDataURL("image/png,0.5");
      doc = doc.addImage(img, "JPEG", left, top, width, height);
      // doc.save(`OAC_Report_${moment().format("YYYYMMDD HH:mm:ss")}`);
      doc.autoPrint();
      props.closeLoading();
      window.open(doc.output("bloburl"));
    });

    el.style.height = "1050px";
    $(".pharse-table tr").css("height", 30);
    $(".sleep-trend table td").css("height", 77);
  };

  const handleExportCsv = () => {
    // export
    props.exportCsv({
      patient_id: props.patient?.id,
      locale: localStorage.getItem("OMNI2_PRIORITY_LANGUAGE") || "en",
      patient_name: props.patient?.name,
    });

    // set state
    setState({
      ...state,
      isOpenExportCSVModal: false,
    });
  };

  const handleChangeSelectedDate = (val) => {
    setState({ ...state, selectedDate: val });
  };

  const PatientName = ti(
    {
      type: "text",
      name: "patient_name",
      value: state.patient_name,
      className: cx(
        "enroll-input-sm w330",
        state.patient_name === props.patient?.name ? " color-grey" : ""
      ),
      maxLength: 20,
    },
    `Name of Resident`,
    handleChange
  );

  const firstDayOfMonth = moment()
    .startOf("month")
    .format("YYYY-MM-DD / HH:mm");
  const currentDayOfMonth = moment()
    .utcOffset(state.timezone)
    .format("YYYY-MM-DD / HH:mm");

  return (
    <>
      <div
        className={
          localStorage.getItem("OMNI2_PRIORITY_LANGUAGE") !== "en"
            ? "patient-report html-canvas-container ja"
            : "patient-report html-canvas-container"
        }
        id="patient-report"
      >
        <div className="middle-icons"></div>
        <div className="right-icons">
          <ReportDatePicker
            selectedDate={state.selectedDate}
            plusDaysDisplay={getStartDate()}
            plusDays={getStartDate(false)}
            minDate={moment(props.patient?.enroll).toDate()}
            handleChange={handleChangeSelectedDate}
          />
          <span
            className={premium === "true" ? "" : "invisible"}
            onClick={() => setState({ ...state, isOpenExportCSVModal: true })}
          >
            <img src={ExportCSVImg} />
          </span>
          <span
            onClick={() => switchReport(1)}
            className={state.typeOfReport === 1 ? "active" : ""}
          >
            <img src={SleepTrendImg} />
          </span>
          <span
            onClick={() => switchReport(2)}
            className={state.typeOfReport === 2 ? "active" : ""}
          >
            <img src={VitalSignImg} />
          </span>
          <span title={dischargeTxt} onClick={handleOpenDischargeModal}>
            <img
              className={props.hasPending ? "disabled" : ""}
              id="sidebar-btn-3"
              src={props.hasPending ? dischargeDisabledBtn : dischargeBtn}
            />
          </span>
          {/*<span title={transferTxt}>*/}
          {/*<img className={props.hasPending ? "disabled": ""} id="sidebar-btn-4" src={props.hasPending ? transferDisabledBtn :transferBtn}/>*/}
          {/*</span>*/}
          <span title={editTxt} onClick={() => handleOpenEditModal()}>
            <img src={editBtn} />
          </span>
          <span title={printPreviewTxt} onClick={onPrint}>
            <img src={PrintoutWhite} />
          </span>
        </div>
        <div
          id="report-content"
          className={
            state.typeOfReport === 1 ? "report-content" : "report-content-2"
          }
        >
          <div className={state.typeOfReport === 1 ? "" : "hidden"}>
            <div className="rept-header">
              <div className="rept-header-left">
                <div className="report-title">
                  {t(`OAC RESIDENT SLEEP REPORT`)}
                </div>
                <div className="center-name">{props.patient?.oah_name}</div>
              </div>
              <div className="rept-header-center mt10">
                <div className="patient-info">
                  <span className="patient-name-txt">
                    {t("Resident Name:")}&nbsp;
                  </span>
                  <b className="patient-name-val">{props.patient?.name}</b>
                </div>
                <div className="patient-info">
                  <span className="patient-name-txt">
                    {t("Resident Birthdate:")}&nbsp;
                  </span>
                  <b className="patient-name-val">
                    {moment(props.patient?.dob).format("YYYY-MM-DD")}
                  </b>
                </div>
              </div>
              <div className="rept-header-right mt10">
                <div className="bed-name">
                  &nbsp;
                  {/*<span className="bed-name-txt">{t("Bed Name:")}&nbsp;</span>*/}
                  {/*<span className="bed-name-val">{props.patient?.bed_name}</span>*/}
                </div>
                <div className="date-report">
                  {/*<span*/}
                  {/*className="date-report-txt">{t("Date/Time of Report:")}&nbsp;&nbsp;&nbsp;&nbsp;</span>*/}
                  {/*<span className="date-report-val">{moment().format("YYYY-MM-DD / HH:mm")}</span>*/}
                  <span className="bed-name-txt">{t("Bed Name:")}&nbsp;</span>
                  <span className="bed-name-val">
                    {props.patient?.bed_name}
                  </span>
                </div>
              </div>
            </div>
            <div className="sleep-pharse-trend"></div>
            <div className="sleep-trend"></div>
            <div className="caution">
              {t(
                `CAUTION: Data is not intended for medical diagnosis or critical medical care purposes.`
              )}
            </div>
          </div>
          <div className={state.typeOfReport === 2 ? "" : "hidden"}>
            <div className="rept-header">
              <div className="rept-header-left">
                <div className="report-title">
                  {t(`OAC RESIDENT VITAL SIGNS REPORT`)}
                </div>
                <div className="center-name">{props.patient?.oah_name}</div>
              </div>
              <div className="rept-header-center mt10">
                <div className="patient-info">
                  <span className="patient-name-txt">
                    {t("Resident Name:")}&nbsp;
                  </span>
                  <b className="patient-name-val">{props.patient?.name}</b>
                </div>
                <div className="patient-info">
                  <span className="patient-name-txt">
                    {t("Resident Birthdate:")}&nbsp;
                  </span>
                  <b className="patient-name-val">
                    {moment(props.patient?.dob).format("YYYY-MM-DD")}
                  </b>
                </div>
              </div>
              <div className="rept-header-right mt10">
                <div className="bed-name">&nbsp;</div>
                <div className="date-report">
                  {/*<span*/}
                  {/*className="date-report-txt">{t("Date/Time of Report:")}&nbsp;&nbsp;&nbsp;&nbsp;</span>*/}
                  {/*<span className="date-report-val">{moment().format("YYYY-MM-DD / HH:mm")}</span>*/}
                  <span className="bed-name-txt">{t("Bed Name:")}&nbsp;</span>
                  <span className="bed-name-val">
                    {props.patient?.bed_name}
                  </span>
                </div>
              </div>
            </div>
            <div className="rept-content"></div>
            <div className="caution-2">
              {t(
                `CAUTION: Data is not intended for medical diagnosis or critical medical care purposes.`
              )}
            </div>
            {/*<div id="data"></div>*/}
          </div>
        </div>
        {state.isOpenDischargeModal ? (
          <IrespModal
            clazz="discharge-modal"
            title={t(`Discharge Resident`)}
            contents={[
              <div className="mt10">
                <b>{t(`Resident's name:`)}&nbsp;</b>
                <span className="red">{emptyToDash(props.patient?.name)}</span>
              </div>,
              <div className="">
                <b>{t(`Resident's birthdate:`)}&nbsp;</b>
                <span className="red">
                  {moment(props.patient?.dob).format("YYYY-MM-DD")}
                </span>
              </div>,
              <div className="m35">{t(`Confirming discharge will:`)}</div>,
              <div className="">{t(`* Stop resident monitoring`)}</div>,
              <div className="">{t(`* Unallocate bed`)}</div>,
              <div className="m35 mb10">
                {t(`This action cannot be undone.`)}
              </div>,
            ]}
            buttons={[
              <button onClick={handleDischargeModalCancel}>
                {t(`Cancel`)}
              </button>,
              <button onClick={handleDischargeModalConfirm}>
                {t(`Confirm`)}
              </button>,
            ]}
          />
        ) : null}

        {state.isOpenDischargeReConfirmModal ? (
          <IrespModal
            clazz="discharge-reconfirm-modal"
            title={t(`Are you sure?`)}
            contents={[
              <div className="red font-weight-bold">
                {t(`This action cannot be undone.`)}
              </div>,
            ]}
            buttons={[
              <button onClick={handleDischargeModalReconfirmCancel}>
                {t(`Cancel`)}
              </button>,
              <button onClick={handleDischargeModalReconfirmConfirm}>
                {t(`YES`)}
              </button>,
            ]}
          />
        ) : null}

        {state.isOpenEditModal ? (
          <IrespModal
            clazz="edit-modal"
            title={t(`Edit Resident's Details`)}
            contents={[
              <div className="one-line mt-lg-5 mt-md-5 mt-sm-5">
                <div>{t(`Resident's Name:`)}</div>
                <div>
                  {PatientName}
                  <br />
                  <span className="descr ml-4">{t(`Max. 20 characters`)}</span>
                </div>
              </div>,
              <div className="one-line">
                <div>{t(`Resident's Birthdate:`)}</div>
                <div>
                  <InputDatePicker
                    value={state.dob}
                    showAll={true}
                    minDate={moment().add(-365, "days")}
                    className={cx(
                      "enroll-input-sm",
                      moment(state.dob).format("YYYYMMDD") ===
                        moment(props.patient?.dob).format("YYYYMMDD")
                        ? " color-grey"
                        : ""
                    )}
                    onChange={(v) =>
                      setState({
                        ...state,
                        dob: v,
                      })
                    }
                  />
                </div>
              </div>,
            ]}
            buttons={[
              <button onClick={handleEditModalCancel}>{t(`Cancel`)}</button>,
              <button onClick={handleEditModalConfirm}>{t(`Confirm`)}</button>,
            ]}
          />
        ) : null}
        {state.isOpenExportCSVModal ? (
          <IrespModal
            clazz="export-csv-modal"
            title={t(`Export Resident Data`)}
            contents={[
              <div className="font-weight-bold">{t(`for date range:`)}</div>,
              <br />,
              <div>
                <div className="inline w110px">
                  <span className="color-red">*&nbsp;</span>
                  {t(`From:`)}
                </div>
                <div className="inline">
                  <b>{firstDayOfMonth}</b>
                </div>
              </div>,
              <br />,
              <div>
                <div className="inline w110px">
                  <span className="color-red">*&nbsp;</span>
                  {t(`To:`)}
                </div>
                <div className="inline">
                  <b>{currentDayOfMonth}</b>
                </div>
              </div>,
            ]}
            buttons={[
              <button
                onClick={() =>
                  setState({
                    ...state,
                    isOpenExportCSVModal: false,
                  })
                }
              >
                {t(`Cancel`)}
              </button>,
              <button onClick={handleExportCsv}>{t(`Confirm`)}</button>,
            ]}
          />
        ) : null}
      </div>
      {state.isOpenPrintPreview ? <PrintPreview /> : undefined}
    </>
  );
}
