import React, {useEffect} from "react";
import PropTypes from "prop-types";
import "./EspTable.css";
import {messageWrapper as t} from "../../utils/message-wrapper";
const name = "components.espTable";

export function EspTable(props) {

    let headers = [];
    let body = [];

    useEffect(() => {
        return function cleanup() {
            headers = [];
            body = [];
        }
    }, []);

    if (props.renderHeader) {
        headers = props.renderHeader();
    }

    if (props.renderBody) {
        body = props.renderBody();
    }

    return (
        <>
            <div className={"esp-table " + props.className} style={{...props.style}}>
                <div className="esp-table-header">
                    <table>
                        <thead>
                        {headers}
                        </thead>
                    </table>
                </div>
                <div className="esp-table-body" onScroll={props.onScroll}>
                    <table>
                        <tbody>
                        {body}
                        </tbody>
                    </table>
                </div>
            </div>
            {
                props.errors
                    ? props.errors.map(e=>{
                        if(typeof e === 'string') {
                            return (<div className="table-error">{t(e)}</div>);
                        } else {
                            return (<div className="table-error">{t(e.message, {0: e.value})}</div>);
                        }
                    })
                    : null
            }
            {
                props.errorHolder && (!props.errors || props.errors.length < 1)
                ? <div className="table-error-white">&nbsp;</div>
                    : null
            }
        </>
    );
}

EspTable.propTypes = {
    data: PropTypes.object,
    renderHeader: PropTypes.func,
    renderBody: PropTypes.func,
    errors: PropTypes.object,
};