import {SLEEP_PHARSE, CHART_UNITS, COLOR, COLOR_SLEEP_PHARSE, HEIGHT_PHARSE, NAMES} from "./constant";
import {renderContent} from "./layout2";
import * as d3 from "d3";

// const fakeData = {
//     dataPoints: [],
//     events: []
// };
//
// const dataPoints = [];
// for (let i = 0; i < CHART_UNITS; i++) {
//     if((i > CHART_UNITS / 6 && i < CHART_UNITS / 4) || (i > CHART_UNITS / 2 && i < CHART_UNITS / 1.5)) {
//         if(i % 4 === 0) dataPoints.push(Math.floor(Math.random() * 99))
//         else dataPoints.push(0);
//     } else {
//         dataPoints.push(0);
//     }
// }
//
// const events = [];
// for( let i = 0; i < 4; i++) {
//     let type;
//
//     switch(i%4) {
//         case 0: type = "LOW_RESP"; break;
//         case 1: type = "HIGH_RESP"; break;
//         case 2: type = "LOW_PR"; break;
//         case 3: type = "HIGH_PR"; break;
//     }
//
//     events.push({
//         start: Math.floor(CHART_UNITS/ (6 - i/2) * 4),
//         duration: 1020,
//         type: type
//     });
// }
//
// for( let i = 0; i < 4; i++) {
//     let type;
//
//     switch(i%4) {
//         case 0: type = "LOW_RESP"; break;
//         case 1: type = "HIGH_RESP"; break;
//         case 2: type = "LOW_PR"; break;
//         case 3: type = "HIGH_PR"; break;
//     }
//
//     events.push({
//         start: Math.floor(CHART_UNITS/ (2 - i/8) * 4),
//         duration: 1020,
//         type: type
//     });
// }
//
//
// fakeData.dataPoints = dataPoints;
// fakeData.events = events;

export function updateVitalSignChart(vitalSign, translations) {
    if (!vitalSign) return;
    renderContent(vitalSign, translations);
}
export function clearVitalSignChart() {
    const content = d3.select(".rept-content");
    content.html("");
}
