import {GET_NURSES} from "../actions/manageAccountAction";

const initialState = {};

const manageAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NURSES:
            const {nurses, beds, license_count} = action.payload;
            return {...state, nurses, beds, license_count};
        default:
            return state
    }
};

export default manageAccountReducer;