import React, {useEffect, useState} from "react";
import {useAuth} from "../../connections/net";
import {messageWrapper as t, translateInput as ti} from "../../utils/message-wrapper";
import {IrespModal} from "../common/IrespModal";
import "./ChangePw.css";

export function ChangePw(props) {

    useAuth();

    const [state, setState] = useState({openModal: true});

    useEffect(() => {
        // window.location.replace("/import-csv-config");
    }, []);

    const handleResetPasswordClose = () => {
        localStorage.setItem("OMNI2_NEED_CHANGE_PASSWORD", "true");
        props.clearData();
        props.history.goBack();
    };

    const handleResetPasswordConfirm = async () => {
        if (!state.oldPassword || !state.newPassword || !state.reEnterPassword) {
            props.setError("Input must be not empty.");
            return;
        }

        if (state.newPassword !== state.reEnterPassword) {
            props.setError("New passwords do not match.");
            return;
        }

        // different with old
        if (state.newPassword === state.oldPassword) {
            props.setError("New password must be different from existing.");
            return;
        }

        // validate new password length
        if (state.newPassword.length < 8 || state.newPassword.length > 20) {
            props.setError("Password should be between 8 and 20 characters inclusive.");
            return;
        }

        // validate format
        if (state.newPassword.search(/[A-Z]/) < 0
            || state.newPassword.search(/[a-z]/) < 0
            || state.newPassword.search(/[0-9]/) < 0
            || state.newPassword.search(/[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+/) < 0
        ) {
            props.setError("Password format.");
            return;
        }

        const res = await props.changePassword(state.oldPassword, state.newPassword);
        if (res) {
            props.history.push("/");
        }
    };

    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    };

    const OldPassword = ti({type: "password", name: "oldPassword"}, `Current password`, handleChange);
    const NewPassword = ti({type: "password", name: "newPassword"}, `New password`, handleChange);
    const ReEnterPassword = ti({type: "password", name: "reEnterPassword"}, `Re-enter password`, handleChange);

    return (
        <div className="cc-admin-change-pw">
            {
                state.openModal
                    ? <IrespModal
                        clazz="change-pw-modal"
                        title={t(`Update Password`)}
                        contents={[
                            <div>{t(`Once you have finished, use your new password to sign-in.`)}</div>,
                            <div className="mt20" >
                                {OldPassword}
                            </div>,
                            <div className="mt50">
                                {NewPassword}
                            </div>,
                            <div className="mt20">
                                {ReEnterPassword}
                            </div>,
                            props.success
                                ? t(`Success`)
                                : <div className="error">
                                    {props.error ? t(props.error) : undefined}
                                </div>,
                        ]}
                        buttons={[
                            <button onClick={handleResetPasswordClose}>{t(`Cancel`)}</button>,
                            <button onClick={handleResetPasswordConfirm}>{t(`Confirm`)}</button>,
                        ]}
                    />
                    : null
            }
        </div>
    );
}