import React, {useRef, useState} from "react";
import "./IrespFilterDate.css";
import {OmniCalendar} from "./OmniCalendar";
import {useOutsideDetect} from "../../utils/hooks";
import moment from "moment";
import {FontAwesomeIcon as FA} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import {formatDisplayDate} from "../../utils/format";

export function IrespFilterDate({handleSelect, value}) {
    const [state, setState] = useState({selectedDate: new Date()});

    const dateRef = useRef(null);

    useOutsideDetect(dateRef, state.isOpenCalendar, () => setState({...state, isOpenCalendar: false}));

    const handleChange = (val) => {
        setState({selectedDate: val});
        handleSelect(val);
    };

    const openCalendar = () => {
        setState({...state, isOpenCalendar: true});
    };

    return (
        <div className="iresp-filter-date">
            <div ref={dateRef} className="name-12" tabIndex="1">
                <div className="input-group-custom">
                    <div className="input-group-custom-prepend">
                        <FA icon={faCalendar}/>
                    </div>
                    <input readOnly value={formatDisplayDate(value)} placeholder="Select value" onClick={openCalendar}/>
                </div>
                {
                    state.isOpenCalendar
                        ? <OmniCalendar
                            onChange={(value) => handleChange(value)}
                            value={state.selectedDate}
                            minDate={moment().add(-365, 'days')}
                        />
                        : undefined
                }
            </div>
        </div>
    )
}