import React, {useEffect, useState} from "react";
import {useAuth} from "../../../connections/net";
import {messageWrapper as t} from "../../../utils/message-wrapper";
import {EspTable} from "../../common/EspTable";
import {IrespModal} from "../../common/IrespModal";
import {OACNumberSelection} from "../../common/oac/OACNumberSelection";
import {OACTimeSelection} from "../../common/oac/OACTimeSelection";
import {EspDropdown} from "../../common/EspDropdown";
import {formatTime} from "../../../utils/format";
import {compareOACBedName} from "../../../utils/sort";
import {setZoom} from "../../../utils/set-zoom";
import "./Thresholds.css";
import {useIntl} from "react-intl";
import cx from "classnames";
import {Models} from "../../../constants/models";

const DefaultThreshold = {
    vital_enabled: true,
    rpm_high: 0,
    rpm_low: 5,
    bpm_high: 120,
    bpm_low: 40,
    off_bed_delay: 0,
    off_bed_enabled: true,
    off_bed_from_minute: 0,
    off_bed_to_minute: 0,
    off_bed_from_hour: 21,
    off_bed_to_hour: 7,
    end_of_life_enabled: false,
    awake_sens: 2,
    alert_pause: 10,
    vol: 2
};

export function Thresholds(props) {

    useAuth();

    const intl = useIntl();

    // translation
    const translation = {
        High: intl.formatMessage({id: "High"}),
        Low: intl.formatMessage({id: "Low"}),
        Normal: intl.formatMessage({id: "Normal"}),
        "n/a": intl.formatMessage({id: "n/a"}),
    };

    const VolumeMap = {
        1: "Low",
        2: "Normal",
        3: "High",
    };

    // set zoom
    setZoom();

    const [state, setState] = useState({tab: 1, edit: {}});

    useEffect(() => {
        props.getThresholdSetting();
    }, []);

    const ja = localStorage.getItem("OMNI2_PRIORITY_LANGUAGE") !== "en";

    const generateThresholds = () => {
        const generateHeader = () => {
            return <>
                <tr>
                    <td rowSpan={3} className="w1">
                        {t(`Resident's Name`)}
                    </td>
                    <td rowSpan={3} className="w2">
                        {t(`Bed`)}
                    </td>
                    <td rowSpan={1} colSpan={5} className="w10">
                        {t(`VITAL SIGNS`)}
                    </td>
                    <td rowSpan={1} colSpan={6} className="w11">
                        {t(`RESIDENT STATUS`)}
                    </td>
                    <td rowSpan={3} className="w8">
                        {t(`Awake Sensitivity`)}
                    </td>
                    <td rowSpan={3} className="w7 text-center">
                        {t(`BCU Volume`)}
                    </td>
                    <td rowSpan={3} className="w7">
                        {t(`Action`)}
                    </td>
                </tr>
                <tr>
                    <td rowSpan={2} className="w4">
                        {t(`Status`)}
                    </td>
                    <td colSpan={2}>
                        {t(`RESP`)}
                    </td>
                    <td colSpan={2}>
                        {t(`PR`)}
                    </td>
                    <td colSpan={5}>
                        {t(`Off-Pillow`)}
                    </td>
                    <td rowSpan={2} className="w8">
                        {t(`Vital Signs not detected Alert`)}
                    </td>
                </tr>
                <tr>
                    <td className="w3">{t(`Low`)}</td>
                    <td className="w3">{t(`High`)}</td>
                    <td className="w3">{t(`Low`)}</td>
                    <td className="w3">{t(`High`)}</td>
                    <td className="w4">{t(`Status`)}</td>
                    <td className="w5">{t(`From`)}</td>
                    <td className="w6">{t(`To`)}</td>
                    <td className="w9">{t(`Notification delay (seconds)`)}</td>
                    <td className="w9">{t(`Alert-paused duration (minutes)`)}</td>
                </tr>
            </>
        };
        const generateBody = () => {
            const filteredBeds = props.beds
                .filter(bed => bed.bcu_wifi_mac && bed.patient_id && bed.patient_id !== "");
            if (!filteredBeds || filteredBeds.length < 1) {
                return <tr>
                    <td className="max">{t(`No record found`)}</td>
                </tr>
            } else {
                return filteredBeds
                    .sort((a, b) => compareOACBedName(a.bed_name, b.bed_name))
                    .map(b => {
                            const isHighlightName = b.config?.vital_enabled?.toString() !== DefaultThreshold.vital_enabled.toString()
                                || b.config?.rpm_low !== DefaultThreshold.rpm_low
                                || b.config?.rpm_high !== DefaultThreshold.rpm_high
                                || b.config?.bpm_low !== DefaultThreshold.bpm_low
                                || b.config?.bpm_high !== DefaultThreshold.bpm_high
                                || b.config?.off_bed_enabled?.toString() !== DefaultThreshold.off_bed_enabled.toString()
                                || b.config?.off_bed_from_hour !== DefaultThreshold.off_bed_from_hour
                                || b.config?.off_bed_from_minute !== DefaultThreshold.off_bed_from_minute
                                || b.config?.off_bed_to_hour !== DefaultThreshold.off_bed_to_hour
                                || b.config?.off_bed_to_minute !== DefaultThreshold.off_bed_to_minute
                                || b.config?.off_bed_delay !== DefaultThreshold.off_bed_delay
                                || b.config?.end_of_life_enabled?.toString() !== DefaultThreshold.end_of_life_enabled.toString()
                                || b.config?.awake_sens !== DefaultThreshold.awake_sens
                                || b.config?.alert_pause !== DefaultThreshold.alert_pause
                                || b.config?.vol !== DefaultThreshold.vol;

                            return (
                                <tr>
                                    <td className={isHighlightName ? "w1 highlight" : "w1"}>{b.patient_name}</td>
                                    <td className="w2">{b.bed_name}</td>
                                    {b.config ?
                                        <td className={b.config?.vital_enabled?.toString() !== DefaultThreshold.vital_enabled.toString() ? "w4 highlight" : "w4"}>
                                            {b.config?.vital_enabled === true || b.config?.vital_enabled === "true" ? t("Enabled") : t("Disabled")}
                                        </td> :
                                        <td></td>}
                                    {b.config ?
                                        <td className={b.config?.rpm_low !== DefaultThreshold.rpm_low ? "w3 highlight" : "w3"}>{b.config?.rpm_low}</td> :
                                        <td></td>}
                                    {b.config ?
                                        <td className={b.config?.rpm_high !== DefaultThreshold.rpm_high ? "w3 highlight" : "w3"}>{b.config?.rpm_high}</td> :
                                        <td></td>}
                                    {b.config ?
                                        <td className={b.config?.bpm_low !== DefaultThreshold.bpm_low ? "w3 highlight" : "w3"}>{b.config?.bpm_low}</td> :
                                        <td></td>}
                                    {b.config ?
                                        <td className={b.config?.bpm_high !== DefaultThreshold.bpm_high ? "w3 highlight" : "w3"}>{b.config?.bpm_high}</td> :
                                        <td></td>}
                                    {b.config ?
                                        <td className={b.config?.off_bed_enabled?.toString() !== DefaultThreshold.off_bed_enabled.toString() ? "w4 highlight" : "w4"}>
                                            {b.config?.off_bed_enabled?.toString() === "true" ? t("Enabled") : t("Disabled")}
                                        </td> :
                                        <td></td>}
                                    {b.config
                                        ?
                                        <td className={
                                            b.config?.off_bed_from_hour !== DefaultThreshold.off_bed_from_hour
                                            || b.config?.off_bed_from_minute !== DefaultThreshold.off_bed_from_minute ? "w5 highlight" : "w5"
                                        }>
                                            {formatTime(b.config?.off_bed_from_hour)}:{formatTime(b.config?.off_bed_from_minute)}
                                        </td>
                                        :
                                        <td></td>}
                                    {b.config
                                        ?
                                        <td className={
                                            b.config?.off_bed_to_hour !== DefaultThreshold.off_bed_to_hour
                                            || b.config?.off_bed_to_minute !== DefaultThreshold.off_bed_to_minute ? "w6 highlight" : "w6"
                                        }>
                                            {formatTime(b.config?.off_bed_to_hour)}:{formatTime(b.config?.off_bed_to_minute)}
                                        </td>
                                        :
                                        <td></td>}
                                    {b.config ?
                                        <td className={b.config?.off_bed_delay !== DefaultThreshold.off_bed_delay ? "w9 highlight" : "w9"}>{b.config?.off_bed_delay || 0}</td> :
                                        <td></td>}
                                    {b.config ?
                                        <td className={b.config?.alert_pause !== DefaultThreshold.alert_pause ? "w9 highlight" : "w9"}>{b.config?.alert_pause}</td> :
                                        <td></td>}
                                    {b.config ?
                                        <td className={b.config?.end_of_life_enabled?.toString() !== DefaultThreshold.end_of_life_enabled.toString() ? "w8 highlight" : "w8"}>
                                            {b.config?.end_of_life_enabled === true || b.config?.end_of_life_enabled === "true" ? t("Enabled") : t("Disabled")}
                                        </td> :
                                        <td></td>}
                                    {b.config
                                        ?
                                        <td className={b.config?.awake_sens !== DefaultThreshold.awake_sens ? "w8 highlight" : "w8"}>
                                            {b.config?.awake_sens}
                                        </td>
                                        : <td></td>}
                                    {b.config
                                        ?
                                        <td className={b.model && b.model === Models.SUPPORT_AUDIO && b.config?.vol !== DefaultThreshold.vol ? "text-center w7 highlight" : "text-center w7"}>
                                            {t(b.model && b.model === Models.SUPPORT_AUDIO ? VolumeMap[b.config?.vol] : "n/a")}
                                        </td>
                                        : <td></td>}
                                    <td className="w7">{generateAction(b)}</td>
                                </tr>
                            )
                        }
                    );
            }
        };

        return <>
            <EspTable className="threshold-tbl mt-lg-5 mt-md-5 mt-sm-5" renderHeader={generateHeader}
                      renderBody={generateBody}/>
        </>
    };

    const generateAction = (b) => {
        return <a className="link" onClick={() => openEditModal(b)}>{t(`Edit`)}</a>
    };

    const openEditModal = (p) => {
        setState({...state, isOpenEditModal: true, edit: p});
    };

    const closeEditModal = () => {
        setState({...state, isOpenEditModal: false, edit: undefined});
    };

    const editConfirm = () => {
        // console.log(state.edit.config)
        props.updateThresholdSetting({
            "friendly_name": state.edit?.id,
            "last_update": state.edit?.last_update,
            "rpm_low": state.edit?.config?.rpm_low,
            // "rpm_low" : 10,
            "rpm_high": state.edit?.config?.rpm_high,
            "bpm_low": state.edit?.config?.bpm_low,
            "bpm_high": state.edit?.config?.bpm_high,
            "off_bed_enabled": state.edit?.config?.off_bed_enabled,
            "off_bed_from_hour": state.edit?.config?.off_bed_from_hour,
            "off_bed_from_minute": state.edit?.config?.off_bed_from_minute,
            "off_bed_to_hour": state.edit?.config?.off_bed_to_hour,
            "off_bed_to_minute": state.edit?.config?.off_bed_to_minute,
            "end_of_life_enabled": state.edit?.config?.end_of_life_enabled,
            "awake_sens": state.edit?.config?.awake_sens,
            "awake_enabled": state.edit?.config?.awake_enabled,
            "absent_time": state.edit?.config?.absent_time,
            "off_bed_delay": state.edit?.config?.off_bed_delay || 0,
            "vital_enabled": state.edit?.config?.vital_enabled,
            "alert_pause": state.edit?.config?.alert_pause,
            "vol": state.edit?.config?.vol,
        });
        setState({...state, isOpenEditModal: false, edit: undefined});
    };

    const handleChange = (e) => {
        let value = e.target.value;

        if (e.target.type === "checkbox") {
            value = e.target.checked === "true" || e.target.checked === true;
        }

        setState({
            ...state,
            edit: {
                ...state.edit,
                config: {...state.edit?.config, [e.target.name]: value}
            }
        });
    };

    const handleChangeFromChild = (field, val) => {
        setState({
            ...state,
            edit: {
                ...state.edit,
                config: {...state.edit?.config, [field]: val}
            }
        });
    };

    const handleResetValue = () => {
        const edit = state.edit;
        setState({
            ...state,
            edit: {
                ...edit,
                config: {
                    ...edit.config,
                    "rpm_low": DefaultThreshold.rpm_low,
                    "rpm_high": DefaultThreshold.rpm_high,
                    "bpm_low": DefaultThreshold.bpm_low,
                    "bpm_high": DefaultThreshold.bpm_high,
                    "off_bed_enabled": DefaultThreshold.off_bed_enabled,
                    "off_bed_from_hour": DefaultThreshold.off_bed_from_hour,
                    "off_bed_from_minute": DefaultThreshold.off_bed_from_minute,
                    "off_bed_to_hour": DefaultThreshold.off_bed_to_hour,
                    "off_bed_to_minute": DefaultThreshold.off_bed_to_minute,
                    "end_of_life_enabled": DefaultThreshold.end_of_life_enabled,
                    "awake_sens": DefaultThreshold.awake_sens,
                    "off_bed_delay": DefaultThreshold.off_bed_delay,
                    "vital_enabled": DefaultThreshold.vital_enabled,
                    "alert_pause": DefaultThreshold.alert_pause,
                }
            }
        });
    };

    return (
        <div className="thresholds">
            <div className="table-content">
                <div>{generateThresholds()}</div>
            </div>
            {
                state.isOpenEditModal && <IrespModal
                    clazz="edit-threshold-modal"
                    title={t(`Edit Alarms & Thresholds`)}
                    contents={[
                        <div className="mt10">
                            {t(`Resident's Name`)}:&nbsp;<b>{state.edit.patient_name || "--"}</b>
                        </div>,
                        <div className="mt10 reset-to-default">
                            <a className="" onClick={handleResetValue}>{t(`Reset to default values`)}</a>
                        </div>,
                        <div className="mt5 half-line-wrapper">
                            <div className="half-line-wrapper-2">
                                <div className="one-line-2">
                                    <b>{t(`Vital Signs Alert:`)}</b>
                                    <span className="forNone">{t(`(0 for "None")`)}</span>
                                    <span className="flex-right">
                                        <div className="custom-control custom-switch">
                                          <input
                                              checked={state.edit?.config?.vital_enabled === true || state.edit?.config?.vital_enabled === "true"}
                                              onChange={handleChange}
                                              name="vital_enabled"
                                              type="checkbox"
                                              className={
                                                  cx(
                                                      "custom-control-input",
                                                  )
                                              }
                                              id="customSwitch4"/>
                                          <label className="custom-control-label" htmlFor="customSwitch4"></label>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div className="half-line-wrapper-3">
                                <div className="white-area w50p h110">
                                    <div className="one-line">
                                        <b>{t(`Respiratory Threshold (RPM):`)}</b>
                                    </div>
                                    <div className="one-line">
                                        <div className="w60">{t(`Low`)}:</div>
                                        <div className="w60">
                                            <OACNumberSelection
                                                onChange={(val) => handleChangeFromChild("rpm_low", val)}
                                                start={5}
                                                end={12}
                                                className={
                                                    cx(
                                                        "w60",
                                                        state.edit?.config?.rpm_low !== DefaultThreshold.rpm_low
                                                            ? "highlight"
                                                            : ""
                                                    )
                                                }
                                                value={state.edit?.config?.rpm_low}/>
                                        </div>
                                        <div className="w60 ml10 ">{t(`High`)}:</div>
                                        <div className="w60">
                                            <OACNumberSelection
                                                onChange={(val) => handleChangeFromChild("rpm_high", val)}
                                                start={19}
                                                end={50}
                                                hasZero={true}
                                                className={
                                                    cx(
                                                        "w60",
                                                        state.edit?.config?.rpm_high !== DefaultThreshold.rpm_high
                                                            ? "highlight"
                                                            : ""
                                                    )
                                                }
                                                value={state.edit?.config?.rpm_high}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-space"></div>
                                <div className="white-area w50p h110">
                                    <div className="one-line">
                                        <b>{t(`Pulse Rate Threshold (BPM):`)}</b>
                                    </div>
                                    <div className="one-line">
                                        <div className="w60">{t(`Low`)}:</div>
                                        <div className="w60">
                                            <OACNumberSelection
                                                onChange={(val) => handleChangeFromChild("bpm_low", val)}
                                                start={30}
                                                end={40}
                                                className={
                                                    cx(
                                                        "w60",
                                                        state.edit?.config?.bpm_low !== DefaultThreshold.bpm_low
                                                            ? "highlight"
                                                            : ""
                                                    )
                                                }
                                                value={state.edit?.config?.bpm_low}/>
                                        </div>
                                        <div className="w60 ml10">{t(`High`)}:</div>
                                        <div className="w60">
                                            <OACNumberSelection
                                                onChange={(val) => handleChangeFromChild("bpm_high", val)}
                                                start={99}
                                                end={120}
                                                hasZero={true}
                                                className={
                                                    cx(
                                                        "w60",
                                                        state.edit?.config?.bpm_high !== DefaultThreshold.bpm_high
                                                            ? "highlight"
                                                            : ""
                                                    )
                                                }
                                                value={state.edit?.config?.bpm_high}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>,
                        <div className="mt10 white-area h200">
                            <div className="one-line">
                                <b>{t(`Off-Pillow (OP) Alert:`)}</b>
                                <span className="flex-right">
                                    <div className="custom-control custom-switch">
                                      <input
                                          checked={state.edit?.config?.off_bed_enabled === true || state.edit?.config?.off_bed_enabled === "true"}
                                          onChange={handleChange}
                                          name="off_bed_enabled"
                                          type="checkbox"
                                          className="custom-control-input" id="customSwitch1"/>
                                      <label className="custom-control-label" htmlFor="customSwitch1"></label>
                                    </div>
                                </span>
                            </div>
                            <div className="one-line">
                                <div className={ja ? "w371 mb5" : "w220 mb5"}>{t(`Notification delay:`)}</div>
                                <div className="">
                                    <OACNumberSelection
                                        onChange={(val) => handleChangeFromChild("off_bed_delay", val)}
                                        start={0}
                                        end={30}
                                        className={
                                            cx(
                                                "w60",
                                                state.edit?.config?.off_bed_delay !== DefaultThreshold.off_bed_delay
                                                    ? "highlight"
                                                    : ""
                                            )
                                        }
                                        value={state.edit?.config?.off_bed_delay}/>
                                </div>
                                &nbsp;{t("seconds")}
                            </div>
                            <div className="one-line mtmb10">
                                <div className={ja ? "w371 mb5" : "w220 mb5"}>{t(`Alert-paused duration:`)}</div>
                                <div className="">
                                    <OACNumberSelection
                                        onChange={(val) => handleChangeFromChild("alert_pause", val)}
                                        start={0}
                                        end={20}
                                        className={
                                            cx(
                                                "w60",
                                                state.edit?.config?.alert_pause !== DefaultThreshold.alert_pause
                                                    ? "highlight"
                                                    : ""
                                            )
                                        }
                                        value={state.edit?.config?.alert_pause}/>
                                </div>
                                &nbsp;{t("minutes")}
                            </div>
                            <div className="one-line">
                                <div className="w120">{t(`Enabled hours:`)}</div>
                                <div className={ja ? "w100" : "w60 ml10"}>{t(`From`)}:</div>
                                <div className="w120">
                                    <OACTimeSelection
                                        startH={0}
                                        endH={23}
                                        startM={0}
                                        endM={59}
                                        onChangeHour={(val) => handleChangeFromChild("off_bed_from_hour", val)}
                                        onChangeMinute={(val) => handleChangeFromChild("off_bed_from_minute", val)}
                                        hour={Number(state.edit?.config?.off_bed_from_hour)}
                                        min={Number(state.edit?.config?.off_bed_from_minute)}
                                        className={
                                            cx(
                                                "w120",
                                                state.edit?.config?.off_bed_from_hour !== DefaultThreshold.off_bed_from_hour
                                                || state.edit?.config?.off_bed_from_minute !== DefaultThreshold.off_bed_from_minute
                                                    ? "highlight"
                                                    : ""
                                            )
                                        }/>
                                </div>
                                <div className={ja ? "w100 ml25" : "w40 ml10"}>{t(`To`)}:</div>
                                <div>
                                    <OACTimeSelection
                                        startH={0}
                                        endH={23}
                                        startM={0}
                                        endM={59}
                                        onChangeHour={(val) => handleChangeFromChild("off_bed_to_hour", val)}
                                        onChangeMinute={(val) => handleChangeFromChild("off_bed_to_minute", val)}
                                        hour={Number(state.edit?.config?.off_bed_to_hour)}
                                        min={Number(state.edit?.config?.off_bed_to_minute)}
                                        className={
                                            cx(
                                                "w120",
                                                state.edit?.config?.off_bed_to_hour !== DefaultThreshold.off_bed_to_hour
                                                || state.edit?.config?.off_bed_to_minute !== DefaultThreshold.off_bed_to_minute
                                                    ? "highlight"
                                                    : ""
                                            )
                                        }/>
                                </div>
                            </div>
                        </div>,
                        <div className="mt10 white-area h70">
                            <div className="one-line">
                                <b>{t(`Vital Signs not detected Alert:`)}</b>
                                <span className="flex-right">
                                    <div className="custom-control custom-switch">
                                      <input
                                          checked={state.edit?.config?.end_of_life_enabled === "true" || state.edit?.config?.end_of_life_enabled === true}
                                          onChange={handleChange}
                                          name="end_of_life_enabled"
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="customSwitch2"/>
                                      <label className="custom-control-label" htmlFor="customSwitch2"></label>
                                    </div>
                                </span>
                            </div>
                        </div>,
                        <div className="mt10 white-area h70">
                            <div className="one-line mt10">
                                <div className="w220">
                                    <b>{t(`Awake Sensitivity:`)}</b>
                                </div>
                                <div className={
                                    cx(
                                        "w120 mr60",
                                        state.edit?.config?.awake_sens !== DefaultThreshold.awake_sens
                                            ? "highlight-select"
                                            : ""
                                    )
                                }>
                                    <EspDropdown
                                        key={Math.random()}
                                        value={{
                                            value: state.edit?.config?.awake_sens,
                                            label: state.edit?.config?.awake_sens
                                        }}
                                        placeholder={`Select Level`}
                                        options={[
                                            {value: 1, label: "1"},
                                            {value: 2, label: "2"},
                                            {value: 3, label: "3"},
                                        ]}
                                        onChange={(val) => handleChangeFromChild("awake_sens", val.value)}
                                    />
                                </div>
                                <div className="descr">
                                    {t(`1 = Least sensitive`)}
                                    <br/>
                                    {t(`3 = Most sensitive`)}
                                </div>
                            </div>
                        </div>,
                        <div className={state.edit?.model && state.edit?.model === Models.SUPPORT_AUDIO ? "mt10 white-area h70" : "mt10 white-area h70 invisible"}>
                            <div className="one-line mt5">
                                <div className="w220">
                                    <b>{t(`BCU speaker volume:`)}</b>
                                </div>
                                <div className={
                                    cx(
                                        "w120 mr40",
                                        state.edit?.model &&
                                        state.edit?.config?.vol !== DefaultThreshold.vol
                                            ? "highlight-select"
                                            : ""
                                    )
                                }>
                                    <EspDropdown
                                        key={Math.random()}
                                        value={{
                                            value: (state.edit?.model && state.edit?.model === Models.SUPPORT_AUDIO ? state.edit?.config?.vol : ""),
                                            label: translation[state.edit?.model && state.edit?.model === Models.SUPPORT_AUDIO ? VolumeMap[state.edit?.config?.vol] : "n/a"]
                                        }}
                                        placeholder={`Select Level`}
                                        options={state.edit?.model ? [
                                            {value: 3, label: translation.High},
                                            {value: 2, label: translation.Normal},
                                            {value: 1, label: translation.Low},
                                        ] : []}
                                        onChange={(val) => handleChangeFromChild("vol", val.value)}
                                        noOptionsMessage={t("n/a")}
                                    />
                                </div>
                            </div>
                        </div>,
                    ]}
                    buttons={[
                        <button onClick={closeEditModal}>
                            {t(`Cancel`)}
                        </button>,
                        <button onClick={editConfirm}>{t(`Confirm`)}</button>,
                    ]}/>
            }
        </div>
    );
}