import {useEffect} from "react";

export function useOutsideDetect(ref, isOpen, callback) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) && isOpen) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, isOpen]);
}

export function useSidebarButtons(idx, callback, target) {
    useEffect(() => {
        function sidebarButtonClickHandle() {
            callback();
        }

        // if (window.navigator.platform.toLowerCase().indexOf("win") === -1
        //     && window.navigator.platform.toLowerCase().indexOf("macintel") === -1) {
        //     return;
        // }

        // Bind the event listener
        const el = document.getElementById(`sidebar-btn-${idx}`);
        if (el) el.addEventListener("click", sidebarButtonClickHandle);

        return () => {
            // Unbind the event listener on clean up
            // const el = document.getElementById(`sidebar-btn-${idx}`);
            if (el) el.removeEventListener("click", sidebarButtonClickHandle);
        };
    }, [idx, target]);
}

export function useUserButtons(userBtnRef, callback) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (userBtnRef.current && !userBtnRef.current.contains(event.target)) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [userBtnRef]);
}

export function useAlertButton(callback) {
    useEffect(() => {
        function handleClick(event) {
            callback();
        }

        // button
        const alertBtn = document.getElementById('alert-btn');

        if (!alertBtn) return;

        // Bind the event listener
        alertBtn.addEventListener("click", handleClick);
        return () => {
            // Unbind the event listener on clean up
            alertBtn.removeEventListener("click", handleClick);
        };
    }, [callback]);
}