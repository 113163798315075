import React, {useEffect, useState} from "react";
import {Sort} from "../../constants/sort";
import {
    faArrowDown,
    faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon as FA} from "@fortawesome/react-fontawesome";
import "./IrespSortForTable.css";

export function IrespSortForTable({name, sortState}) {

    const generateUpArrow = () => {
        if (sortState && sortState.name === name && sortState.value === Sort.Ascending) {
            return <span>
                <FA icon={faArrowUp}/>
                </span>
        }
    };

    const generateDownArrow = () => {
        if (sortState && sortState.name === name && sortState.value === Sort.Descending) {
            return <span>
                <FA icon={faArrowDown}/>
                </span>
        }
    };

    const generateAltArrow = () => {
        if (!sortState || sortState.name !== name) {
            return <span className="none-sort">
                <FA icon={faArrowDown}/>
                </span>
        }
    };

    return (
        <div className="iresp-sort">
            {/*{generateUpArrow()}*/}
            {/*{generateDownArrow()}*/}
            {/*{generateAltArrow()}*/}
        </div>
    )
}