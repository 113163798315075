import {connect} from 'react-redux'
import {Thresholds} from "./Thresholds.js";
import {thresholdAction} from "../../../redux/actions/thresholdAction";

const mapStateToProps = state => ({
    beds: state.thresholdReducer.beds || [],
});

function mapDispatchToProps(dispatch) {
    return ({
        getThresholdSetting: () => dispatch(thresholdAction.getThresholdSetting()),
        updateThresholdSetting: (update) => dispatch(thresholdAction.updateThresholdSetting(update)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Thresholds);