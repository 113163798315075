import React, {useEffect, useState} from "react";
import {useAuth} from "../../../connections/net";
import {messageWrapper as t, translateInput as ti} from "../../../utils/message-wrapper";
import {EspDropdown} from "../../common/EspDropdown";
import cx from "classnames";
import "./EnrollPatient.css";

import CalendarImg from "../../../images/CalendarImg.png";
import {InputDatePicker} from "../../common/InputDatePicker";
import moment from "moment";
import {IrespModal} from "../../common/IrespModal";
import {compareMatName, compareOACBedName} from "../../../utils/sort";
import {setZoom} from "../../../utils/set-zoom";

export function EnrollPatient(props) {

    useAuth();

    // set zoom
    setZoom();

    const initialState = {isOpenCalendar: false, dob: new Date(), patient_name: ""};

    const [state, setState] = useState({...initialState});

    useEffect(() => {
        props.getListOfAvailableBeds();

        return () => {
            props.setError(undefined);
        };
    }, []);

    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    };

    const PatientName = ti({
        type: "text",
        name: "patient_name",
        className: "enroll-input",
        value: state.patient_name,
        maxLength: 20
    }, `Enter Resident's name`, handleChange);
    // const PatientBirthday = ti({
    //     type: "text",
    //     name: "patient_birth",
    //     className: "enroll-input-sm",
    //     readOnly: true
    // }, `Enter Resident's birthday`, handleChange);

    const handleEnroll = async () => {
        const {dob, bed, patient_name} = state;
        const {id, last_update} = bed.value;
        const dobStr = moment(dob).format("YYYY-MM-DD");
        const res = await props.enrollPatient({
            "name": patient_name,
            "dob": dobStr,
            "bed_id": id,
            "bed_timestamp": last_update
        });
        if (res) {
            setState({...state, openEnrollModal: true});
            props.setError(undefined);
        }
    };

    return (
        <div className={localStorage.getItem("OMNI2_PRIORITY_LANGUAGE") !== "en" ? "enroll-patient ja" : "enroll-patient"}>
            <div>
                <span className="descr">{t(`Resident registration. Fields marked`)}</span>
                <span className="color-red">&nbsp;&nbsp;*&nbsp;</span>
                <span className="descr">{t(`are mandatory fields.`)}</span>
            </div>
            <div className="one-line mt-lg-5 mt-md-5 mt-sm-5">
                <div>
                    <span className="color-red">*&nbsp;</span>{t(`Resident's Name:`)}
                </div>
                <div>
                    {PatientName}
                    <br/>
                    <span className="descr ml-4">{t(`Max. 20 characters`)}</span>
                </div>
            </div>
            <div className="one-line">
                <div>
                    <span className="color-red">*&nbsp;</span>{t(`Resident's Birthdate:`)}
                </div>
                <div>
                    {/*{PatientBirthday}*/}
                    <InputDatePicker
                        showAll={true}
                        value={state.dob}
                        minDate={moment().add(-365, 'days')}
                        className="enroll-input-sm"
                        onChange={(v) => setState({
                            ...state, dob: v
                        })
                        }
                    />
                </div>
            </div>
            <div className="one-line">
                <div>
                    <span className="color-red">*&nbsp;</span>{t(`Allocate bed:`)}
                </div>
                <div>
                    <EspDropdown
                        key={Math.random()}
                        value={state.bed}
                        placeholder={`Select bed to allocate`}
                        options={props.beds?.sort((a,b) => compareOACBedName(a.bed_name , b.bed_name))
                            .map(b => ({value: b, label: b.bed_name}))}
                        // noOptionsMessage={}
                        onChange={(val) => setState({...state, bed: val})}
                    />
                </div>
                <div className={cx(
                    "ml-5 link",
                    state.patient_name && state.bed ? "" : "disabled"
                )}>
                    <a onClick={handleEnroll}>{t(`Allocate`)}</a>
                </div>
            </div>
            <div className="error">
                {props.error ? <div>{t(props.error)}</div> : undefined}
            </div>
            {
                state.openEnrollModal && <IrespModal
                    clazz="confirm-modal"
                    title={t(`Resident Enrollment`)}
                    contents={[
                        <div className="mt10">
                            {t(`A Resident named {0} has been enrolled to {1} successfully.`, {0: state.patient_name, 1: state.bed?.label})}
                        </div>,
                    ]}
                    buttons={[
                        <button onClick={() => setState({...initialState})}>{t(`OK`)}</button>,
                    ]}/>
            }
        </div>
    );
}