import {GET_CU_LIST, SET_ERROR, CLEAR_ERROR} from "../../actions/osp-mgr/cuBinding";

const initialState = {};

const cuBindingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CU_LIST:
            const {beds} = action.payload;
            return {...state, beds: beds.sort((a, b) => a.create_on > b.create_on ? 1 : -1)};
        case SET_ERROR:
            return {...state, error: action.payload};
        case CLEAR_ERROR:
            return {...state, error: undefined};
        default:
            return state
    }
};

export default cuBindingReducer;