import React, {useState, useEffect} from "react";
import moment from "moment";
// import "moment/locale/zh-cn";
import "moment/locale/en-sg";
import {useLocation, useHistory} from 'react-router-dom';
import {AppBar} from "./AppBar";
import {SideBar} from "./SideBar";
import "./Layout.css";
import {Loading} from "./Loading";
import {messageWrapper as t} from "../../utils/message-wrapper";
import {EspModal} from "../common/EspModal";
import LoadingImage from "../../images/gw_tablet_spinner.gif";
import AppIcon from "../../images/oac/favicon_io/favicon-32x32.png";
import "./modal.css";
import "./Report.css";
import {useIntl} from "react-intl";
import backIcon from "../../images/Report_Back.png";
import cx from "classnames";
import {LANG} from "../../constants/lang";
import {Versions} from "../../constants/versions";
import {IrespModal} from "../common/IrespModal";
import {Configs} from "../../constants/configs";
import {ROLES} from "../../constants/roles";

let intervaler, retry = 30, intervaler2, intervaler3, timer4;

export function Layout(props) {

    const [state, setState] = useState({});
    const location = useLocation();
    const history = useHistory();
    const intl = useIntl();

    const cnCss = props.locale !== "en" ? "" : "";

    const role = sessionStorage.getItem("OMNI2_ROLE");

    const token = sessionStorage.getItem(`${role}_token`);

    const BackText = intl.formatMessage({id: `Back`});

    function removeLoginRole() {
        localStorage.removeItem(`${role}_LOGGIN_ROLE`);
    }

    useEffect(() => {
        if (localStorage.getItem("OMNI2_PRIORITY_LANGUAGE") !== "en") {
            document
                .documentElement
                .style
                .setProperty("--main-font", "Noto");
        } else {
            document
                .documentElement
                .style
                .setProperty("--main-font", "Roboto Condensed");
        }

        document.fonts.ready.then(function () {
            if (window.location.href.indexOf("/cc-admin-patient-report") === -1) {
                props.closeLoading();
            }
        });

        // document.fonts.onloadingdone = function (fontFaceSetEvent) {
        //     props.closeLoading();
        // };

        // change title page
        document.title = intl.formatMessage({id: "OAC STATION"});

        // localStorage.setItem("OMNI2_TILES_OPENING", "false");
        if (window.location.href.indexOf("/login") === -1) {
            if (token) {
                // polling
                intervaler = setInterval(() => {
                    props.pollingByBedStatus();
                }, 60000);

                // get centre name
                if (window.location.href.indexOf("/cc-admin-patient-report") === -1) {
                    props.getBedAllocation();
                }

                // fetch live view data
                const pollingFunc = () => {
                    if(role === ROLES.NurseAdmin) {
                        props.pollingData();
                    }
                };

                pollingFunc();
                timer4 = setInterval(pollingFunc, 4000);
            }
        } else {
            // polling
            intervaler = setInterval(() => {
                props.checkServerUp();
            }, 60000);
        }

        intervaler2 = setInterval(() => {
            if (localStorage.getItem("OMNI2_DISCONNECTED") === "true") {
                if (retry-- === 30) {
                    // props.pollingByBedStatus();
                } else if (retry-- > 0) {
                    props.closeLoading();
                    props.reconnect(true);
                } else {
                    props.setOverRetry(true);
                    props.reconnect(false);
                }
            } else {
                retry = 30;
                props.reconnect(false, true);
                props.setOverRetry(false);
            }
        }, 4000);

        if (window.location.href.indexOf("/cc-admin-live-tile") === -1) {
            props.getVersions();
            intervaler3 = setInterval(() => {
                props.getVersions();
            }, 3600000);
        }

        function checkKeyPress(e) {
            let evtobj = window.event ? window.event : e;

            if (evtobj.keyCode === 37 && evtobj.ctrlKey && evtobj.altKey) {
                // arrow left - change language to english
                props.changeLanguage(LANG.en);
            }

            if (evtobj.keyCode === 39 && evtobj.ctrlKey && evtobj.altKey) {
                // arrow right - change language to installed language
                props.changeLanguage(LANG.ja);
            }
        }

        const el = document.getElementById('body');

        if (el) {
            el.addEventListener('keydown', checkKeyPress);
        }

        // if (role) {
        //     // set log in role
        //     localStorage.setItem(`${role}_LOGGIN_ROLE`, role);
        //
        //     // remove when unload
        //     window.addEventListener("unload", removeLoginRole, false);
        // }

        // check duplicate tab
        if (window.location.href.indexOf("/login") === -1) {
            try {
                const ch = new BroadcastChannel('already');
                ch.addEventListener('message', function (e) {
                    if (token && e.data === `ping_${role}`) {
                        ch.postMessage(`pong_${role}`);
                    }
                });
            } catch (e) {
                console.log(e.message);
            }
        }

        // check reload live tile
        // const openLiveView = () => {
        //     // localStorage.setItem("OMNI2_TILES_OPENING", "false");
        //     if (props.liveTile) {
        //         props.liveTile.close();
        //     }
        //     let liveTile = window.open("/cc-admin-live-tile", "_blank");
        //
        //     props.setLiveTile(liveTile);
        // };
        // if (window.location.href.indexOf("/login") === -1 && window.location.href.indexOf("/cc-admin-live-tile") === -1) {
        //     try {
        //         const ch = new BroadcastChannel('reopen');
        //         ch.addEventListener('message', function (e) {
        //             if (token && e.data === `reopen_pls`) {
        //                 openLiveView();
        //             }
        //         });
        //     } catch (e) {
        //         console.log(e.message);
        //     }
        // }

        return () => {
            clearTimeout(intervaler);
            clearTimeout(intervaler2);
            clearTimeout(timer4);
            el.removeEventListener('keydown', checkKeyPress);
        };
    }, []);

    if (props.locale !== "en") {
        moment.locale(props.locale);
    } else {
        moment.locale("en-sg");
    }

    const headers = {
        "": "Dashboard",
        "/": "Dashboard",
        "/cc-admin-dashboard": "Dashboard",
        "/cc-admin-show-qrcode": "Show Bed Group QR Code",
        "/cc-admin-manage-account": "Manage Bed Groups",
        "/cc-admin-operation-enroll": "Enroll Resident",
        "/cc-admin-operation-discharge-history": "Discharge History",
        "/cc-admin-operation-transfer": "Resident Operations",
        "/cc-admin-operation-thresholds": "Thresholds",
        "/cc-admin-patient-report": "OAC RESIDENT",
        "/cc-admin-qrcode-download-app": "SHOW QR CODE FOR OAC APP DOWNLOAD",

        "/osp-mgr-show-qrcode": "Show Installer Mode QR Code",
        "/osp-mgr-setup-wifi": "WiFi Setup",
        "/osp-mgr-setup-cu": "Bed Management",
        "/osp-mgr-administrative-na": "Nurse-Admin account",
        "/osp-mgr-reset-password": "Reset Password",
        "/osp-mgr-qrcode-download-app": "SHOW QR CODE FOR OAC APP DOWNLOAD",
    };

    const alreadyOpen = undefined;

    const logoutModal = props.shouldLogout && !token
        ? <EspModal
            title={t(`Error Encountered`)}
            definedButtons={[t(`OK`)]}
            clazz="logout-modal"
            content={t(`Your account has been logged in on another device, please login again.`)}
            handlers={[() => {
                props.handleLogout();
                // window.location.href = "/login";
            }]}
            isOpen={true}/>
        : null;

    const reconnectModal = <EspModal
        title={t(`Connection to cloud interrupted`)}
        definedButtons={[""]}
        clazz={cx(
            "logout-modal",
            props.isReconnect ? "" : "d-none"
        )}
        content={t(`Attempting to re-connect…`)}
        noFooter={true}
        loading={true}
        LoadingImage={LoadingImage}
        handlers={[() => {
            // props.handleLogout();
            // window.location.href = "/login";
            // props.history.push("/login");
        }]}
        isOpen={true}/>

    let overRetryModal = props.overRetry
        ? <EspModal
            title={t(`Connection to Cloud lost`)}
            definedButtons={[t(`OK`)]}
            clazz="retry-modal"
            content={t(`Contact Technical Support to resolve this before proceeding.`)}
            // noFooter={true}
            // loading={true}
            handlers={[() => {
                props.setHideOverRetry(true);
            }]}
            isOpen={true}/>
        : undefined;

    const outdateVersion = props.reloadToNewVersion
        ? <EspModal
            title={t(`New OAC Station Version Detected!`)}
            definedButtons={[t(`Cancel`), t(`OK`)]}
            clazz="reload-to-new-version-modal"
            content={t(`The current OAC Station version is outdated. Please reload the browser to continue with the latest version.`)}
            handlers={[() => {
                props.pendingReloadToNewVersion()
            }, () => {
                window.location.reload();
            },]}
            isOpen={true}/>
        : null;

    const handleAcceptPolicy = () => {
        if (props.policyAckVersion === -1) {
            props.setPolicyAckVersion({policy_acked_version: Configs.POLICY_VERSION}, false);
        } else {
            props.setPolicyAckVersion({policy_acked_version: Configs.POLICY_VERSION});
        }
    };

    const agreeChkBox = props.policyAckVersion !== -1
        ? <div className="agree-group">
            <input className="agree-chk" type="checkbox"
                   onClick={() => setState({...state, agreeChecked: !state.agreeChecked})}/>
            <span className="agree-label">{t(`I agree!`)}</span>
        </div>
        : undefined;

    const policyModal = props.policyAckVersion !== Configs.POLICY_VERSION
        ? <IrespModal
            clazz={props.locale === "en" ? "accept-policy" : "accept-policy accept-policy-jp"}
            // title={t(`Terms & Conditions`)}
            contents={[
                <iframe src={props.locale === "en" ? "/policy.html" : "policy_jp.html"} scrolling="no">

                </iframe>,
                agreeChkBox
            ]}
            buttons={[
                <button className={!state.agreeChecked && props.policyAckVersion !== -1 ? "disabled" : ""}
                        onClick={handleAcceptPolicy}>{t(`OK`)}</button>,
            ]}
        />
        : null;

    const changeFavicon = () => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
        }
        link.href = AppIcon;
    };

    if (
        window.location.pathname.indexOf("/login") > -1
        || window.location.pathname.indexOf("/preview") > -1
        || window.location.pathname.indexOf("/reset-password") > -1
    ) {
        // changeFavicon();
        return (
            <div className={"container-fluid" + cnCss}>
                <AppBar
                    key="app-bar"
                    auth={false}
                    languageOptions={props.languageOptions}
                    selectedLanguage={props.selectedLanguage}
                    changeLanguage={props.changeLanguage}
                />
                {props.children}
                <Loading loading={props.loading}/>
                {reconnectModal}
                {overRetryModal}
            </div>
        )
    }

    if (window.location.pathname.indexOf("/cc-admin-live-tile") > -1) {
        return (
            <div className={"container-fluid" + cnCss}>
                {props.children}
                {logoutModal}
                {reconnectModal}
                {overRetryModal}
                {role ? policyModal : null}
                <Loading loading={props.loading}/>
            </div>
        )
    }

    // changeFavicon();

    if (token && !headers[location.pathname]) {
        window.location.href = "/";
    }

    return (
        <div className={"container-fluid" + cnCss}>
            <AppBar
                key="app-bar"
                auth={true}
                audio={props.audio}
                liveViewIsOpen={props.liveViewIsOpen}
                onOffAudio={props.onOffAudio}
                languageOptions={props.languageOptions}
                selectedLanguage={props.selectedLanguage}
                changeLanguage={props.changeLanguage}
                handleLogout={props.handleLogout}
                history={history}
                location={location}
                isReconnect={props.isReconnect}
                deleteOldLiveTile={props.deleteOldLiveTile}
                setLiveTile={props.setLiveTile}
                liveTile={props.liveTile}
                hideOverRetry={props.hideOverRetry}
                overRetry={props.overRetry}
                setHideOverRetry={props.setHideOverRetry}
                mailUnread={props.mailUnread}
                getInboxMessage={props.getInboxMessage}
                handleOpenPP={props.openPP}
                clickedLiveView={props.clickedLiveView}
                // toggle={props.toggleSidebar}
            />
            <SideBar isOpen={true} clickedLiveView={props.clickedLiveView} isConfigurationSaved={props.isSaved}/>
            <div id="main">
                <div className="main-header">
                    {
                        window.location.pathname.indexOf("report") > -1
                            ? <div className="left-icons" title={BackText}>
                                <img src={backIcon} onClick={() => history.go(-1)}/>
                            </div>
                            : undefined
                    }
                    {t(`${headers[location.pathname]}`)}
                    {/*{middleIcon}*/}
                    {/*{rightIcon}*/}
                </div>
                <div className="main-body">
                    {props.children}
                </div>
            </div>
            <Loading loading={props.loading}/>
            {logoutModal}
            {role ? reconnectModal : null}
            {role ? overRetryModal : null}
            {role ? alreadyOpen : null}
            {role ? outdateVersion : null}
            {role ? policyModal : null}
        </div>
    );
}