export function isOldVersion(current, required) {
    const splittingCurrent = current.split(".");
    const splittingRequired = required.split(".");

    if (!splittingCurrent || !splittingRequired || splittingCurrent.length !== splittingRequired.length) {
        return false;
    }

    for (let i = 0; i < splittingCurrent.length; i++) {
        if(Number(splittingCurrent[i]) < Number(splittingRequired[i])) return false;
    }

    return true;
}