import React, {useState, useEffect, useRef} from "react";
import {messageWrapper as t} from "../../utils/message-wrapper";
import "./LiveTileBtn.css";

export function LiveViewBtn(props) {
    const [state, setState] = useState(true);

    const ref = useRef(true);

    let interval;
    useEffect(() => {
        interval = setInterval(() => {
            ref.current = !ref.current;
            setState(ref.current);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const ja = localStorage.getItem("OMNI2_PRIORITY_LANGUAGE") === "en" ? "" : " ja";

    return <div className={state && !props.isBlack ? "lv-btn lv-btn-red" + ja : "lv-btn lv-btn-black" + ja}>
        {t(`LiveView`)}
    </div>
}