import * as d3 from "d3";
import moment from "moment";
import {CHART_UNITS, COLOR_SLEEP_PHARSE, HEIGHT_PHARSE, dayInWeek, Zoomed} from "./constant";
import ReloadImg from "../../libs/images/OAC_Refresh-2A-444444_png.png";

export function updateChart(sl, translations) {
    if (!sl) return;
    const realDataChart = sl.map(data => {
        return {events: data.events, date: data.day};
    });
    const realDataTotal = sl.map(data => {
        return {...data.total, date: data.day, day: dayInWeek(translations.lang, moment(data.day, "YYYY-M-D").day())};
    });

    renderSleepTrend(realDataChart, realDataTotal, translations);
}

export function clearChart() {
    const content = d3.select(".sleep-pharse-trend");
    const content2 = d3.select(".sleep-trend");
    content.html("");
    content2.html("");
}

function renderSleepTrend(realDataChart, realDataTotal, translations) {
    // console.log(realDataChart, realDataTotal)
    renderSleepChart(realDataChart, translations);
    renderSleepPharse(realDataTotal, translations);
}

function renderSleepPharse(data, translations) {
    const content = d3.select(".sleep-pharse-trend");

    // table
    const tbody = content
        .append("table")
        .attr("class", "pharse-table")
        .append("tbody");

    // header
    const thTitle = tbody
        .append("tr");
    thTitle
        .append("th")
        .attr("colspan", 8)
        .attr("class", "title")
        .text(translations["SLEEP STATISTICS"]);

    // table header
    const ths = tbody
        .append("tr");
    ths.append("th").text(translations["Date"]);
    ths.append("th").text(translations["Day"]);
    ths.append("th").text(translations["Total Sleep Time"]);
    ths.append("th").text(translations["Awake (+ Sleep Latency)"]);
    ths.append("th").text(translations["Light Sleep"]);
    ths.append("th").text(translations["Deep Sleep"]);
    ths.append("th").text(translations["Off-Pillow"]);
    const lastTh = ths.append("th");
    lastTh.append("span").text(translations["Off-Pillow Events"]);
    lastTh.append("span").attr("class", "text-italic").text(translations[" (6pm-6am)"]);

    data.map(e => {
        const tr = tbody
            .append("tr");

        for (let j = 0; j <= 7; j++) {
            switch (j) {
                case 0:
                    tr
                        .append('td')
                        .text(moment(e.date, "YYYY-M-D").format("YYYY-MM-DD"));
                    break;
                case 1:
                    tr
                        .append('td')
                        .text(e.day);
                    break;
                case 2:
                    const tdSt = tr
                        .append('td')
                        .attr("class", "td-pharse")
                        .append("div")
                        .attr("class", "td-flex-container");

                    // tdSt
                    //     .append("div")
                    //     .attr("class", "progress-status")
                    //     .append("svg")
                    //     .attr("height", "30")
                    //     .attr("width", "180")
                    //     .append("rect")
                    //     .attr('class', 'progress-rect')
                    //     .attr('fill', "rgb(244, 235, 73)")
                    //     .attr('height', 12)
                    //     .attr('width', 180 / (24 * 3600) * e.sleep_time)
                    //     .attr('rx', 7)
                    //     .attr('ry', 7)
                    //     .attr('x', 0)
                    //     .attr("y", 15);

                    tdSt.append("div").attr("class", "").text(formatDuration(e.sleep_time));

                    break;
                case 3:
                    const tdAk = tr
                        .append('td')
                        .attr("class", "td-ak")
                        .append("div")
                        .attr("class", "td-flex-container");

                    tdAk
                        .append("div")
                        .attr("class", "progress-status")
                        .append("svg")
                        .attr("height", "30")
                        .attr("width", "160")
                        .append("rect")
                        .attr('class', 'progress-rect')
                        .attr('fill', "rgb(252, 175, 74)")
                        .attr('height', 12)
                        .attr('width', 160 / (24 * 3600) * (e.awake_on_bed + e.tts))
                        .attr('rx', 7)
                        .attr('ry', 7)
                        .attr('x', 0)
                        .attr("y", 15);

                    tdAk.append("div").attr("class", "value").text(formatDuration(e.awake_on_bed + e.tts));
                    break;
                case 4:
                    const tdLs = tr
                        .append('td')
                        .attr("class", "td-ls")
                        .append("div")
                        .attr("class", "td-flex-container");

                    tdLs
                        .append("div")
                        .attr("class", "progress-status")
                        .append("svg")
                        .attr("height", "30")
                        .attr("width", "160")
                        .append("rect")
                        .attr('class', 'progress-rect')
                        .attr('fill', "rgb(106, 225, 255)")
                        .attr('height', 12)
                        .attr('width', 160 / (24 * 3600) * e.ls)
                        .attr('rx', 7)
                        .attr('ry', 7)
                        .attr('x', 0)
                        .attr("y", 15);
                    tdLs.append("div").attr("class", "value").text(formatDuration(e.ls));
                    break;
                case 5:
                    const tdDl = tr
                        .append('td')
                        .attr("class", "td-dl")
                        .append("div")
                        .attr("class", "td-flex-container");

                    tdDl
                        .append("div")
                        .attr("class", "progress-status")
                        .append("svg")
                        .attr("height", "30")
                        .attr("width", "160")
                        .append("rect")
                        .attr('class', 'progress-rect')
                        .attr('fill', "rgb(11, 186, 231)")
                        .attr('height', 12)
                        .attr('width', 160 / (24 * 3600) * e.ds)
                        .attr('rx', 7)
                        .attr('ry', 7)
                        .attr('x', 0)
                        .attr("y", 15);

                    tdDl.append("div").attr("class", "value").text(formatDuration(e.ds));
                    break;
                case 6:
                    const tdOp = tr
                        .append('td')
                        .attr("class", "td-dl")
                        .append("div")
                        .attr("class", "td-flex-container");

                    tdOp
                        .append("div")
                        .attr("class", "progress-status")
                        .append("svg")
                        .attr("height", "30")
                        .attr("width", "160")
                        .append("rect")
                        .attr('class', 'progress-rect')
                        .attr('fill', "#F5EA08")
                        .attr('height', 12)
                        .attr('width', 160 / (24 * 3600) * e.off_pillow)
                        .attr('rx', 7)
                        .attr('ry', 7)
                        .attr('x', 0)
                        .attr("y", 15);

                    tdOp.append("div").attr("class", "value").text(formatDuration(e.off_pillow));
                    break;
                case 7:
                    const tdTts = tr
                        .append('td')
                        .attr("class", "td-tts")
                        .append("div")
                        .attr("class", "td-flex-container");

                    tdTts.append("div").attr("class", "").text(e.off_pillow_count);
                    break;
            }
        }
    });
}

function renderSleepChart(data, translations) {
    const content = d3.select(".sleep-trend");

    // header
    const header = content
        .append("div")
        .attr("class", "header-wrapper");


    header.append("div")
        .attr("class", "title")
        .text(translations["SLEEP TREND"]);

    header.append("div")
        .attr("class", "reload")
        .append("img")
        .attr("id", "reload-btn")
        .attr("x", 0)
        .attr("y", 0)
        .attr("src", ReloadImg);

    const divKey = header
        .append("div")
        .attr("class", "key-wrapper");

    divKey.append("span").attr("class", "key-text").text(translations["Key:"]);
    divKey.append("span").attr("class", "dl-color");
    divKey.append("span").attr("class", "dl-text").text(translations["Deep sleep"]);
    divKey.append("span").attr("class", "sl-color");
    divKey.append("span").attr("class", "sl-text").text(translations["Light sleep"]);
    divKey.append("span").attr("class", "awk-color");
    divKey.append("span").attr("class", "awk-text").text(translations["Awake"]);
    divKey.append("span").attr("class", "op-color");
    divKey.append("span").attr("class", "op-text").text(translations["Off-Pillow"]);

    // table
    const tbody = content
        .append("table")
        .attr("class", "trend-table")
        .append("tbody");

    function initZoom() {
        let zoom = d3.zoom()
            .on('zoom', handleZoom);

        d3.selectAll(`.zooming`)
            .call(zoom);
    }

    function handleZoom(e) {
        const previousZoomed = Zoomed.zoomed;
        const previousTranslatedX = Zoomed.translatedX;
        const previousOldK = Zoomed.previousK;

        let delta = 1800;
        const x = e.sourceEvent?.x;

        if (x <= 600) {
            delta = 0;
        } else if (x <= 900) {
            delta = 900;
        } else if (x <= 1100) {
            delta = 1800;
        } else if (x <= 1200) {
            delta = 1900;
        } else if (x <= 1300) {
            delta = 2000;
        } else if (x <= 1500) {
            delta = 2100;
        } else if (x <= 1700) {
            delta = 2700;
        } else if (x >= 1700) {
            delta = 3600;
        }

        // k zoomed
        if (e.transform.k > Zoomed.previousK && Zoomed.zoomed > -20) {
            Zoomed.translatedX = Zoomed.zoomed === -20 ? Zoomed.translatedX : Zoomed.translatedX - (4 * delta);
            Zoomed.zoomed = Zoomed.zoomed - 4 > -20 ? Zoomed.zoomed - 4 : -20;
            Zoomed.previousK = e.transform.k;
        } else if (e.transform.k < Zoomed.previousK && Zoomed.zoomed < 0) {
            Zoomed.zoomed = Zoomed.zoomed + 4 > 0 ? 0 : Zoomed.zoomed + 4;
            Zoomed.previousK = e.transform.k;
            Zoomed.translatedX = Zoomed.translatedX + (4 * delta);
        } else {
            Zoomed.previousK = e.transform.k;
        }

        // x axis
        let xDistance = 420;
        if (Zoomed.zoomed === -16) {
            xDistance = 120;
        } else if (Zoomed.zoomed === -20) {
            xDistance = 60;
        }

        if (e.transform.x < Zoomed.previousX && Zoomed.translatedX > Zoomed.zoomed * 3600 && Zoomed.zoomed < 0 && previousOldK === e.transform.k) {
            Zoomed.translatedX = Zoomed.translatedX - (Math.floor(23 / (Zoomed.zoomed >= -20 ? 12 : -Zoomed.zoomed) * xDistance));
            Zoomed.previousX = e.transform.x;
        } else if (e.transform.x > Zoomed.previousX && Zoomed.translatedX < 0 && Zoomed.zoomed < 0 && previousOldK === e.transform.k) {
            Zoomed.translatedX = Zoomed.translatedX + (Math.floor(23 / (Zoomed.zoomed >= -20 ? 12 : -Zoomed.zoomed) * xDistance));
            Zoomed.previousX = e.transform.x;
        } else if (Zoomed.zoomed >= 0) {
            Zoomed.translatedX = 0;
        } else {
            Zoomed.previousX = e.transform.x;
        }

        // if > 0
        if (Zoomed.translatedX > 0) {
            Zoomed.translatedX = 0;
        } else if (Zoomed.translatedX <= Zoomed.zoomed * 3600) {
            Zoomed.translatedX = Zoomed.zoomed * 3600;
        }

        // update graphics
        if (previousZoomed !== Zoomed.zoomed || previousTranslatedX !== Zoomed.translatedX) {
            for(let i = 0; i < data.length; i++) {
                drawSleepTrend(`sleep-trend-canvas-${i}`, data[i].events, Zoomed.zoomed, Zoomed.translatedX);
            }
        }
    }

    data.map((d, i) => {
        const tr = tbody
            .append("tr");

        const td1 = tr
            .append("td")
            .append("div")
            .style("position", "relative");

        const txtWrapper = td1
            .append("div")
            .attr("class", "txt-wrapper");

        txtWrapper
            .append("div")
            .text(`${moment(d.date, "YYYY-M-D").format("YYYY-MM-DD")}`);
        txtWrapper
            .append("div")
            .attr("class", "txt-time")
            .text(translations["Time"]);

        const td2 = tr
            .append("td")
            .append("div")
            .style("position", "relative")
            .style("width", "100%")
            .style("height", "100%")
            .style("padding", "0")
            .style("margin", "0");

        td2
            .append("svg")
            .style("cursor", "pointer")
            .style("width", "100%")
            .style("height", "100%")
            .style("position", "absolute")
            .style("top", 0)
            .attr("id", `svg-sleep-trend-canvas-${i}`)
            .attr("class", `zooming`);

        td2
            .append("canvas")
            .attr("width", 1780)
            .attr("height", 57)
            .attr("id", `sleep-trend-canvas-${i}`);

        drawSleepTrend(`sleep-trend-canvas-${i}`, d.events, Zoomed.zoomed, Zoomed.translatedX);
    });

    initZoom();

}

function drawSleepTrend(id, data = [], zoomed = 0, translatedX = 0) {
    // console.log("drawSleepTrend")
    const drawArea = function (totalHours = 24, translatedX = 0) {
        // ctx
        let canvas = document.getElementById(id);
        let width = document.getElementById(id).width;
        let height = document.getElementById(id).height;
        let xMargin = 0;

        let ctx = canvas.getContext("2d");

        // clear
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // draw area
        const unitSize = width / (totalHours * 86400 / 24);
        data.map(e => {
            const x = Math.round((e.startTime + translatedX) * unitSize);
            let duration = Math.ceil(e.duration * unitSize);

            if (duration < 1) {
                duration = 1;
            }

            // fill events
            ctx.fillStyle = COLOR_SLEEP_PHARSE[e.type];
            ctx.fillRect(posX(x, xMargin), height, duration, -HEIGHT_PHARSE[e.type]);
        });
    };

    // draw y lines
    const svg = d3.select(`#svg-${id}`);
    const drawYLines = function (zoomed = 0, translatedX = 0) {
        for (let i = 0; i <= 24; i++) {
            if (i > 0 && i < 24) {
                svg
                    .append("line")
                    .attr('stroke', '#9c9c9c')
                    .style("stroke-dasharray", ("3, 2"))
                    .style("stroke-width", 1)
                    .style('shape-rendering', 'crispEdges')
                    .attr('x1', function (d) {
                        return 1780 / 24 * i
                    })
                    .attr('y1', function (d) {
                        return 0
                    })
                    .attr('x2', function (d) {
                        return 1780 / 24 * i
                    })
                    .attr('y2', function (d) {
                        return 110
                    });
            }

            // text
            const hr0 = 0;
            const hr = 15;
            const hr24 = 50;
            if (i === 0 || i % 3 === 0) {
                let x = hr;
                if (i === 0) {
                    x = hr0;
                } else if (i === 24) {
                    x = hr24;
                }
                svg
                    .append("text")
                    .text(formatZoomedTime(i, zoomed, translatedX))
                    .attr("x", 1780 / 24 * i - x)
                    .attr("y", 74)
            }
        }
    };

    const drawXLine = function () {
        // draw x line
        svg
            .append("line")
            .attr('stroke', '#9c9c9c')
            .style("stroke-width", 1)
            .style('shape-rendering', 'crispEdges')
            .attr('x1', 0)
            .attr('y1', function (d) {
                return 57
            })
            .attr('x2', 1780)
            .attr('y2', function (d) {
                return 57
            });
    };

    const removeLines = function () {
        svg.selectAll("line").remove();
        svg.selectAll("text").remove();
    };

    removeLines();
    drawXLine();
    drawYLines(zoomed, translatedX);
    drawArea(24 + zoomed, translatedX);
}

function posX(x, xMargin) {
    return xMargin + x;
}

function formatTime(num) {
    if (num === 1) return `0:00`;
    return num === 24 ? `${num - 1}:59` : `${num}:00`;
}

function formatDuration(duration) {
    if(!duration) return "0h 0m";

    const hour = Math.floor(duration / 3600);
    const min = Math.ceil(duration / 60) - hour * 60;

    return `${hour}h ${min}m`;
}

function formatZoomedTime(pos, zoomed, translatedX) {
    let totalUnit = 24 + zoomed;

    if (totalUnit <= 0) {
        totalUnit = 1;
    }

    let start = 0;

    if (pos !== 1) {
        start = pos;
    }

    // // translated
    // const translatedHour = Math.floor(translatedX / 60);
    //
    // const translatedMin = translatedX - translatedHour * 60;

    // calculate
    const unitInMinute = totalUnit * 60 / 24;

    const startInMinute = Math.floor(unitInMinute * start - Math.floor(translatedX / 60));

    let hour = Math.floor(startInMinute / 60);

    let minute = startInMinute - hour * 60;

    if (hour < 0) {
        hour = 0;
        minute = 0;
    } else if (hour >= 24) {
        hour = 23;
        minute = 59;
    }

    let hourPlus12 = (hour + 12) % 24;

    return `${hourPlus12 <= 9 ? "0" + hourPlus12 : hourPlus12}:${minute <= 9 ? "0" + minute : minute}`;
}