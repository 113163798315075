import React, {useState, useRef, useEffect} from "react";

import PasswordShow from "../../../images/oac/password-show.png";
import PasswordHide from "../../../images/oac/password-hide.png";

import "./OACPasswordField.css";
import {FormattedMessage} from "react-intl";

export function OACPasswordField(props) {

    const [state, setState] = useState({isShow: false});

    return <div className="oac-password">
        {
            state.isShow
            ? <FormattedMessage id={props.placeholder || "Enter password"}>
                {
                    placeholder => <input maxLength={props.maxLength} placeholder={placeholder} type="text" value={props.value} onChange={(e) => props.onChange(e.target.value)}/>
                }
            </FormattedMessage>
            : <FormattedMessage id={props.placeholder || "Enter password"}>
                {
                    placeholder => <input maxLength={props.maxLength} placeholder={placeholder} type="password" value={props.value} onChange={(e) => props.onChange(e.target.value)}/>
                }
            </FormattedMessage>
        }
        {
            state.isShow
                ? <img src={PasswordShow} onClick={()=>setState({isShow: false})}/>
                : <img src={PasswordHide} onClick={()=>setState({isShow: true})} />
        }
    </div>
}