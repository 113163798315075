export const Host = {
  // NssSocket: "ws://47.74.251.238:8083/",
  // NssRestApi: "https://api.oac-system.com:8443/",
  // NssSocket: "ws://47.74.245.192:8083/",
  // NssRestApi: "http://47.74.245.192:8081",
  // LocalProxy: "http://localhost:8084",
  // NssSocket: false,
  // NssRestApi: false,
  // LocalProxy: false,
  NssRestApiPort: 8081,
  LocalProxyPort: 8084,
  NssSocketPort: 8083,
};
