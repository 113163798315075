import {connect} from 'react-redux'
import {ChangePassword} from "./ChangePassword";
import {loginActions} from "../../../redux/actions/loginAction";

const mapStateToProps = state => ({
    error: state.loginReducer.error,
});

function mapDispatchToProps(dispatch) {
    return ({
        resetPassword: (info) => dispatch(loginActions.requestResetPassword(info)),
        setError: (error) => dispatch(loginActions.setError(error)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);