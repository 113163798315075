import React, { useEffect, useState } from "react";
import { useAuth } from "../../../connections/net";
import {
  messageWrapper as t,
  translateValueOption,
} from "../../../utils/message-wrapper";
import { EspTable } from "../../common/EspTable";
import moment from "moment";
import { IrespDropdown } from "../../common/IrespDropdown";
import _ from "lodash";
import "./BedAssignment.css";
import { IrespSortForTable } from "../../common/IrespSortForTable";
import { Sort } from "../../../constants/sort";
import { ROLES } from "../../../constants/roles";
import { compareOACBedName } from "../../../utils/sort";
import { useIntl } from "react-intl";
import ReportImg from "../../../images/oac/report-icon.png";
import { setZoom } from "../../../utils/set-zoom";
import CsvImg from "../../../images/oac/OAC_FIle-CSV-2B-1464f6_png.png";
import CloseImg from "../../../images/oac/OAC_Plain_SVG_5.png";

const TextFields = [""];

const RSSI_MAP = {
  "-1": "Offline",
  0: "Offline",
  1: "Poor",
  2: "Acceptable",
  3: "Good",
  4: "Best",
};

export function BedAssignment(props) {
  useAuth();

  // set zoom
  setZoom();

  const [state, setState] = useState({ tab: 1, patients: [] });

  const intl = useIntl();

  const translations = {
    BR_E1: intl.formatMessage({ id: "BR_E1" }),
    HR_E1: intl.formatMessage({ id: "HR_E1" }),
    BR_E8: intl.formatMessage({ id: "BR_E8" }),
    HR_E8: intl.formatMessage({ id: "HR_E8" }),
    POSITION_E1: intl.formatMessage({ id: "POSITION_E1" }),
    POSITION_E8: intl.formatMessage({ id: "POSITION_E8" }),
    STATUS_E1: intl.formatMessage({ id: "STATUS_E1" }),
    STATUS_E8: intl.formatMessage({ id: "STATUS_E8" }),
  };

  useEffect(() => {
    switch (state.tab) {
      case 1:
        props.getBedAllocation();
        break;
      case 2:
        props.getBedAssignment();
        break;
    }

    // Open live tile page
    // let liveTile;
    // if (localStorage.getItem("OMNI2_ROLE") === ROLES.NurseAdmin) {
    //     if (!props.liveTile && window.location.href.indexOf("/cc-admin-dashboard") > -1 && !localStorage.getItem("OMNI2_TILES_OPENING")) {
    //         liveTile = window.open("/cc-admin-live-tile", "_blank");
    //         props.setLiveTile(liveTile);
    //         localStorage.setItem("OMNI2_TILES", "true");
    //     }
    // }
  }, [state.tab]);

  useEffect(() => {
    if (props.beds) {
      setState({
        ...state,
        filteredPatient: props.beds.filter(
          (p) => (p.patient_id && p.patient_id !== "") || p.patient_name
        ),
      });
    }
  }, [props.beds]);

  useEffect(() => {
    if (props.beds && props.beds.length > 0) {
      const filteredPatient = props.beds.filter((b) => {
        if (!state.filterValue || state.filterValue === "ALL") return true;

        if (state.filterField && state.filterField.indexOf("time") > -1) {
          return (
            moment(b[state.filterField]).format("YYYYMMDD") ===
            moment(state.filterValue).format("YYYYMMDD")
          );
        }

        if (state.filterField && state.filterField.indexOf("status") > -1) {
          return b[state.filterField]?.indexOf(state.filterValue) > -1;
        }

        if (
          state.filterField &&
          state.filterField.indexOf("nurse_names") > -1
        ) {
          return b.nurse_names?.indexOf(state.filterValue) > -1;
        }

        if (state.filterField && TextFields.indexOf(state.filterField) > -1) {
          return (
            b[state.filterField] &&
            b[state.filterField]
              .toUpperCase()
              .indexOf(state.filterValue.toUpperCase()) > -1
          );
        }

        return b[state.filterField] === state.filterValue;
      });
      setState({ ...state, filteredPatient: filteredPatient });
    }
  }, [state.filterValue]);

  const generateBedAssignments = () => {
    const generateHeader = () => {
      return (
        <tr>
          <td>{t(`Bed Name`)}</td>
          <td>{t(`Bed Group Assignments`)}</td>
          <td>{t(`Functional Status`)}</td>
          <td>{t(`Signal Strength`)}</td>
          <td>{t(`Access Point`)}</td>
        </tr>
      );
    };
    const generateBody = () => {
      if (!state.filteredPatient || state.filteredPatient.length < 1) {
        return (
          <tr>
            <td className="max">{t(`No record found`)}</td>
          </tr>
        );
      } else {
        return state.filteredPatient
          .sort((a, b) => compareOACBedName(a.bed_name, b.bed_name))
          .map((b) => {
            const rssi = getRssi(b.rssi, b.sensor);
            return (
              <tr>
                {/*<td>{b.patient_name}</td>*/}
                <td
                  className={
                    b.functional_status?.length > 0 ||
                    b.rssi <= 0 ||
                    rssi === "Offline"
                      ? "status-error"
                      : ""
                  }
                >
                  {b.bed_name}
                </td>
                <td
                  className={
                    b.functional_status?.length > 0 ||
                    b.rssi <= 0 ||
                    rssi === "Offline"
                      ? "status-error"
                      : ""
                  }
                >
                  {b.nurse_names?.length > 0 ? b.nurse_names?.join(", ") : "--"}
                </td>
                <td
                  className={
                    b.functional_status?.length > 0 ||
                    b.rssi <= 0 ||
                    rssi === "Offline"
                      ? "status-error"
                      : ""
                  }
                >
                  {b.functional_status?.length === 0 && rssi !== "Offline"
                    ? t("Operational")
                    : undefined}
                  {rssi !== "Offline" &&
                    b.functional_status?.map((f, idx) => {
                      return (
                        <>
                          {t(f)}
                          {idx < b.functional_status?.length - 1 ? ", " : ""}
                        </>
                      );
                    })}
                  {rssi === "Offline" ? t("Offline") : undefined}
                </td>
                <td className={rssi === "Offline" ? "status-error" : ""}>
                  {t(rssi)}
                </td>
                <td>{b.ssid}</td>
              </tr>
            );
          });
      }
    };

    const timezone = Number(localStorage.getItem(`ADMIN_OAH_TIMEZONE`) || 540);
    const now = moment().utcOffset(Number(timezone)).valueOf();
    const getRssi = (rssi, status = {}) => {
      const lastUpdate = status?.last_update;
      if (!lastUpdate || now - lastUpdate > 60000) {
        return "Offline";
      }

      if (!rssi) return "n/a";

      return RSSI_MAP[rssi];
    };

    return (
      <>
        <div className="table-descr">
          <div className="inline">
            {t(`Filter Field:`)}
            <IrespDropdown
              options={[
                `Select Field`,
                // translateValueOption("Resident's Name", "patient_name"),
                translateValueOption("Bed", "bed_name"),
                translateValueOption("Bed Group Assignments", "nurse_names"),
                translateValueOption("Functional Status", "functional_status"),
              ]}
              handleSelect={(val) =>
                setState({
                  ...state,
                  filterField: val,
                  filterValue: undefined,
                })
              }
            />
          </div>
          <div className="inline ml90">
            {t(`Filter Value:`)}
            {generateValues()}
          </div>
        </div>
        <EspTable
          className="bed-assignments"
          renderHeader={generateHeader}
          renderBody={generateBody}
        />
      </>
    );
  };

  const generateBedAllocation = () => {
    const generateHeader = () => {
      return (
        <tr>
          <td onClick={() => sortProcess("patient_name")}>
            {t(`Resident's Name`)}
            <IrespSortForTable name="patient_name" sortState={state.sort} />
          </td>
          <td onClick={() => sortProcess("bed")}>
            {t(`Bed`)}
            <IrespSortForTable name="bed" sortState={state.sort} />
          </td>
          <td>{t(`Action`)}</td>
        </tr>
      );
    };
    const generateBody = () => {
      if (!state.filteredPatient || state.filteredPatient.length < 1) {
        return (
          <tr>
            <td className="max">{t(`No record found`)}</td>
          </tr>
        );
      } else {
        const Role = sessionStorage.getItem("OMNI2_ROLE");
        return state.filteredPatient
          .sort((a, b) => compareOACBedName(a.bed_name, b.bed_name))
          .map((b) => {
            return (
              <tr
                className={Role === ROLES.NurseAdmin ? "cursor-pointer" : ""}
                onDoubleClick={() => {
                  if (Role !== ROLES.NurseAdmin) return;
                  // props.setPatient(props.patients.find(p => p.id === b.patient_id));
                  props.history.push({
                    pathname: `/cc-admin-patient-report`,
                    search: `?patient_id=${b.patient_id}&enroll=${b.enroll}&premium=${b.premium}`,
                  });
                }}
                onTouchEnd={(ev) => {
                  if (Role !== ROLES.NurseAdmin) return;
                  handleDoubleTouch(ev, b);
                }}
              >
                <td>
                  {b.patient_name}
                  {Role === ROLES.NurseAdmin && b.premium ? (
                    <img
                      className="csv-icon"
                      src={CsvImg}
                      onClick={() => listCsv(b)}
                    />
                  ) : undefined}
                  {Role === ROLES.NurseAdmin ? (
                    <img
                      className="report-icon"
                      src={ReportImg}
                      onClick={() => {
                        props.history.push({
                          pathname: `/cc-admin-patient-report`,
                          search: `?patient_id=${b.patient_id}&enroll=${b.enroll}&premium=${b.premium}`,
                        });
                      }}
                    />
                  ) : undefined}
                </td>
                <td>{b.bed_name}</td>
                <td>{generateAction()}</td>
              </tr>
            );
          });
      }
    };

    const generateArchiveHeader = () => {
      return (
        <tr>
          <td>{t(`Date`)}</td>
          <td>{t(`Resident's Name`)}</td>
          <td>{t(`Download Status`)}</td>
        </tr>
      );
    };

    const generateArchiveBody = () => {
      if (Object.keys(props.listOfCsv).length === 0) {
        return (
          <tr>
            <td className="max">{t(`No record found`)}</td>
          </tr>
        );
      }

      return Object.keys(props.listOfCsv).map((key) => {
        const csv = props.listOfCsv[key];
        return (
          <tr>
            <td>
              {key.length === 7
                ? moment(key, "YYYY_MM").format("YYYY-MM")
                : key}
              &nbsp;
              <img
                className="csv-icon-2"
                src={CsvImg}
                onClick={() => downloadCsv(key)}
              />
            </td>
            <td>{state.patientDownload.patient_name}</td>
            <td>
              {csv.downloaded
                ? moment(csv.downloaded).format("YYYY-MM-DD / HH:mm")
                : "--"}
            </td>
          </tr>
        );
      });
    };

    return (
      <>
        <div className="table-descr">
          <div className="inline">
            {t(`Filter Field:`)}
            <IrespDropdown
              options={[
                `Select Field`,
                translateValueOption("Resident's Name", "patient_name"),
                translateValueOption("Bed", "bed_name"),
              ]}
              handleSelect={(val) =>
                setState({
                  ...state,
                  filterField: val,
                  filterValue: undefined,
                })
              }
            />
          </div>
          <div className="inline ml90">
            {t(`Filter Value:`)}
            {generateValues()}
          </div>
        </div>
        <div className="inline">
          <EspTable
            className="bed-allocation"
            renderHeader={generateHeader}
            renderBody={generateBody}
          />
        </div>
        {state.showCsvDownload ? (
          <div className="inline csv-archive">
            <div>
              <span>{t(`CSV Archive for:`)}</span>
              &nbsp;
              <b>{state.patientDownload?.patient_name}</b>
              <span className="close-img-wrapper" onClick={handleCloseCsvTable}>
                <img src={CloseImg} />
              </span>
            </div>
            <EspTable
              className="csv-archive-table"
              renderHeader={generateArchiveHeader}
              renderBody={generateArchiveBody}
            />
          </div>
        ) : undefined}
      </>
    );
  };

  const downloadCsv = (key) => {
    props.downloadCsv({
      patient_id: state.patientDownload?.patient_id,
      report_key: key,
      patient_name: state.patientDownload?.patient_name,
    });
  };

  const listCsv = (b) => {
    props.listCsv({
      patient_id: b.patient_id,
    });

    setState({
      ...state,
      showCsvDownload: true,
      patientDownload: b,
    });
  };

  const handleCloseCsvTable = () => {
    setState({
      ...state,
      showCsvDownload: false,
    });
  };

  const generateAction = () => {
    return (
      <a className="link" onClick={undefined}>
        {t(`Transfer`)}
      </a>
    );
  };

  const dropdownValues = () => {
    if (!state.filterField) return [];
    // if(state.filterField.indexOf("patient") > -1) {
    //     return _.uniq(props.patients?.map(b => b.name));
    // }
    if (state.filterField.indexOf("nurse_names") > -1) {
      const values = {};
      props.beds.forEach((b) => {
        const nurses = b[state.filterField] || [];
        nurses.forEach((s) => (values[s] = true));
      });
      return Object.keys(values).sort((a, b) => (a > b ? 1 : -1));
    }

    if (state.filterField.indexOf("status") > -1) {
      const values = {};
      props.beds.forEach((b) => {
        const statuses = b[state.filterField] || [];
        statuses.forEach((s) => (values[s] = true));
      });
      return Object.keys(values).sort((a, b) => (a > b ? 1 : -1));
    }

    return _.uniq(
      props.beds.map((b) => b[state.filterField]),
      state.filterField
    ).sort((a, b) => (a.toUpperCase() > b.toUpperCase() ? 1 : -1));
  };

  const generateValues = () => {
    if (TextFields.indexOf(state.filterField) > -1) {
      return (
        <input
          className="filter"
          value={state.filterValue || ""}
          onChange={(e) => setState({ ...state, filterValue: e.target.value })}
        />
      );
    }

    return (
      <IrespDropdown
        value={state.filterValue}
        options={[
          `Select Value`,
          translateValueOption("All", "ALL"),
          ...dropdownValues().map((val) => {
            if (state.filterField.indexOf("nurse_id") > -1) {
              return (
                <option value={props.nurseMap[val]}>
                  {props.nurseMap[val]}
                </option>
              );
            }

            if (state.filterField.indexOf("status") > -1) {
              return <option value={val}>{translations[val]}</option>;
            }

            return <option value={val}>{val}</option>;
          }),
        ]}
        handleSelect={(val) => setState({ ...state, filterValue: val })}
      />
    );
  };

  const sortProcess = (name) => {
    if (!state.sort || state.sort.name !== name) {
      return setState({
        ...state,
        sort: { name: name, value: Sort.Descending },
      });
    } else if (
      state.sort.name === name &&
      state.sort.value === Sort.Descending
    ) {
      return setState({
        ...state,
        sort: { name: name, value: Sort.Ascending },
      });
    }
    setState({ ...state, sort: undefined });
  };

  let tapedTwice = false;
  const handleDoubleTouch = (event, b) => {
    if (!tapedTwice) {
      tapedTwice = true;
      setTimeout(function () {
        tapedTwice = false;
      }, 300);
      return false;
    }
    event.preventDefault();
    props.history.push({
      pathname: `/cc-admin-patient-report`,
      search: `?patient_id=${b.patient_id}&enroll=${b.enroll}&premium=${b.premium}`,
    });
  };

  return (
    <div className="patient-transfer">
      <div className="data">
        <div className="tabs">
          <a
            onClick={() =>
              setState({ ...state, tab: 1, filterField: undefined })
            }
            className={state.tab === 1 ? "selected" : ""}
          >
            {t(`Bed Allocation`)}
          </a>
          <a
            onClick={() =>
              setState({ ...state, tab: 2, filterField: undefined })
            }
            className={state.tab === 2 ? "selected" : ""}
          >
            {t(`Bed Assignments`)}
          </a>
        </div>
        <div className="devider"></div>
      </div>
      {state.tab === 1 ? (
        <div className="table-content">
          <div>{generateBedAllocation()}</div>
        </div>
      ) : (
        ""
      )}
      {state.tab === 2 ? (
        <div className="table-content">
          <div>{generateBedAssignments()}</div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
