import {GET_NURSES, GET_QR_DATA} from "../../actions/osp-mgr/showOspMgrQRCodeAction";

const initialState = {};

const showOspMgrQrCodeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NURSES:
            const {nurses} = action.payload;
            return {...state, listOfNurses: nurses};
        case GET_QR_DATA:
            return {...state, qrData: action.payload};
        default:
            return state
    }
};

export default showOspMgrQrCodeReducer;