import React, {useState, useRef, useEffect} from "react";
import PropTypes from "prop-types";
import "./OACTimeSelection.css";
import cx from "classnames";
import _ from "lodash";
import {useOutsideDetect} from "../../../utils/hooks";
import {formatTime} from "../../../utils/format";
import { v4 as uuidv4} from 'uuid';
import {disableTouchMove, enableTouchMove} from "../../../utils/windows-listener";

export function OACTimeSelection(props) {
    const VISIBLE_OFFSET = 4;

    const uuid = uuidv4();

    const [state, setState] = useState({
        cornerColor: {
            hour: props.hour - 2,
            min: props.min - 2
        },
        focusValue: {
            hour: props.hour,
            min: props.min
        },
    });
    const zRef = useRef(null);
    const numRef = useRef({
        startNum: props.hour - 2,
        previousOffset: 0
    });
    const minRef = useRef({
        startNum: props.min - 2,
        previousOffset: 0
    });
    // console.log(props, state)

    useOutsideDetect(zRef, state.isOpen, () => {
        setState({...state, isOpen: false});
        enableTouchMove();
    });

    useEffect(() => {
        let touchmoveY = 0;
        let touchstartY = 0;

        const gesuredHour = document.getElementById(`${uuid}-drag-hour`);
        const gesuredMin = document.getElementById(`${uuid}-drag-min`);

        gesuredHour.addEventListener('touchstart', function(event) {
            touchstartY = event.changedTouches[0]?.screenY;
            disableTouchMove();
        }, false);

        let gesuredTimeout;
        gesuredHour.addEventListener('touchmove', function(event) {
            touchmoveY = event.changedTouches[0]?.screenY;
            clearTimeout(gesuredTimeout);
            gesuredTimeout = setTimeout(function () {
                handleGesureHour();
            }, 10);
        }, false);

        gesuredHour.addEventListener('touchend', function(event) {
            enableTouchMove();
        }, false);

        function handleGesureHour() {
            if(touchstartY > touchmoveY) updateHourSelection(1);
            if(touchstartY < touchmoveY) updateHourSelection(-1);
        }

        gesuredMin.addEventListener('touchstart', function(event) {
            disableTouchMove();
            touchstartY = event.changedTouches[0]?.screenY;
        }, false);

        let gesuredTimeoutMin;
        gesuredMin.addEventListener('touchmove', function(event) {
            touchmoveY = event.changedTouches[0]?.screenY;
            clearTimeout(gesuredTimeoutMin);
            gesuredTimeoutMin = setTimeout(function () {
                handleGesureMin();
            }, 10);
        }, false);

        gesuredMin.addEventListener('touchend touchcancel', function(event) {
            enableTouchMove();
        }, false);

        function handleGesureMin() {
            if(touchstartY > touchmoveY) updateMinuteSelection(1);
            if(touchstartY < touchmoveY) updateMinuteSelection(-1);
        }


        const elh = document.getElementById(`${uuid}-drag-hour`);
        const elm = document.getElementById(`${uuid}-drag-min`);
        if (elh) {
            let dragTimeout;
            elh.onwheel = function (e) {
                clearTimeout(dragTimeout);
                dragTimeout = setTimeout(function () {
                    updateHourSelection(e.deltaY);
                }, 15);
            }
        }

        if (elm) {
            let dragTimeout2;
            elm.onwheel = function (e) {
                clearTimeout(dragTimeout2);
                dragTimeout2 = setTimeout(function () {
                    updateMinuteSelection(e.deltaY);
                }, 15);
            }
        }

        // if (!state.isOpen) {
        //     numRef.current = {startNum: props.hour - 2,};
        //     minRef.current = {startNum: props.min - 2,};
        //     setState({
        //         ...state,
        //         cornerColor: {
        //             hour: props.hour - 2,
        //             min: props.min - 2
        //         },
        //         focusValue: {
        //             hour: props.hour,
        //             min: props.min
        //         },
        //     })
        // }

    }, []);

    useEffect(() => {
        props.onChangeHour(numRef.current.startNum + 2);
    }, [numRef.current.startNum]);

    useEffect(() => {
        props.onChangeMinute(minRef.current.startNum + 2);
    }, [minRef.current.startNum]);

    const handleMinuteClick = (e) => {
        const val = e.target.innerText;
        if (!isNaN(val)) {
            updateMinuteSelection(Number(val) - state.focusValue?.min, false);
        }
    };

    const handleHourClick = (e) => {
        const val = e.target.innerText;
        if (!isNaN(val)) {
            updateHourSelection(Number(val) - state.focusValue?.hour, false);
        }
    };

    const updateMinuteSelection = (deltaY, isOpen = true) => {
        const offsetY = deltaY;
        let startNum = minRef.current.startNum;
        if (offsetY > 0) {
            startNum = startNum + 1;
        } else if (offsetY < 0) {
            startNum = startNum - 1;
        }
        if (startNum < props.startM - 2 || startNum > props.endM - 2) return;
        minRef.current = {startNum};
        setState({
            ...state,
            cornerColor: {hour: numRef.current.startNum, min: startNum},
            focusValue: {hour: numRef.current.startNum + 2, min: startNum + 2},
            isOpen: isOpen
        });
    };

    const updateHourSelection = (deltaY, isOpen = true) => {
        const offsetY = deltaY;
        let startNum = numRef.current.startNum;
        if (offsetY > 0) {
            startNum = startNum + 1;
        } else if (offsetY < 0) {
            startNum = startNum - 1;
        }

        if (startNum < props.startH - 2 || startNum > props.endH - 2) return;
        numRef.current = {startNum};
        setState({
            ...state,
            cornerColor: {hour: startNum, min: minRef.current.startNum},
            focusValue: {hour: startNum + 2, min: minRef.current.startNum + 2},
            isOpen: isOpen
        });
    };

    const openSelection = () => {
        setState({...state, isOpen: true});
    };

    // let scrollTimer;
    // const onScroll = (ev) => {
    //     const scrollTop = ev.target.scrollTop;
    //     console.log(scrollTop, ev.scrollY, ev.deltaY, ev.target.scrollY, ev.target.deltaY)
    //     // const scrollHeight = ev.target.scrollHeight;
    //     // const start = props.start || 1;
    //     // const end = props.end || 101;
    //     clearTimeout(scrollTimer);
    //     scrollTimer = setTimeout(function () {
    //         let startNum = state.cornerColor[0];
    //         if (scrollTop > 0) {
    //             startNum = startNum + 1;
    //         } else if (scrollTop < 0) {
    //             startNum = startNum - 1;
    //         }
    //         const endNum = startNum + VISIBLE_OFFSET;
    //         setState({...state, cornerColor: [startNum, endNum], focusValue: (endNum + startNum) / 2});
    //     }, 100);
    // };

    const renderHour = () => {
        return <ul id={`${uuid}-drag-hour`}>
            {
                _.range(numRef.current.startNum, numRef.current.startNum + 5).map(num => {
                    if (num === numRef.current.startNum || num === numRef.current.startNum + 5) {
                        return <li className="corner-color" key={num}>{formatTime(num, props.startH, props.endH)}</li>
                    } else if (numRef.current.startNum + 2 === num) {
                        return <li className="focus-color" key={num}>{formatTime(num, props.startH, props.endH)}</li>
                    }

                    return <li onClick={handleHourClick} key={num}>{formatTime(num, props.startH, props.endH)}</li>;
                })
            }
        </ul>;
    };

    const renderMin = () => {
        return <ul id={`${uuid}-drag-min`}>
            {
                _.range(minRef.current.startNum, minRef.current.startNum + 5).map(num => {
                    if (num === minRef.current.startNum || num === minRef.current.startNum + 5) {
                        return <li className="corner-color" key={num}>{formatTime(num, props.startM, props.endM)}</li>
                    } else if (minRef.current.startNum + 2 === num) {
                        return <li className="focus-color" key={num}>{formatTime(num, props.startM, props.endM)}</li>
                    }

                    return <li onClick={handleMinuteClick} key={num}>{formatTime(num, props.startM, props.endM)}</li>;
                })
            }
        </ul>;
    };

    const renderSemicolon = () => {
        return <ul>
            {
                _.range(0, 5).map(num => {
                    if (num === 0 || num === 4) {
                        return <li className="corner-color" key={num}>:</li>;
                    }
                    if (num === 2) {
                        return <li className="focus-color" key={num}>:</li>;
                    }
                    return <li className="" key={num}>:</li>;
                })
            }
        </ul>
    };

    return (
        <div ref={zRef} className="date-select">
            <input className={
                cx(
                    props.className,
                    "date-select-input",
                )
            } readOnly={true} value={`${formatTime(props.hour, props.startH, props.endH)} : ${formatTime(props.min, props.startM, props.endM)}`} onClick={openSelection}/>
            <div className={
                cx(
                    "date-select-block",
                    !state.isOpen ? "d-none" : ""
                )}>
                {renderHour()}
                {renderSemicolon()}
                {renderMin()}
            </div>
        </div>
    )
}