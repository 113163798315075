import {connect} from 'react-redux'
import {PatientReport} from "./PatientReport";
import {reportAction} from "../../../redux/actions/reportAction";
import {appAction} from "../../../redux/actions/appAction";

const mapStateToProps = state => ({
    patient: state.reportReducer.patient,
    sleeplogs: state.reportReducer.sleeplogs,
    config_history: state.reportReducer.config_history,
    sl: state.reportReducer.sl,
    vitalSign: state.reportReducer.vitalSign,
});

function mapDispatchToProps(dispatch) {
    return ({
        getSleepLog: (search) => dispatch(reportAction.getSleepLog(search)),
        editPatient: async (update) => dispatch(reportAction.editPatient(update)),
        dischargePatient: async (patient) => dispatch(reportAction.dischargePatient(patient)),
        openLoading: async () => dispatch(appAction.openLoading()),
        closeLoading: async () => dispatch(appAction.closeLoading()),
        exportCsv: async (info) => dispatch(reportAction.exportCsv(info)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientReport);