let supportsPassive = false;
try {
    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () {
            supportsPassive = true;
        }
    }));
} catch (e) {
}

let wheelOpt = supportsPassive ? {passive: false} : false;

function preventDefault(e) {
    e.preventDefault();
}

export function disableTouchMove() {
    window.addEventListener('touchmove', preventDefault, wheelOpt);
}

export function enableTouchMove() {
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
}