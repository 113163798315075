import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import {store, history} from './redux/store/global-store';
import LanguageProvider from './components/language';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'react-flags-select/css/react-flags-select.css';
import './index.css';
import './fit-size';

const Root = () => (
  <Provider store={store}>
      <LanguageProvider>
          <ConnectedRouter history={history}>
              <App/>
          </ConnectedRouter>
      </LanguageProvider>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
