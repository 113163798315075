import * as d3 from "d3";
import moment from "moment";
import {SLEEP_PHARSE, CHART_UNITS, COLOR, COLOR_SLEEP_PHARSE, HEIGHT_PHARSE, NAMES} from "./constant";
import RespImg from "../../libs/images/RESP-1A-R1.png";
import PrImg from "../../libs/images/PR-R1.png";
import {Zoomed} from "./constant";


export function renderContent(data, translations) {
    // for (let i = 0; i < data.length; i++) {
    //     renderOneReport(`${NAMES.REPORT}${i}`, data[i]);
    // }
    const lenOfData = data.length;
    const previousConfig = lenOfData > 1 ? data[1].config_history : undefined;
    renderOneReport(`${NAMES.REPORT}${0}`, data[0], translations, previousConfig);
}

let dataPointsBr, dataPointsHr, config_history, apneaEvents, eolFirstEvents, currentDate, previousDate, config_history_previous;
const DefaultThreshold = {
    vital_enabled: true,
    rpm_high: 0,
    rpm_low: 5,
    bpm_high: 120,
    bpm_low: 40,
    off_bed_delay: 0,
    off_bed_enabled: true,
    off_bed_from_minute: 0,
    off_bed_to_minute: 0,
    off_bed_from_hour: 21,
    off_bed_to_hour: 7,
    end_of_life_enabled: false,
    awake_sens: 2
};

function renderOneReport(name, data = {}, translations, previousConfig) {
    const content = d3.select(".rept-content");
    const report = content
        .append("div")
        .attr("class", "one-rept")
        .attr("id", name);

    const reptHeader = report.append("div").attr("class", "vital-sign-header");
    const reptContent = report.append("div").attr("class", "vital-sign-content");

    let apneaTime = "--";
    if (data && data.eolFirstEvents && data.eolFirstEvents.length > 0) {
        apneaTime = formatMinuteToHour(data.eolFirstEvents[0].startTimeOrigin);
    }

    dataPointsBr = data.dataPointsBr;
    dataPointsHr = data.dataPointsHr;
    apneaEvents = data.apneaEvents;
    eolFirstEvents = data.eolFirstEvents;
    previousDate = moment(data.day, "YYYY_M_D").format("YYYY-MM-DD");
    currentDate = moment(data.day, "YYYY_M_D").add(1, "days").format("YYYY-MM-DD");
    config_history = data.config_history || {};
    // if (!config_history["1440"]) {
    //     config_history["1440"] = {};
    // }
    // config_history_previous = {...previousConfig};
    // if (!config_history_previous["1440"]) {
    //     config_history_previous["1440"] = {};
    // }

    let after720 = {}, before2160 = {};
    Object.keys(config_history).forEach(key => {
        if(Number(key) <= 720) after720 = {...config_history[key]};
        if(Number(key) <= 2160) before2160 = {...config_history[key]};
        if(Number(key) < 720 || Number(key) > 2160) delete config_history[key];
    });
    config_history[720] = after720;
    config_history[2160] = before2160;

    // let before720 = {};
    // Object.keys(config_history_previous).forEach(key => {
    //     if(Number(key) <= 720) before720 = {...config_history_previous[key]};
    //     if(Number(key) < 720 || Number(key) > 1440) delete config_history_previous[key];
    // });
    // config_history_previous[720] = before720;

    // append current date
    reptContent.append("div").attr("class", "rept-day").text(previousDate);

    renderOneReportHeader(reptHeader, {
        highBpmCnt: data.highBpmCnt,
        lowBpmCnt: data.lowBpmCnt,
        highRpmCnt: data.highRpmCnt,
        lowRpmCnt: data.lowRpmCnt,
        apneaCnt: data.apneaCnt || 0,
        minHr: data.minHr,
        minBr: data.minBr,
        maxHr: data.maxHr,
        maxBr: data.maxBr,
        aveBr: data.aveBr,
        aveHr: data.aveHr,
        apneaTime
    }, data.day, translations);
    renderOneReportContent(reptContent, name, data, translations);
}

function renderOneReportHeader(reptHeader, cnts, day, translations) {
    const statistics = reptHeader.append("div").attr("class", "statistics");
    const statisticFieldset = statistics.append("div").attr("class", "fieldset");
    const legendStatistic = statisticFieldset.append("div").attr("class", "legend");
    legendStatistic.append("b").text(translations["STATISTICS"]);
    legendStatistic.append("span").text(translations["(for displayed timeline)"]);
    const cursorMeasurement = reptHeader.append("div").attr("class", "cursor-measurement");
    const cursorFieldset = cursorMeasurement.append("div").attr("class", "fieldset");
    const cursorLegend = cursorFieldset.append("div").attr("class", "legend");
    cursorLegend.append("b").text(translations["CURSOR MEASUREMENT"]);
    cursorLegend.append("b").attr("class", "cursor-date").text("--");

    // statistics
    // resp
    const respFieldset = statisticFieldset.append("div").attr("class", "fieldset resp-statistics");

    // pr
    const prFieldset = statisticFieldset.append("div").attr("class", "fieldset pr-statistics");

    // event
    const eventFieldset = statisticFieldset.append("div").attr("class", "fieldset event-statistics");

    // legends
    respFieldset
        .append("div")
        .attr("class", "legend")
        .append("img")
        .attr("x", 0)
        .attr("y", 0)
        // .attr("width", 20)
        // .attr("height", 20)
        .attr("src", RespImg);

    prFieldset
        .append("div")
        .attr("class", "legend")
        .append("legend")
        .append("img")
        .attr("x", 0)
        .attr("y", 0)
        // .attr("width", 20)
        // .attr("height", 20)
        .attr("src", PrImg);

    eventFieldset.append("div").attr("class", "legend").text(translations["Events"]);

    // readings
    const cursorContent = cursorFieldset.append("div").attr("class", "cursor-content");
    const cursorBlock1 = cursorFieldset.append("div").attr("class", "block-1");
    const readingFieldset = cursorFieldset.append("div").attr("class", "fieldset fieldset-readings");
    const thresholdFieldset = cursorFieldset.append("div").attr("class", "fieldset fieldset-threshold");


    // // thresholds
    // const cursorBlock2 = cursorContent.append("div").attr("class", "block-2");
    // const thresholdRow1 = cursorBlock2.append("div").attr("class", "th-row1");
    // const thresholdRow2 = cursorBlock2.append("div").attr("class", "th-row2");
    //
    // // th-row11
    // const fieldset3 = thresholdRow1.append("fieldset").attr("class", "fieldset-thresholds");
    // fieldset3.append("legend").text("Thresholds");
    //
    // // th-row12
    // const fieldset4 = thresholdRow1.append("fieldset").attr("class", "fieldset-others");
    // fieldset4.append("legend").text("Others");
    //
    // // th-row21
    // const fieldset5 = thresholdRow2.append("fieldset").attr("class", "fieldset-op");
    // fieldset5.append("legend").text("Off-Pillow");

    // statistics detail
    generateStatisticsDetail(respFieldset, prFieldset, eventFieldset, cnts, translations);

    // generate readings block
    generateReadings(readingFieldset, translations);

    // generate threshold block
    generateThresholds(thresholdFieldset, translations);
}

function generateThresholds(thresholdFieldset, translations) {
    // thresholds
    thresholdFieldset.append("div").attr("class", "legend").text(translations["Thresholds"]);
    const thRESP = thresholdFieldset.append("div").attr("class", "th-resp");
    const thPR = thresholdFieldset.append("div").attr("class", "th-pr");

    thRESP
        .append("span")
        .attr("class", "th-resp-label")
        .append("img")
        .attr("x", 0)
        .attr("y", 0)
        // .attr("width", 20)
        // .attr("height", 20)
        .attr("src", RespImg);
    const loWrapper = thRESP.append("div").attr("class", "low-wrapper");
    loWrapper.append("span").attr("class", "th-resp-label-lo").text(translations["Lo:"]);
    loWrapper.append("span").attr("class", "th-resp-value-lo text-bordered w48px mr30").text("--");

    const hiWrapper = thRESP.append("div").attr("class", "hi-wrapper");
    hiWrapper.append("span").attr("class", "th-resp-label-hi").text(translations["Hi:"]);
    hiWrapper.append("span").attr("class", "th-resp-value-hi text-bordered w48px").text("--");

    thPR
        .append("span")
        .attr("class", "th-pr-label")
        .append("img")
        .attr("x", 0)
        .attr("y", 0)
        // .attr("width", 20)
        // .attr("height", 20)
        .attr("src", PrImg);

    const loPrWrapper = thPR.append("div").attr("class", "low-wrapper");
    loPrWrapper.append("span").attr("class", "th-pr-label-lo").text(translations["Lo:"]);
    loPrWrapper.append("span").attr("class", "th-pr-value-lo text-bordered w48px mr30").text("--");

    const hiPrWrapper = thPR.append("div").attr("class", "hi-wrapper");
    hiPrWrapper.append("span").attr("class", "th-pr-label-hi").text(translations["Hi:"]);
    hiPrWrapper.append("span").attr("class", "th-pr-value-hi text-bordered w48px").text("--");
}

function generateReadings(fieldset2, translations) {
    fieldset2.append("div").attr("class", "legend").text(translations["Cursor reading"]);
    const readingsDiv1 = fieldset2.append("div").attr("class", "reading-resp");
    const readingsDiv2 = fieldset2.append("div").attr("class", "reading-resp");
    readingsDiv1
        .append("span")
        .attr("class", "reading-text")
        .append("span")
        .attr("class", "th-pr-label w85px")
        .append("img")
        .attr("x", 0)
        .attr("y", 0)
        // .attr("width", 20)
        // .attr("height", 20)
        .attr("src", RespImg);
    readingsDiv1.append("span").attr("class", "reading-value value-resp text-bordered w100px").text("--");
    readingsDiv2
        .append("span")
        .attr("class", "reading-text")
        .append("span")
        .attr("class", "th-pr-label w85px")
        .append("img")
        .attr("x", 0)
        .attr("y", 0)
        // .attr("width", 20)
        // .attr("height", 20)
        .attr("src", PrImg);
    readingsDiv2.append("span").attr("class", "reading-value value-pr text-bordered w100px").text("--");
}

function generateStatisticsDetail(respFieldset, prFieldset, eventFieldset, cnts, translations) {
    // clear
    respFieldset.selectAll("div.removable").remove();
    prFieldset.selectAll("div.removable").remove();
    eventFieldset.selectAll("div.removable").remove();

    // resp block
    const respDiv1 = respFieldset.append("div").attr("class", "removable");
    respDiv1.append("span").text(translations["Min:"]);
    respDiv1.append("b").text(cnts.minBr !== -1 ? cnts.minBr : "--");

    const respDiv2 = respFieldset.append("div").attr("class", "removable");

    respDiv2.append("span").text(translations["Max:"]);
    respDiv2.append("b").text(cnts.maxBr !== -1 ? cnts.maxBr : "--");

    const respDiv3 = respFieldset.append("div").attr("class", "removable");

    respDiv3.append("span").text(translations["Ave:"]);
    respDiv3.append("b").text(cnts.aveBr);

    const respDiv4 = respFieldset.append("div").attr("class", "removable");

    respDiv4.append("span").text(translations["Lo. Threshold Exceeded:"]);
    respDiv4.append("b").text(cnts.lowBpmCnt);

    const respDiv5 = respFieldset.append("div").attr("class", "removable");

    respDiv5.append("span").text(translations["Hi. Threshold Exceeded:"]);
    respDiv5.append("b").text(cnts.highBpmCnt);

    // pr block
    const prDiv1 = prFieldset.append("div").attr("class", "removable");

    prDiv1.append("span").text(translations["Min:"]);
    prDiv1.append("b").text(cnts.minHr !== -1 ? cnts.minHr : "--");

    const prDiv2 = prFieldset.append("div").attr("class", "removable");

    prDiv2.append("span").text(translations["Max:"]);
    prDiv2.append("b").text(cnts.maxHr !== -1 ? cnts.maxHr : "--");

    const prDiv3 = prFieldset.append("div").attr("class", "removable");

    prDiv3.append("span").text(translations["Ave:"]);
    prDiv3.append("b").text(cnts.aveHr);

    const prDiv4 = prFieldset.append("div").attr("class", "removable");

    prDiv4.append("span").text(translations["Lo. Threshold Exceeded:"]);
    prDiv4.append("b").text(cnts.lowRpmCnt);

    const prDiv5 = prFieldset.append("div").attr("class", "removable");

    prDiv5.append("span").text(translations["Hi. Threshold Exceeded:"]);
    prDiv5.append("b").text(cnts.highRpmCnt);

    // event block
    const eventDiv1 = eventFieldset.append("div").attr("class", "removable");

    eventDiv1.append("span")
        .attr("class", "w150px")
        .text(translations["Apnea events:"]);
    eventDiv1.append("b")
        .attr("class", "text-bordered text-blue")
        .text(cnts.apneaCnt);

    const eventDiv2 = eventFieldset.append("div").attr("class", "removable");

    eventDiv2.append("span")
        .attr("class", "w150px")
        .text(translations["Time (Vital Signs not detected):"]);
    eventDiv2.append("b")
        .attr("class", "text-bordered text-orange")
        .text(cnts.apneaTime);
    // .text("--");
}

function renderOneReportContent(reptContent, name, data, translations) {
    const wrapper1 = reptContent
        .append("div")
        .attr("class", "content-wrapper");

    const div1 = wrapper1
        .append("div")
        .attr("class", "info");

    // canvas info
    const reptName = div1.append("div").attr("class", "canvas-name");
    // reptName.append("div").text("RESP");
    reptName.append("img")
        .attr("x", 0)
        .attr("y", 0)
        .attr("src", RespImg);

    // measurement
    const measurement = div1.append("div").attr("class", "canvas-measurement");
    // measurement.append("div").text("RPM");
    measurement.append("div").attr("class", "time-text").text(translations["Time"]);

    const ruler1 = div1.append("div").attr("class", "canvas-ruler");
    // canvas ruler
    ruler1.append("div").text("100");
    ruler1.append("div").text("75");
    ruler1.append("div").text("50");
    ruler1.append("div").text("25");
    ruler1.append("div").text("0");


    const groupCanvas1 = wrapper1
        .append("div")
        .style("position", "relative");

    groupCanvas1
        .append("svg")
        .style("cursor", "pointer")
        .attr("class", `zooming2`)
        .attr("id", `svg-${name}-canvas-1`);

    groupCanvas1
        .append("canvas")
        .attr("width", 1778)
        .attr("height", 283)
        .attr("id", `${name}-canvas-1`);

    const wrapper2 = reptContent
        .append("div")
        .attr("class", "content-wrapper");

    const div2 = wrapper2
        .append("div")
        .attr("class", "info");

    // canvas info
    const prName = div2.append("div").attr("class", "canvas-name");
    // prName.append("div").text("PR");
    prName.append("img")
        .attr("x", 0)
        .attr("y", 0)
        .attr("src", PrImg);

    // measurement
    const measurement2 = div2.append("div").attr("class", "canvas-measurement");
    // measurement2.append("div").text("BPM");
    measurement2.append("div").attr("class", "time-text").text(translations["Time"]);

    const ruler2 = div2.append("div").attr("class", "canvas-ruler");
    // canvas ruler
    ruler2.append("div").text("200");
    ruler2.append("div").text("150");
    ruler2.append("div").text("100");
    ruler2.append("div").text("50");
    ruler2.append("div").text("0");

    const groupCanvas2 = wrapper2
        .append("div")
        .style("position", "relative");

    groupCanvas2
        .append("svg")
        .style("cursor", "pointer")
        .attr("class", `zooming2`)
        .attr("id", `svg-${name}-canvas-2`);

    groupCanvas2
        .append("canvas")
        .attr("width", 1778)
        .attr("height", 283)
        .attr("id", `${name}-canvas-2`);

    function initZoom() {
        let zoom = d3.zoom()
            .on('zoom', handleZoom);

        d3.selectAll(`.zooming2`)
            .call(zoom);
    }

    function handleZoom(e) {
        const previousZoomed = Zoomed.zoomed;
        const previousTranslatedX = Zoomed.translatedX;
        const previousOldK = Zoomed.previousK;

        let delta = 1800;
        const x = e.sourceEvent?.x;

        if (x <= 600) {
            delta = 0;
        } else if (x <= 900) {
            delta = 900;
        } else if (x <= 1100) {
            delta = 1800;
        } else if (x <= 1200) {
            delta = 1900;
        } else if (x <= 1300) {
            delta = 2000;
        } else if (x <= 1500) {
            delta = 2100;
        } else if (x <= 1700) {
            delta = 2700;
        } else if (x >= 1700) {
            delta = 3600;
        }

        // k zoomed
        if (e.transform.k > Zoomed.previousK && Zoomed.zoomed > -20) {
            Zoomed.translatedX = Zoomed.zoomed === -20 ? Zoomed.translatedX : Zoomed.translatedX - (4 * delta);
            Zoomed.zoomed = Zoomed.zoomed - 4 > -20 ? Zoomed.zoomed - 4 : -20;
            Zoomed.previousK = e.transform.k;
        } else if (e.transform.k < Zoomed.previousK && Zoomed.zoomed < 0) {
            Zoomed.zoomed = Zoomed.zoomed + 4 > 0 ? 0 : Zoomed.zoomed + 4;
            Zoomed.previousK = e.transform.k;
            Zoomed.translatedX = Zoomed.translatedX + (4 * delta);
        } else {
            Zoomed.previousK = e.transform.k;
        }

        // x axis
        let xDistance = 420;
        if (Zoomed.zoomed === -16) {
            xDistance = 120;
        } else if (Zoomed.zoomed === -20) {
            xDistance = 60;
        }

        if (e.transform.x < Zoomed.previousX && Zoomed.translatedX > Zoomed.zoomed * 3600 && Zoomed.zoomed < 0 && previousOldK === e.transform.k) {
            Zoomed.translatedX = Zoomed.translatedX - (Math.floor(23 / (Zoomed.zoomed >= -20 ? 12 : -Zoomed.zoomed) * xDistance));
            Zoomed.previousX = e.transform.x;
        } else if (e.transform.x > Zoomed.previousX && Zoomed.translatedX < 0 && Zoomed.zoomed < 0 && previousOldK === e.transform.k) {
            Zoomed.translatedX = Zoomed.translatedX + (Math.floor(23 / (Zoomed.zoomed >= -20 ? 12 : -Zoomed.zoomed) * xDistance));
            Zoomed.previousX = e.transform.x;
        } else if (Zoomed.zoomed >= 0) {
            Zoomed.translatedX = 0;
        } else {
            Zoomed.previousX = e.transform.x;
        }

        // if > 0
        if (Zoomed.translatedX > 0) {
            Zoomed.translatedX = 0;
        } else if (Zoomed.translatedX <= Zoomed.zoomed * 3600) {
            Zoomed.translatedX = Zoomed.zoomed * 3600;
        }

        // update graphics
        if (previousZoomed !== Zoomed.zoomed || previousTranslatedX !== Zoomed.translatedX) {
            drawChart(`${name}-canvas-1`, data.dataPointsBr, data.apneaEvents, data.config_history, {
                    high: NAMES.RESP_HIGH,
                    low: NAMES.RESP_LOW
                },
                {
                    maxUnit: 100,
                    low: "rpm_low",
                    high: "rpm_high",
                    max: 100,
                    min: 0,
                },
                {
                    min: 0, max: 100
                },
                ruler1,
                Zoomed.zoomed, Zoomed.translatedX,
                `${name}-canvas-2`,
                config_history_previous
            );
            drawChart(`${name}-canvas-2`, data.dataPointsHr, data.apneaEvents, data.config_history, {
                    high: NAMES.PR_HIGH,
                    low: NAMES.PR_LOW
                },
                {
                    maxUnit: 200,
                    low: "bpm_low",
                    high: "bpm_high",
                    max: 200,
                    min: 0,
                },
                {
                    min: 0, max: 200
                },
                ruler2,
                Zoomed.zoomed, Zoomed.translatedX,
                `${name}-canvas-1`,
                config_history_previous
            );

            // update statistics
            const respFieldset = d3.select(".fieldset.resp-statistics");
            const prFieldset = d3.select(".fieldset.pr-statistics");
            const eventFieldset = d3.select(".fieldset.event-statistics");
            const cnts = calcStatistics(24 + Zoomed.zoomed, Zoomed.translatedX);
            generateStatisticsDetail(respFieldset, prFieldset, eventFieldset, cnts, translations)
        }
    }

    initZoom();

    drawChart(`${name}-canvas-1`, data.dataPointsBr, data.apneaEvents, data.config_history, {
            high: translations[NAMES.RESP_HIGH],
            low: translations[NAMES.RESP_LOW]
        },
        {
            maxUnit: 100,
            low: "rpm_low",
            high: "rpm_high",
            max: 100,
            min: 0,
        },
        {
            min: 0, max: 100
        },
        ruler1,
        Zoomed.zoomed, Zoomed.translatedX,
        `${name}-canvas-2`,
        config_history_previous
    );
    drawChart(`${name}-canvas-2`, data.dataPointsHr, data.apneaEvents, data.config_history, {
            high: translations[NAMES.PR_HIGH],
            low: translations[NAMES.PR_LOW]
        },
        {
            maxUnit: 200,
            low: "bpm_low",
            high: "bpm_high",
            max: 200,
            min: 0,
        },
        {
            min: 0, max: 200
        },
        ruler2,
        Zoomed.zoomed, Zoomed.translatedX,
        `${name}-canvas-1`,
        config_history_previous
    );
}

function calcStatistics(totalHours, translatedX) {
    const totalUnit = Math.floor(1440 * totalHours / 24);
    const start = Math.floor(0 - translatedX / 60);
    const end = start + totalUnit;

    const dataBr = [...dataPointsBr].slice(start, end).map((d, idx) => [idx, d]) || [];
    const dataHr = [...dataPointsHr].slice(start, end).map((d, idx) => [idx, d]) || [];
    const apneaEventsInThisPeriod = apneaEvents.filter(ev => {
        return ev.startTime / 60 >= start && ev.startTime / 60 <= end && !ev.noCount;
    });

    const eolInThisPeriod = eolFirstEvents.filter(ev => {
        return ev.startTime / 60 >= start && ev.startTime / 60 <= end && !ev.noCount;
    });

    // exceed events
    const exceedEventsBr = [], exceedEventsHr = [];
    const revert_keys_config_history = Object.keys(config_history).sort((a, b) => a > b ? -1 : 1);
    const brThresholdLow = [], hrThresholdLow = [], brThresholdHigh = [], hrThresholdHigh = [], vital_enabled = [];
    for (let i = start; i < end; i++) {
        const findKey = revert_keys_config_history.find(k => Number(k) <= i);
        if (findKey) {
            brThresholdLow.push(config_history[findKey].rpm_low);
            brThresholdHigh.push(config_history[findKey].rpm_high);
            hrThresholdLow.push(config_history[findKey].bpm_low);
            hrThresholdHigh.push(config_history[findKey].bpm_high);
            vital_enabled.push(config_history[findKey].vital_enabled);
        }
    }

    let flatEventBr = [], flatEventHr = [];
    dataBr.forEach((data, idx) => {
        // console.log(d, brThresholdLow[idx], brThresholdHigh[idx])
        const d = data[1];
        if (d!== null && d >= 0 && d < Number(brThresholdLow[idx]) && vital_enabled[idx]) {
            flatEventBr.push({type: "LOW_RESP", startInSeconds: idx * 60, val: d, th: brThresholdLow[idx]});
        } else if (d > Number(brThresholdHigh[idx]) && Number(brThresholdHigh[idx]) !== 0 && vital_enabled[idx]) {
            flatEventBr.push({type: "HIGH_RESP", startInSeconds: idx * 60, val: d, th: brThresholdHigh[idx]});
        }
    });
    dataHr.forEach((data, idx) => {
        const d = data[1];
        // console.log(d, hrThresholdLow[idx], hrThresholdHigh[idx])
        if (d!== null && d > 0 && d < Number(hrThresholdLow[idx]) && vital_enabled[idx]) {
            flatEventHr.push({type: "LOW_PR", startInSeconds: idx * 60, val: d, th: hrThresholdLow[idx]});
        } else if (d > Number(hrThresholdHigh[idx]) && Number(hrThresholdHigh[idx]) !== 0 && vital_enabled[idx]) {
            flatEventHr.push({type: "HIGH_PR", startInSeconds: idx * 60, val: d, th: hrThresholdHigh[idx]});
        }
    });

    // calc flatEvent
    // let brEventName, brStartTime, brLastTime;
    // flatEventBr.forEach((br, idx) => {
    //     if (idx === 0) {
    //         brEventName = br.type;
    //         brStartTime = br.startInSeconds;
    //         brLastTime = br.startInSeconds;
    //     } else if (brEventName !== br.type || br.startInSeconds - brLastTime > 60) {
    //         exceedEventsBr.push({
    //             type: brEventName,
    //             startTime: brStartTime,
    //             duration: brLastTime !== brStartTime ? brLastTime - brStartTime : 60
    //         });
    //         brEventName = br.type;
    //         brStartTime = br.startInSeconds;
    //         brLastTime = br.startInSeconds;
    //     } else if (idx === flatEventBr.length - 1) {
    //         exceedEventsBr.push({
    //             type: brEventName,
    //             startTime: brStartTime,
    //             duration: br.startInSeconds - brStartTime
    //         });
    //     } else {
    //         brLastTime = br.startInSeconds;
    //     }
    // });
    //
    // let hrEventName, hrStartTime, hrLastTime;
    // flatEventHr.forEach((hr, idx) => {
    //     if (idx === 0) {
    //         hrEventName = hr.type;
    //         hrStartTime = hr.startInSeconds;
    //         hrLastTime = hr.startInSeconds;
    //     } else if (hrEventName !== hr.type || hr.startInSeconds - hrLastTime > 60) {
    //         exceedEventsHr.push({
    //             type: hrEventName,
    //             startTime: hrStartTime,
    //             duration: hrLastTime !== hrStartTime ? hrLastTime - hrStartTime : 60
    //         });
    //         hrEventName = hr.type;
    //         hrStartTime = hr.startInSeconds;
    //         hrLastTime = hr.startInSeconds;
    //     } else if (idx === flatEventHr.length - 1) {
    //         exceedEventsHr.push({
    //             type: hrEventName,
    //             startTime: hrStartTime,
    //             duration: hr.startInSeconds - hrStartTime
    //         });
    //     } else {
    //         hrLastTime = hr.startInSeconds;
    //     }
    // });

    // count
    const highBpmCnt = flatEventBr.filter(br => br.type === "HIGH_RESP").length;
    const lowBpmCnt = flatEventBr.filter(br => br.type === "LOW_RESP").length;
    const highRpmCnt = flatEventHr.filter(br => br.type === "HIGH_PR").length;
    const lowRpmCnt = flatEventHr.filter(br => br.type === "LOW_PR").length;
    const apneaCnt = apneaEventsInThisPeriod.length;

    // max, min
    let maxBr = -1, maxHr = -1, minBr = -1, minHr = -1;
    let totalBr = 0, totalPointBr = 0, aveBr, totalHr = 0, totalPointHr = 0, aveHr;
    dataBr.forEach(data => {
        const d = data[1];
        if (d !== null && d !== 0 && (maxBr < d || maxBr === -1) && d >= 0 && d <= 100) {
            maxBr = d;
        }

        if (d !== null && d !== 0 && (minBr > d || minBr === -1) && d >= 0 && d <= 100) {
            minBr = d;
        }

        if (d !== null && d !== 0 && d >= 0 && d <= 100) {
            totalBr += d;
            totalPointBr += 1;
        }
    });

    dataHr.forEach(data => {
        const d = data[1];
        if (d !== null && d !== 0 && (maxHr < d || maxHr === -1) && d >= 0 && d <= 200) {
            maxHr = d;
        }

        if (d !== null && d !== 0 && (minHr > d || minHr === -1) && d >= 0 && d <= 200) {
            minHr = d;
        }

        if (d !== null && d !== 0 && d >= 0 && d <= 200) {
            totalHr += d;
            totalPointHr += 1;
        }
    });

    // cal ave
    aveBr = totalPointBr !== 0 ? Math.round(totalBr / totalPointBr) : "--";
    aveHr = totalPointHr !== 0 ? Math.round(totalHr / totalPointHr) : "--";

    let apneaTime = "--";
    if (eolInThisPeriod && eolInThisPeriod.length > 0) {
        apneaTime = formatMinuteToHour(eolInThisPeriod[0].startTimeOrigin);
    }

    return {
        highBpmCnt: highBpmCnt,
        lowBpmCnt: lowBpmCnt,
        highRpmCnt: highRpmCnt,
        lowRpmCnt: lowRpmCnt,
        apneaCnt: apneaCnt || 0,
        minHr: minHr,
        minBr: minBr,
        maxHr: maxHr,
        maxBr: maxBr,
        aveBr: aveBr,
        aveHr: aveHr,
        apneaTime
    }
}

function drawChart(id, data = [], events = [], thresholds, texts, fields, options, ruler, zoomed = 0, translatedX = 0, id2, config_history_previous) {
    let canvas = document.getElementById(id);
    let width = document.getElementById(id).width;
    let height = document.getElementById(id).height;
    let xMargin = 0;

    let ctx = canvas.getContext("2d");
    clearCanvas(ctx, width, height);
    if (id.indexOf("canvas-2") === -1) {
        drawEvent(ctx, width, height, xMargin, events, 24 + zoomed, translatedX);
    }
    drawGrid(ctx, width, height, xMargin);
    drawXAxis(id, zoomed, translatedX);
    drawLineChartByD3(id, data, options, 24 + zoomed, translatedX, ruler, (max, min) => {
        // console.log(thresholds, config_history_previous)
        drawThreshold(id, ctx, width, height, xMargin, thresholds, texts, {
            ...fields,
            max,
            min,
            maxUnit: max - min
        }, 24 + zoomed, translatedX);
        // drawThresholdPrevious(id, ctx, width, height, xMargin, config_history_previous, texts, {
        //     ...fields,
        //     max,
        //     min,
        //     maxUnit: max - min
        // }, 24 + zoomed, translatedX);
    }, width, height);
    drawCursor(id, width, height, 24 + zoomed, translatedX, id2);
}

function clearCanvas(ctx, width, height,) {
    // clear
    ctx.clearRect(0, 0, width, height);
}


let cursorPositionX;

function drawCursor(id, width, height, totalHour = 24, translatedX = 0, id2) {
    const svg = d3.select(`#svg-${id}`);
    const svg2 = d3.select(`#svg-${id2}`);
    svg.selectAll('.rectMask').remove();
    svg.selectAll('.line-grid').remove();
    svg.append('rect')
        .attr("class", "rectMask")
        .attr("id", `rectMask-${id}`)
        .style("fill", "none")
        .style("pointer-events", "all")
        // .style('cursor', 'e-resize')
        .attr('width', width)
        .attr('height', height)
        // .attr('x', 100 - 5)
        // .attr('y', 0)
        // .on('mouseover', mouseover)
        .on('mousemove', mousemove)
        .on('touchmove', touchmove)
        // .on('touchend', touchmove)
    // .on('mouseout', mouseout);

    const percent = document
        .getElementById("report-content")
        .style
        .getPropertyValue("--report-zoom") || 1;

    const percent2 = document
        .documentElement
        .style
        .getPropertyValue("--main-zoom");

    for (let i = 1; i <= 11; i++) {
        svg
            .append("line")
            .attr("class", "line-grid")
            .attr('stroke', 'rgb(170, 170, 170)')
            .style("stroke-width", 1)
            .attr('x1', 0)
            .attr('y1', height / 11 * i)
            .attr('x2', width)
            .attr('y2', height / 11 * i);
    }

    function touchmove(e) {
        if(e.touches?.length > 1) return;

        cursorPositionX = (e.targetTouches[0].pageX - (230 * Number(percent2) + 40 + 117 * Number(percent))) / Number(percent);

        // update cursor value
        if (dataPointsBr) {
            updateCursorMeasurement(cursorPositionX, totalHour, translatedX, width, svg);
        }

        // redraw cursor line
        if (cursorPositionX) {
            svg.selectAll(".cursor-measurement").remove();
            svg2.selectAll(".cursor-measurement").remove();
            svg.selectAll("defs").remove();
            svg2.selectAll("defs").remove();

            // create marker start
            svg.append("svg:defs").append("svg:marker")
                .attr("id", "trianglestart")
                .attr("refX", 0)
                .attr("refY", 6)
                .attr("markerWidth", 30)
                .attr("markerHeight", 30)
                .attr("markerUnits", "userSpaceOnUse")
                .attr("orient", "auto")
                .append("path")
                .attr("d", "M 0 0 L 0 12 L 12 6 Z")
                .style("fill", "rgb(20, 100, 246)");

            // create marker end
            svg.append("svg:defs").append("svg:marker")
                .attr("id", "triangleend")
                .attr("refX", 12)
                .attr("refY", 6)
                .attr("markerWidth", 30)
                .attr("markerHeight", 30)
                .attr("markerUnits", "userSpaceOnUse")
                .attr("orient", "auto")
                .append("path")
                .attr("d", "M 12 0 L 12 12 L 0 6 Z")
                .style("fill", "rgb(20, 100, 246)");

            // add lines
            svg
                .append("line")
                .attr("class", "cursor-measurement")
                .attr('stroke', 'rgb(20, 100, 246)')
                .style("stroke-width", 1.5)
                .style('stroke-dasharray', '8,4')
                .attr('x1', cursorPositionX)
                .attr('y1', function (d) {
                    return 0
                })
                .attr('x2', cursorPositionX)
                .attr('y2', function (d) {
                    return height
                })
                .attr("marker-start", "url(#trianglestart)")
                .attr("marker-end", "url(#triangleend)");
            svg2
                .append("line")
                .attr("class", "cursor-measurement")
                .attr('stroke', 'rgb(20, 100, 246)')
                .style("stroke-width", 1.5)
                .style('stroke-dasharray', '8,4')
                .attr('x1', cursorPositionX)
                .attr('y1', function (d) {
                    return 0
                })
                .attr('x2', cursorPositionX)
                .attr('y2', function (d) {
                    return height
                })
                .attr("marker-start", "url(#trianglestart)")
                .attr("marker-end", "url(#triangleend)");
        }
    }

    function mousemove(e) {
        cursorPositionX = (e.layerX) / Number(percent);

        // update cursor value
        if (dataPointsBr) {
            updateCursorMeasurement(cursorPositionX, totalHour, translatedX, width, svg);
        }

        // redraw cursor line
        if (cursorPositionX) {
            svg.selectAll(".cursor-measurement").remove();
            svg2.selectAll(".cursor-measurement").remove();
            svg.selectAll("defs").remove();
            svg2.selectAll("defs").remove();

            // create marker start
            svg.append("svg:defs").append("svg:marker")
                .attr("id", "trianglestart")
                .attr("refX", 0)
                .attr("refY", 6)
                .attr("markerWidth", 30)
                .attr("markerHeight", 30)
                .attr("markerUnits", "userSpaceOnUse")
                .attr("orient", "auto")
                .append("path")
                .attr("d", "M 0 0 L 0 12 L 12 6 Z")
                .style("fill", "rgb(20, 100, 246)");

            // create marker end
            svg.append("svg:defs").append("svg:marker")
                .attr("id", "triangleend")
                .attr("refX", 12)
                .attr("refY", 6)
                .attr("markerWidth", 30)
                .attr("markerHeight", 30)
                .attr("markerUnits", "userSpaceOnUse")
                .attr("orient", "auto")
                .append("path")
                .attr("d", "M 12 0 L 12 12 L 0 6 Z")
                .style("fill", "rgb(20, 100, 246)");

            // add lines
            svg
                .append("line")
                .attr("class", "cursor-measurement")
                .attr('stroke', 'rgb(20, 100, 246)')
                .style("stroke-width", 1.5)
                .style('stroke-dasharray', '8,4')
                .attr('x1', cursorPositionX)
                .attr('y1', function (d) {
                    return 0
                })
                .attr('x2', cursorPositionX)
                .attr('y2', function (d) {
                    return height
                })
                .attr("marker-start", "url(#trianglestart)")
                .attr("marker-end", "url(#triangleend)");
            svg2
                .append("line")
                .attr("class", "cursor-measurement")
                .attr('stroke', 'rgb(20, 100, 246)')
                .style("stroke-width", 1.5)
                .style('stroke-dasharray', '8,4')
                .attr('x1', cursorPositionX)
                .attr('y1', function (d) {
                    return 0
                })
                .attr('x2', cursorPositionX)
                .attr('y2', function (d) {
                    return height
                })
                .attr("marker-start", "url(#trianglestart)")
                .attr("marker-end", "url(#triangleend)");
        }
    }
}

function updateCursorMeasurement(position, totalHours, translatedX, width, svg) {
    const totalUnit = Math.floor(1440 * totalHours / 24);
    const start = Math.floor(0 - translatedX / 60);
    const end = start + totalUnit;

    const dataBr = [...dataPointsBr].slice(start, end).map((d, idx) => [idx, d]) || [];
    const dataHr = [...dataPointsHr].slice(start, end).map((d, idx) => [idx, d]) || [];

    const positionInMinuteInData = Math.floor(position * totalUnit / (width - 1));
    const positionInMinuteInThreshold = Math.floor(position * totalUnit / (width - 1)) + start;

    // calc data
    let cursorBr = dataBr[positionInMinuteInData] ? dataBr[positionInMinuteInData][1] : "--";
    let cursorHr = dataHr[positionInMinuteInData] ? dataHr[positionInMinuteInData][1] : "--";

    if (cursorBr === null) cursorBr = "--";
    if (cursorHr === null) cursorHr = "--";

    // calc threshold
    const keys_thresholds = Object.keys(config_history);
    const thresholdArray = keys_thresholds.map((key, idx) => {
        if (idx + 1 <= keys_thresholds.length - 1) {
            return {...config_history[key], start: key, end: keys_thresholds[idx + 1]};
        }
    });

    const foundThreshold = thresholdArray.find(th => {
        return th && Number(th.start - 720) <= positionInMinuteInThreshold && Number(th.end - 720) >= positionInMinuteInThreshold;
    });

    // update cursor date
    d3.select(".cursor-date").text(`${Math.floor(positionInMinuteInThreshold / 60) < 12 ? previousDate : currentDate} / ${formatZoomedTimeWithoutPos(positionInMinuteInThreshold)}`);

    // add text to cursor line
    const svg1 = d3.select("#svg-REPORT-0-canvas-1");
    svg1.selectAll(".cursor-text").remove();
    svg1
        .append("rect")
        .attr("width", 120)
        .attr("height", 50)
        .attr("class", "cursor-text")
        .attr("x", cursorPositionX + ((positionInMinuteInThreshold - start) > ((end - start) * 91 / 100) ? -125 : 5))
        .attr("y", 20)
        .attr("rx", 4)
        .attr("stroke", "rgb(20, 100, 246)")
        .attr("fill", "white");
    svg1
        .append("text")
        .text(Math.floor(positionInMinuteInThreshold / 60) < 12 ? previousDate : currentDate)
        .attr("class", "cursor-text")
        .attr("fill", "rgb(20, 100, 246)")
        .attr("x", cursorPositionX + ((positionInMinuteInThreshold - start) > ((end - start) * 91 / 100) ? -115 : 10))
        .attr("y", 40);

    svg1
        .append("text")
        .text(formatZoomedTimeWithoutPos(positionInMinuteInThreshold))
        .attr("class", "cursor-text")
        .attr("fill", "rgb(20, 100, 246)")
        .attr("x", cursorPositionX + ((positionInMinuteInThreshold - start) > ((end - start) * 91 / 100) ? -115 : 10))
        .attr("y", 65);

    // update to screen
    let respRed, prRed;
    if (cursorBr !== "--" && cursorBr > foundThreshold.rpm_high && foundThreshold.rpm_high !== 0) {
        respRed = true;
    } else if (cursorBr !== "--" && cursorBr < foundThreshold.rpm_low) {
        respRed = true;
    }
    d3.select(".value-resp").classed("highlight-red", respRed).text(cursorBr);

    if (cursorHr !== "--" && cursorHr > foundThreshold.bpm_high) {
        prRed = true;
    } else if (cursorHr !== "--" && cursorHr < foundThreshold.bpm_low) {
        prRed = true;
    }
    d3.select(".value-pr").classed("highlight-red", prRed).text(cursorHr);

    // th
    if (foundThreshold) {
        d3.select(".th-resp .th-resp-value-hi")
            .classed("highlight", DefaultThreshold.rpm_high !== Number(foundThreshold.rpm_high))
            .text(foundThreshold.rpm_high);
        d3.select(".th-resp .th-resp-value-lo")
            .classed("highlight", DefaultThreshold.rpm_low !== Number(foundThreshold.rpm_low))
            .text(foundThreshold.rpm_low);
        d3.select(".th-pr .th-pr-value-hi")
            .classed("highlight", DefaultThreshold.bpm_high !== Number(foundThreshold.bpm_high))
            .text(foundThreshold.bpm_high);
        d3.select(".th-pr .th-pr-value-lo")
            .classed("highlight", DefaultThreshold.bpm_low !== Number(foundThreshold.bpm_low))
            .text(foundThreshold.bpm_low);
    }

    // eol
    // d3.select(".th-eol-value")
    //     .classed("highlight", DefaultThreshold.end_of_life_enabled !== foundThreshold.end_of_life_enabled)
    //     .text(foundThreshold.end_of_life_enabled ? "Enabled" : "Disabled");
    // d3.select(".th-eol-sensitivity")
    //     .classed("highlight", DefaultThreshold.awake_sens !== Number(foundThreshold.awake_sens))
    //     .text(foundThreshold.awake_sens);

    // op
    // d3.select(".th-op-value")
    //     .classed("highlight", DefaultThreshold.off_bed_enabled !== foundThreshold.off_bed_enabled)
    //     .text(foundThreshold.off_bed_enabled ? "Enabled" : "Disabled");
    // d3.select(".th-op-delay")
    //     .classed("highlight", DefaultThreshold.off_bed_delay !== Number(foundThreshold.off_bed_delay))
    //     .text(foundThreshold.off_bed_delay);
    // d3.select(".th-op-from")
    //     .classed("highlight", DefaultThreshold.off_bed_from_hour !== Number(foundThreshold.off_bed_from_hour) || DefaultThreshold.off_bed_from_minute !== Number(foundThreshold.off_bed_from_minute))
    //     .text(`${formatTime(foundThreshold.off_bed_from_hour)}:${formatTime(foundThreshold.off_bed_from_minute)}`);
    // d3.select(".th-op-to")
    //     .classed("highlight", DefaultThreshold.off_bed_to_hour !== Number(foundThreshold.off_bed_to_hour) || DefaultThreshold.off_bed_to_minute !== Number(foundThreshold.off_bed_to_minute))
    //     .text(`${formatTime(foundThreshold.off_bed_to_hour)}:${formatTime(foundThreshold.off_bed_to_minute)}`);
}

function drawGrid(ctx, width, height, xMargin) {
    ctx.fillStyle = "rgba(0, 0, 0, 1)";
    ctx.strokeStyle = "rgba(0, 0, 0, 0.1)";
    ctx.font = "10px Noto, sans-serif";

    // for (let i = 1; i <= 10; i++) {
    //     ctx.beginPath();
    //     ctx.moveTo(posX(0, xMargin), height * i / 10);
    //     ctx.lineTo(posX(width, xMargin), height * i / 10);
    //     ctx.closePath();
    //     ctx.stroke();
    // }
}

function drawXAxis(id, zoomed = 0, translatedX = 0) {
    // time xaxis
    const svg = d3.select(`#svg-${id}`);

    // clear
    svg.selectAll(".svg-text-time").remove();

    for (let i = 0; i <= 24; i++) {
        // if(i> 0 && i < 24) {
        //     svg
        //     .append("line")
        //     .attr('stroke', '#9c9c9c')
        //     .style("stroke-dasharray", ("3, 2"))
        //     .style("stroke-width", 1)
        //     .style('shape-rendering','crispEdges')
        //     .attr('x1', function(d){ return 1780/24 * i})
        //     .attr('y1', function(d){ return 0})
        //     .attr('x2', function(d){ return 1780/24 * i})
        //     .attr('y2', function(d){ return 180});
        // }

        // text
        const hr0 = 0;
        const hr = 20;
        const hr24 = 60;
        if (i === 0 || i % 3 === 0) {
            let x = hr;
            if (i === 0) {
                x = hr0;
            } else if (i === 24) {
                x = hr24;
            }
            svg
                .append("text")
                .attr("class", "svg-text-time")
                .text(formatZoomedTime(i, zoomed, translatedX))
                .attr("x", 1780 / 24 * i - x)
                .attr("y", 308)
        }
    }
}

function drawThreshold(
    id,
    ctx, width, height, xMargin,
    thresholds = {},
    text = {high: NAMES.RESP_HIGH, low: NAMES.RESP_LOW},
    fields,
    totalHours = 24, translatedX = 0,
) {
    // clear old text
    const keys_threshold = Object.keys(thresholds);
    const svg = d3.select(`#svg-${id}`);
    svg.selectAll(".svg-text-th").remove();
    svg.selectAll(".myClip").remove();

    const clipPath = svg.append("clipPath")
        .attr("id", `myClip-${id}`)
        .attr("class", `myClip`);

    if (keys_threshold.length === 1) {
        const threshold = thresholds[keys_threshold[0]];
        const high = threshold[fields.high];
        const low = threshold[fields.low];
        const max = fields.max;
        const min = fields.min;
        const maxUnit = fields.max - fields.min;

        // threshold area
        ctx.fillStyle = "rgba(100, 100, 100, 0.1)";

        if (high !== 0) {
            ctx.fillRect(posX(0, xMargin), 0, width, height / maxUnit * (max - high));
            ctx.fillRect(posX(0, xMargin), height - height / maxUnit * (low - min), width, height / maxUnit * (low - min));
            drawThresholdByD3(id, width, height, 0, low, high, maxUnit, fields.max, text, true, true);

            // clippath
            // clipPath.append("rect")
            //     .attr("x", posX(0, xMargin))
            //     .attr("y", 0)
            //     .attr("width", width)
            //     .attr("height", height / maxUnit * (max - high))
            //
            // clipPath.append("rect")
            //     .attr("x", posX(0, xMargin))
            //     .attr("y", height - height / maxUnit * (low - min))
            //     .attr("width", width)
            //     .attr("height", height / maxUnit * (low - min))
        } else {
            ctx.fillRect(posX(0, xMargin), height - height / maxUnit * (low - min), width, height / maxUnit * (low - min));
            drawThresholdByD3(id, width, height, 0, low, high, maxUnit, fields.max, text, true, false);

            // clippath
            // clipPath.append("rect")
            //     .attr("x", posX(0, xMargin))
            //     .attr("y", height - height / maxUnit * (low - min))
            //     .attr("width", width)
            //     .attr("height", height / maxUnit * (low - min))
        }

    } else if (keys_threshold.length > 1) {
        const thresholdArray = keys_threshold.map((key, idx) => {
            if (idx + 1 <= keys_threshold.length - 1) {
                return {...thresholds[key], start: Number(key), end: Number(keys_threshold[idx + 1])};
            }
            return {...thresholds[key], start: Number(key), end: 2160};
        });

        let prevHigh, prevLow;
        thresholdArray.forEach((threshold, idx) => {
            // if(idx === thresholdArray.length - 1) return;

            const high = Number(threshold[fields.high]);
            const low = Number(threshold[fields.low]);
            const max = fields.max;
            const min = fields.min;
            const maxUnit = fields.maxUnit;
            const start = Number(threshold.start - 720) + Math.floor(translatedX / 60);
            const end = Number(threshold.end - 720) + Math.floor(translatedX / 60);
            const thresholdUnit = width / (totalHours * 60);

            ctx.fillStyle = "rgba(100, 100, 100, 0.1)";
            if (high !== 0) {
                ctx.fillRect(thresholdUnit * start, 0, thresholdUnit * (end - start), height / maxUnit * (max - high));
                // clippath
                clipPath.append("rect")
                    .attr("x", thresholdUnit * start)
                    .attr("y", 0)
                    .attr("width", thresholdUnit * (end - start))
                    .attr("height", height / maxUnit * (max - high) - 2);
            }
            ctx.fillRect(thresholdUnit * start, height - height / maxUnit * (low - min), thresholdUnit * (end - start), height / maxUnit * (low - min));

            // clippath
            clipPath.append("rect")
                .attr("x", thresholdUnit * start)
                .attr("y", height - height / maxUnit * (low - min) + 4)
                .attr("width", thresholdUnit * (end - start))
                .attr("height", height / maxUnit * (low - min) + 4);

            // threshold text
            // ctx.fillStyle = "rgba(0,0,0,0.9)";
            // ctx.font = "italic 8px Noto, sans-serif";
            if (prevHigh !== high && high !== 0) {
                // ctx.fillText(`${idx === 0 ? text.high : ""} > (${high})`, width / 1440 * start, height / maxUnit * (max - (high > 90 ? 90 : high)));
                drawThresholdByD3(id, width, height, thresholdUnit * start, low, high, maxUnit, fields.max, idx === 0 ? text : {
                    low: "",
                    high: ""
                }, false, true, min);
            }

            if (prevLow !== low) {
                // ctx.fillText(`${idx === 0 ? text.low : ""} < (${low})`, width / 1440 * start, height - height / maxUnit * ((low < 10 ? 10 : low) - min) + 2);
                drawThresholdByD3(id, width, height, thresholdUnit * start, low, high, maxUnit, fields.max, idx === 0 ? text : {
                    low: "",
                    high: ""
                }, true, false, min);
            }

            prevLow = low;
            prevHigh = high;
        });
    }
}

function drawThresholdPrevious(
    id,
    ctx, width, height, xMargin,
    thresholds = {},
    text = {high: NAMES.RESP_HIGH, low: NAMES.RESP_LOW},
    fields,
    totalHours = 24, translatedX = 0,
) {
    // clear old text
    const keys_threshold = Object.keys(thresholds);
    // const svg = d3.select(`#svg-${id}`);
    // svg.selectAll(".svg-text-th").remove();
    // svg.selectAll(".myClip").remove();
    //
    // const clipPath = svg.append("clipPath")
    //     .attr("id", `myClip-${id}`)
    //     .attr("class", `myClip`);

    if (keys_threshold.length === 1) {
        const threshold = thresholds[keys_threshold[0]];
        const high = threshold[fields.high];
        const low = threshold[fields.low];
        const max = fields.max;
        const min = fields.min;
        const maxUnit = fields.max - fields.min;

        // threshold area
        ctx.fillStyle = "rgba(100, 100, 100, 0.1)";

        if (high !== 0) {
            ctx.fillRect(posX(0, xMargin), 0, width, height / maxUnit * (max - high));
            ctx.fillRect(posX(0, xMargin), height - height / maxUnit * (low - min), width, height / maxUnit * (low - min));
            drawThresholdByD3(id, width, height, 0, low, high, maxUnit, fields.max, text, true, true);

            // clippath
            // clipPath.append("rect")
            //     .attr("x", posX(0, xMargin))
            //     .attr("y", 0)
            //     .attr("width", width)
            //     .attr("height", height / maxUnit * (max - high))
            //
            // clipPath.append("rect")
            //     .attr("x", posX(0, xMargin))
            //     .attr("y", height - height / maxUnit * (low - min))
            //     .attr("width", width)
            //     .attr("height", height / maxUnit * (low - min))
        } else {
            ctx.fillRect(posX(0, xMargin), height - height / maxUnit * (low - min), width, height / maxUnit * (low - min));
            drawThresholdByD3(id, width, height, 0, low, high, maxUnit, fields.max, text, true, false);

            // clippath
            // clipPath.append("rect")
            //     .attr("x", posX(0, xMargin))
            //     .attr("y", height - height / maxUnit * (low - min))
            //     .attr("width", width)
            //     .attr("height", height / maxUnit * (low - min))
        }

    } else if (keys_threshold.length > 1) {
        const thresholdArray = keys_threshold.map((key, idx) => {
            if (idx + 1 <= keys_threshold.length - 1) {
                return {...thresholds[key], start: Number(key), end: Number(keys_threshold[idx + 1])};
            }
            return {...thresholds[key], start: Number(key), end: 1440};
        });

        let prevHigh, prevLow;
        thresholdArray.forEach((threshold, idx) => {
            // if(idx === thresholdArray.length - 1) return;

            const high = Number(threshold[fields.high]);
            const low = Number(threshold[fields.low]);
            const max = fields.max;
            const min = fields.min;
            const maxUnit = fields.maxUnit;
            const start = Number(threshold.start - 720) + Math.floor(translatedX / 60);
            const end = Number(threshold.end - 720) + Math.floor(translatedX / 60);
            const thresholdUnit = width / (totalHours * 60);

            ctx.fillStyle = "rgba(100, 100, 100, 0.1)";
            if (high !== 0) {
                ctx.fillRect(thresholdUnit * start, 0, thresholdUnit * (end - start), height / maxUnit * (max - high));
                // clippath
                // clipPath.append("rect")
                //     .attr("x", thresholdUnit * start)
                //     .attr("y", 0)
                //     .attr("width", thresholdUnit * (end - start))
                //     .attr("height", height / maxUnit * (max - high) - 2);
            }
            ctx.fillRect(thresholdUnit * start, height - height / maxUnit * (low - min), thresholdUnit * (end - start), height / maxUnit * (low - min));

            // clippath
            // clipPath.append("rect")
            //     .attr("x", thresholdUnit * start)
            //     .attr("y", height - height / maxUnit * (low - min) + 4)
            //     .attr("width", thresholdUnit * (end - start))
            //     .attr("height", height / maxUnit * (low - min) + 4);

            // threshold text
            // ctx.fillStyle = "rgba(0,0,0,0.9)";
            // ctx.font = "italic 8px Noto, sans-serif";
            if (prevHigh !== high && high !== 0) {
                // ctx.fillText(`${idx === 0 ? text.high : ""} > (${high})`, width / 1440 * start, height / maxUnit * (max - (high > 90 ? 90 : high)));
                drawThresholdByD3(id, width, height, thresholdUnit * start, low, high, maxUnit, fields.max, idx === 0 ? text : {
                    low: "",
                    high: ""
                }, false, true, min);
            }

            if (prevLow !== low) {
                // ctx.fillText(`${idx === 0 ? text.low : ""} < (${low})`, width / 1440 * start, height - height / maxUnit * ((low < 10 ? 10 : low) - min) + 2);
                drawThresholdByD3(id, width, height, thresholdUnit * start, low, high, maxUnit, fields.max, idx === 0 ? text : {
                    low: "",
                    high: ""
                }, true, false, min);
            }

            prevLow = low;
            prevHigh = high;
        });
    }
}

function drawEvent(ctx, width, height, xMargin, events, totalHours = 24, translatedX = 0) {
    // draw area
    const unitSize = width / (totalHours * 86400 / 24);
    events.map(e => {
        const x = (e.startTime + translatedX) * unitSize;
        let duration = Math.floor(e.duration * unitSize)  + 0.5;

        if (duration < 1) {
            duration = 1;
        }

        // fill events
        ctx.fillStyle = COLOR[e.type];
        ctx.fillRect(posX(x, xMargin), 0, duration, height);
    });
}

// function drawData(ctx, width, height, xMargin) {
//     drawLineChartByD3(data)
// }

function drawThresholdByD3(id, width, height, start, low, high, maxUnit, max, text, isDrawLow, isDrawHigh, min) {
    const svg = d3.select(`#svg-${id}`);

    if (isDrawLow && low >= min) {
        svg
            .append("text")
            .attr("class", "svg-text-th")
            .style("fill", "#aaa")
            .text(`${low}`)
            .attr("x", start)
            .attr("y", height / maxUnit * (max - low + 1));
    }

    if (isDrawHigh && max !== Infinity) {
        svg
            .append("text")
            .attr("class", "svg-text-th")
            .style("fill", "#aaa")
            .text(`${high}`)
            .attr("x", start)
            .attr("y", height / maxUnit * (max - high - 1));
    }
}

function drawLineChartByD3(svgId, dataRaw, options = {
    top: 0,
    min: 0,
    max: 100
}, totalHours = 24, translatedX = 0, ruler, updateThreshold, width, height) {
    const totalUnit = Math.floor(1440 * totalHours / 24);
    const start = Math.floor(0 - translatedX / 60);
    const end = start + totalUnit;

    const data = [...dataRaw].slice(start, end).map((d, idx) => [idx, d]) || [];

    let displayMin = Math.min(...data.filter(d => d[1] !== null && d[1] !== undefined).map(d => d[1]));
    let displayMax = Math.max(...data.filter(d => d[1] !== null && d[1] !== undefined).map(d => d[1]));

    if (totalHours === 24) {
        displayMin = 0;
    } else if (displayMin - 1 < 0) {
        displayMin = 0;
    } else {
        displayMin = displayMin - 1;
    }

    if (totalHours === 24) {
        displayMax = options.max;
    }
    if (displayMax + 1 > options.max) {
        displayMax = options.max;
    } else {
        displayMax = displayMax + 1;
    }

    if (svgId.indexOf("canvas-1") > -1) {
        // update threshold
        updateThreshold(displayMax !== -Infinity ? displayMax : 100, displayMin !== Infinity ? displayMin : 0);
        // update ruler
        updateRuler(displayMin, displayMax, ruler, 1);
    } else {
        // update threshold
        updateThreshold(displayMax !== -Infinity ? displayMax : 200, displayMin !== Infinity ? displayMin : 0);
        // update ruler
        updateRuler(displayMin, displayMax, ruler, 2);
    }

    const scaleY = d3.scaleLinear()
    // .domain([Math.min(...data.map(d => d[1])), Math.max(...data.map(d => d[1]))])
        .domain([displayMin, displayMax])
        // .range([Math.max(...data.map(d => d[1]))], Math.min(...data.map(d => d[1])));
        .range([height, 0]);
    const scaleX = d3.scaleLinear()
        .domain([0, totalUnit])
        .range([0, width]);

    // let nCharts = Object.keys(FILTER_NAME_MAP_IDS).filter(k => FILTER_NAME_MAP_IDS[k]).length;
    let nCharts = 1;
    d3.selectAll(`#svg-${svgId} .wave`).remove();
    d3.selectAll(`#svg-${svgId} .dot`).remove();
    const graph = d3.select(`#svg-${svgId}`)
        .attr("width", "100%")
        .style("position", "absolute")
        // .style("top", `${options.top / 20 * 100/nCharts}%`)
        // .style("left", "0")
        .style("height", `${100 / nCharts}%`);

    // add wave black
    // graph.append("path")
    //     .attr("class", "wave")
    //     // .style("top", "0")
    //     // .style("left", "0")
    //     // .style("position", "relative")
    //     .datum(data)
    //     .attr("fill", "none")
    //     .attr("stroke", "rgba(0,0,0,1)")
    //     .attr("stroke-width", 3)
    //     .attr("d", d3.line()
    //         .x(function (d) {
    //             return scaleX(d[0])
    //         })
    //         .y(function (d) {
    //             return scaleY(d[1])
    //         })
    //         .defined(function (d) {
    //             return d[1] !== null && d[1] !== -1;
    //         }) // Omit empty values.
    //     );

    // add wave red
    // graph.append("path")
    //     .attr("class", "wave")
    //     // .style("top", "0")
    //     // .style("left", "0")
    //     // .style("position", "relative")
    //     .datum(data)
    //     .attr("fill", "none")
    //     .attr("stroke", "#e61610")
    //     .attr("stroke-width", 2)
    //     .attr("clip-path", `url(#myClip-${svgId})`)
    //     .attr("d", d3.line()
    //         .x(function (d) {
    //             return scaleX(d[0])
    //         })
    //         .y(function (d) {
    //             return scaleY(d[1])
    //         })
    //         .defined(function (d) {
    //             return d[1] !== null && d[1] !== -1;
    //         }) // Omit empty values.
    //     );
    //

    let r = 1.5;
    if(totalHours >= 20 && totalHours < 24) {
        r = 1.8;
    } else if (totalHours >= 15 && totalHours < 20) {
        r = 2.2;
    } else if (totalHours >= 10 && totalHours < 15) {
        r = 2.8;
    } else if (totalHours < 10) {
        r = 3;
    }

    // // Add the circle black
    graph.selectAll(`circle.black`)
        .data(data.filter((d, idx) => {
            // if (idx > 0 && idx < data.length - 1) {
            //     return !data[idx - 1][1] && !data[idx + 1][1] && d[1] && d[1] !== -1;
            // }
            return d[1] !== null && d[1] !== -1;
        }))
        .enter()
        .append("circle")
        .attr("class", "dot black")
        .attr("fill", "black")
        .attr("stroke", "none")
        .attr("cx", function (d) {
            return scaleX(d[0])
        })
        .attr("cy", function (d) {
            return scaleY(d[1])
        })
        .attr("r", r);

    // Add the circle red
    graph.selectAll(`circle.red`)
        .data(data.filter((d, idx) => {
            // if (idx > 0 && idx < data.length - 1) {
            //     return !data[idx - 1][1] && !data[idx + 1][1] && d[1] && d[1] !== -1;
            // }
            return d[1] !== null && d[1] !== -1;
        }))
        .enter()
        .append("circle")
        .attr("class", "dot red")
        .attr("fill", "#e61610")
        .attr("stroke", "none")
        .attr("clip-path", `url(#myClip-${svgId})`)
        .attr("cx", function (d) {
            return scaleX(d[0])
        })
        .attr("cy", function (d) {
            return scaleY(d[1])
        })
        .attr("r", r);
}

function posX(x, xMargin) {
    return xMargin + x;
}

function updateRuler(min, max, ruler, type) {
    ruler.selectAll("div").remove();
    if (min === undefined || max === undefined || min === Infinity || max === Infinity) {
        if (type === 1) {
            ruler.append("div").attr("class", "canvas-ruler-unit").text("100");
            ruler.append("div").attr("class", "canvas-ruler-unit").text("75");
            ruler.append("div").attr("class", "canvas-ruler-unit").text("50");
            ruler.append("div").attr("class", "canvas-ruler-unit").text("25");
            ruler.append("div").attr("class", "canvas-ruler-unit").text("0");
        } else {
            ruler.append("div").attr("class", "canvas-ruler-unit").text("200");
            ruler.append("div").attr("class", "canvas-ruler-unit").text("150");
            ruler.append("div").attr("class", "canvas-ruler-unit").text("100");
            ruler.append("div").attr("class", "canvas-ruler-unit").text("50");
            ruler.append("div").attr("class", "canvas-ruler-unit").text("0");
        }
    } else {
        let number1 = (min + ((max - min) / 100 * 75));
        let number2 = (min + ((max - min) / 100 * 50));
        let number3 = (min + ((max - min) / 100 * 25));
        let text1, text2, text3;
        if (number1 * 10 % 10 !== 0) {
            text1 = number1.toFixed(1);
        } else {
            text1 = number1;
        }
        if (number2 * 10 % 10 !== 0) {
            text2 = number2.toFixed(1);
        } else {
            text2 = number2;
        }
        if (number3 * 10 % 10 !== 0) {
            text3 = number3.toFixed(1);
        } else {
            text3 = number3;
        }

        ruler.append("div").attr("class", "canvas-ruler-unit").text(`${max}`);
        ruler.append("div").attr("class", "canvas-ruler-unit").text(`${text1}`);
        ruler.append("div").attr("class", "canvas-ruler-unit").text(`${text2}`);
        ruler.append("div").attr("class", "canvas-ruler-unit").text(`${text3}`);
        ruler.append("div").attr("class", "canvas-ruler-unit").text(`${min}`);
    }
}

function formatZoomedTime(pos, zoomed, translatedX) {
    let totalUnit = 24 + zoomed;

    if (totalUnit <= 0) {
        totalUnit = 1;
    }

    let start = 0;

    if (pos !== 1) {
        start = pos;
    }

    // // translated
    // const translatedHour = Math.floor(translatedX / 60);
    //
    // const translatedMin = translatedX - translatedHour * 60;
    // calculate
    const unitInMinute = totalUnit * 60 / 24;

    const startInMinute = Math.floor(unitInMinute * start - Math.floor(translatedX / 60));

    let hour = Math.floor(startInMinute / 60);

    let minute = startInMinute - hour * 60;

    if (hour < 0) {
        hour = 0;
        minute = 0;
    } else if (hour >= 24) {
        hour = 23;
        minute = 59;
    }

    let hourPlus12 = (hour + 12) % 24;

    return `${hourPlus12 <= 9 ? "0" + hourPlus12 : hourPlus12}:${minute <= 9 ? "0" + minute : minute}`;
}

function formatZoomedTimeWithoutPos(startInMinute) {
    let hour = Math.floor(startInMinute / 60);

    let minute = startInMinute - hour * 60;

    if (hour < 0) {
        hour = 0;
        minute = 0;
    } else if (hour >= 24) {
        hour = 23;
        minute = 59;
    }

    let hourPlus12 = (hour + 12) % 24;

    return `${hourPlus12 <= 9 ? "0" + hourPlus12 : hourPlus12}:${minute <= 9 ? "0" + minute : minute}`;
}

function formatTime(num) {
    return `${num <= 9 ? "0" + num : num}`;
}

function formatMinuteToHour(num) {
    const hour = Math.floor(num / 3600);
    const minute = (num - hour * 3600) / 60;
    return `${hour <= 9 ? "0" + hour : hour}:${minute <= 9 ? "0" + minute : minute}`;
}