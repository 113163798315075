import {GET_BEDS, UPDATE_BED} from "../actions/thresholdAction";

const initialState = {};

const thresholdReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BEDS:
            const {beds, patients} = action.payload;

            return {
                ...state,
                beds: beds.map(b => {
                    const found = patients.find(p => p.id === b.patient_id);
                    return {...b, patient_name: found?.name};
                })
            };

        case UPDATE_BED:
            const {updated, found} = action.payload;
            const currentBeds = [...state.beds];
            const findIdx = currentBeds.findIndex(b => b.id === updated?.friendly_name);
            currentBeds[findIdx] = {...currentBeds[findIdx], config: updated, last_update: found.last_update};

            return {
                ...state,
                beds: currentBeds
            };
        default:
            return state
    }
};

export default thresholdReducer;