import {connect} from 'react-redux'
import {ShowQRCode} from "./ShowQRCode";
import {showOspMgrQRCodeAction} from "../../../redux/actions/osp-mgr/showOspMgrQRCodeAction";

const mapStateToProps = state => ({
    listOfNurses: state.showOspMgrQrCodeReducer.listOfNurses || [],
    qrData: state.showOspMgrQrCodeReducer.qrData,
});

function mapDispatchToProps(dispatch) {
    return ({
        getListOfNurses: () => dispatch(showOspMgrQRCodeAction.getListOfNurses()),
        getQRData: (nurse) => dispatch(showOspMgrQRCodeAction.getQRData(nurse)),
        getTechQRCode: () => dispatch(showOspMgrQRCodeAction.getTechQRCode()),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowQRCode);