import {CHANGE_LANGUAGE} from "../actions/languageAction";
import {Configs} from "../../constants/configs";

let locale = "ja";

if (localStorage.getItem("OMNI2_PRIORITY_LANGUAGE") && localStorage.getItem("OMNI2_PRIORITY_LANGUAGE") !== "") {
    locale = localStorage.getItem("OMNI2_PRIORITY_LANGUAGE");
} else if (Configs.LANGUAGE && Configs.LANGUAGE !== "") {
    locale = Configs.LANGUAGE;
}

const initialState = {
    locale: locale,
    // languageOptions: [
    //     {value: "en", label: "langEng"},
    //     {value: "zh-Hans", label: "langChiSim"},
    //     {value: "zh-Hant", label: "langChiTra"},
    // ],
};

const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_LANGUAGE:
            if (action.payload !== localStorage.getItem("OMNI2_PRIORITY_LANGUAGE")) {
                localStorage.setItem("OMNI2_PRIORITY_LANGUAGE", action.payload);
                window.location.reload();
            }

            return state;
        default:
            return state
    }
};

export default languageReducer;