import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';
import {createBrowserHistory} from "history";
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction';

export const history = createBrowserHistory();

export const store = createStore(
    rootReducer(),
    {},
    composeWithDevTools(applyMiddleware(thunk)),
);