import {dataProvider} from "../../../connections/net";

export const SET_ERROR = "showOspMgrQRCode/action/SET_ERROR";
export const SET_STATE = "showOspMgrQRCode/action/SET_STATE";
export const GET_NURSES = "showOspMgrQRCode/action/GET_DATA";
export const GET_QR_DATA = "showOspMgrQRCode/action/GET_QR_DATA";
export const GET_TECH_QR_DATA = "showOspMgrQRCode/action/GET_QR_DATA";
export const CLEAR_DATA = "showOspMgrQRCode/action/CLEAR_DATA";

export const showOspMgrQRCodeAction = {
    getListOfNurses: () => async dispatch => {
        try {
            const {status_code, oah} = await dataProvider.getListOfNurses();
            if (status_code === 0 && oah) {
                dispatch({type: GET_NURSES, payload: oah});
            }
        } catch(e) {
            console.log("Error:", e.message);
        }
    },
    getQRData: (nurse) => async dispatch => {
        try {
            const {status_code, qr_code} = await dataProvider.getQRData(nurse);
            if (status_code === 0 && qr_code) {
                dispatch({type: GET_QR_DATA, payload: qr_code});
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
    },
    getTechQRCode: () => async dispatch => {
        try {
            const {status_code, qr_code} = await dataProvider.getTechQRCode();
            if (status_code === 0 && qr_code) {
                dispatch({type: GET_QR_DATA, payload: qr_code});
            }
        } catch(e) {
            console.log("Error:", e.message);
        }
    },
    setError: (error) => dispatch => dispatch({type: SET_ERROR, payload: error}),
    setState: (state) => dispatch => dispatch({type: SET_STATE, payload: state}),
    clearData: () => dispatch => dispatch({type: CLEAR_DATA})
};