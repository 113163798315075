export const Configs = {
    // en = English, jp = Japanese
    LANGUAGE: "ja",
    // false = No include report feature, true = Include report feature
    // HAS_REPORT: true,
    // for disconnection
    NUMBER_OF_RETRIES: 30, // 30 times: number of times to attempt connect to server
    RETRY_INTERVAL: 2, // 2 seconds: delay between 2 retry
    TIME_DELAY_BETWEEN_CONNECTION_RETRIES: 0, // 0 second: wait retry after X*Y
    OAC_APK_LINK: "https://oacpublic.s3.ap-northeast-1.amazonaws.com/OAC-DEV.apk", // link download app,
    POLICY_VERSION: "1.0.0"

    // APP_TYPE: 2, // 1 = iRESP 1.0, 2 = iRESP 2.0
};