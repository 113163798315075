import {
    DO_LOGIN_SUCCESS,
    DO_LOGIN_ERROR,
    GET_COUNTRY_CODE,
    CHANGE_PASSWORD,
    SET_ERROR,
    CLEAR_DATA, DUPLICATE_LOGIN_RESPONSE, DO_RESET_PASSWORD
} from "../actions/loginAction";

const initialState = {};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case DO_LOGIN_SUCCESS:
            return Object.assign({}, state, {isLogin: true, error: undefined,});
        case DO_LOGIN_ERROR:
            return Object.assign({}, state, {error: action.payload});
        case CHANGE_PASSWORD:
            return Object.assign({}, state, {...action.payload});
        case SET_ERROR:
            return Object.assign({}, state, {error: action.payload});
        case CLEAR_DATA:
            return initialState;
        case GET_COUNTRY_CODE:
            return Object.assign({}, state, {country_code: action.payload.country_code});
        case DUPLICATE_LOGIN_RESPONSE:
            return Object.assign({}, state, {isDuplicatedLogin: action.payload});
        case DO_RESET_PASSWORD:
            const {role, email} = action.payload;
            return Object.assign({}, state, {role, email});
        default:
            return state
    }
};

export default loginReducer;