import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import Layout from "./components/layout";
import Login from "./components/login";

import ShowQRCode from "./components/n-admin/show-qr-code";
import ManageAccount from "./components/n-admin/manage-account";
import CCAdminChangePw from "./components/cc-admin-change-pw";
import BedAssignment from "./components/n-admin/bed-assignment";
import EnrollPatient from "./components/n-admin/enroll-patient";
import PatientTransfer from "./components/n-admin/patient-transfer";
import DischargeHistory from "./components/n-admin/discharge-history";
import Thresholds from "./components/n-admin/thresholds";
import PatientReport from "./components/n-admin/patient-report";
import LiveTile from "./components/n-admin/live-tile";
import OspMgrQRCode from "./components/osp-mgr/show-qr-code";
import CUBinding from "./components/osp-mgr/cu-binding";
import ResetPassword from "./components/osp-mgr/change-password";
import NotFound from "./components/not-found";
import ResetForm from "./components/reset";
import DownloadAppQR from "./components/osp-mgr/show-qr-code-download-app";

import {ROLES} from "./constants/roles";
import './App.css';
import './media.css';

function App(props) {

    const renderCCAdminRoutes = () => {
        return <>
            <Route path="/common-change-password" name="Change Pw" component={CCAdminChangePw}/>
            <Route path="/cc-admin-dashboard" name="Dashboard" component={BedAssignment}/>
            <Route path="/cc-admin-show-qrcode" name="Show QRCode" component={ShowQRCode}/>
            <Route path="/cc-admin-operation-enroll" name="Enroll Resident" component={EnrollPatient}/>
            <Route path="/cc-admin-operation-transfer" name="Transfer Resident" component={PatientTransfer}/>
            <Route path="/cc-admin-operation-discharge-history" name="Discharge History" component={DischargeHistory}/>
            <Route path="/cc-admin-operation-thresholds" name="Thresholds" component={Thresholds}/>
            <Route path="/cc-admin-patient-report" name="Report" component={PatientReport}/>
            {/*<Route path="/cc-admin-live-tile" name="Live tile" component={LiveTile}/>*/}
            <Route path="/osp-mgr-reset-password" name="ResetPassword" component={ResetPassword}/>
            <Route path="/cc-admin-qrcode-download-app" name="Download App" component={DownloadAppQR}/>
        </>
    };

    const renderOSPMgrRoutes = () => {
        return <>
            {/*<Route exact path="/" name="Dashboard" component={BedAssignment}/>*/}
            <Route path="/common-change-password" name="Change Pw" component={CCAdminChangePw}/>
            <Route path="/cc-admin-dashboard" name="Dashboard" component={BedAssignment}/>
            <Route path="/cc-admin-manage-account" name="Manage Account" component={ManageAccount}/>
            {/*<Route path="/cc-admin-live-tile" name="LiveTile" component={LiveTile}/>*/}
            <Route path="/cc-admin-patient-report" name="Report" component={PatientReport}/>

            <Route path="/osp-mgr-show-qrcode" name="OspMgrQRCode" component={OspMgrQRCode}/>
            {/*<Route path="/osp-mgr-setup-wifi" name="WifiSetup" component={WifiSetup}/>*/}
            <Route path="/osp-mgr-setup-cu" name="CUBinding" component={CUBinding}/>
            <Route path="/osp-mgr-reset-password" name="ResetPassword" component={ResetPassword}/>
            <Route path="/osp-mgr-qrcode-download-app" name="Download App" component={DownloadAppQR}/>
        </>
    };

    const role = sessionStorage.getItem("OMNI2_ROLE");

    return (
        <Layout>
            <Switch>
                <Route path="/login" component={Login}/>
                <Route exact path="/reset-password" name="ResetPassword" component={ResetForm}/>
                <Route exact path="/cc-admin-live-tile" name="Live tile" component={LiveTile}/>
                {
                    !sessionStorage.getItem(`${role}_token`)
                        ? <Redirect exact from="/" to="/login"/>
                        : undefined
                }
                {
                    sessionStorage.getItem(`${role}_token`)
                    || role === ROLES.NurseAdmin
                    || role === ROLES.OspMgr
                        ? <Redirect exact from="/" to="/cc-admin-dashboard"/>
                        : undefined
                }
                {
                    role === ROLES.NurseAdmin
                        ? renderCCAdminRoutes()
                        : undefined
                }
                {
                    role === ROLES.OspMgr
                        ? renderOSPMgrRoutes()
                        : undefined
                }
            </Switch>
            <Route path="/not-found" component={NotFound}/>
            <input id="myStateInput" className="d-none"/>
        </Layout>
    );
}

const mapStateToProps = state => ({
    isLogin: state.loginReducer.isLogin,
});

export default connect(mapStateToProps)(App);
