import {dataProvider} from "../../../connections/net";
import {appAction} from "../appAction";

export const SET_ERROR = "CUBinding/action/SET_ERROR";
export const CLEAR_ERROR = "CUBinding/action/CLEAR_ERROR";
export const SET_STATE = "CUBinding/action/SET_STATE";
export const GET_CU_LIST = "CUBinding/action/GET_CU_LIST";
export const CLEAR_DATA = "CUBinding/action/CLEAR_DATA";

export const cuBinding = {
    getListOfCU: () => async dispatch => {
        dispatch(appAction.openLoading());
        try {
            const {status_code, oah} = await dataProvider.getListOfCU();
            if (status_code === 0 && oah) {
                dispatch({type: GET_CU_LIST, payload: oah});
            }
        } catch(e) {
            console.log("Error:", e.message);
        }
        dispatch(appAction.closeLoading());
    },
    addCU: (info) => async dispatch => {
        dispatch(appAction.openLoading());
        try {
            const {status_code, error} = await dataProvider.addCU(info);
            if (status_code === 0) {
                setTimeout(() => {
                    dispatch(cuBinding.getListOfCU());
                }, 1000);
            } else {
                dispatch({type: SET_ERROR, payload: error});
                dispatch(appAction.closeLoading());
            }
        } catch(e) {
            console.log("Error:", e.message);
            dispatch(appAction.closeLoading());
        }
    },
    editCU: (info) => async dispatch => {
        dispatch(appAction.openLoading());
        try {
            const {status_code, error} = await dataProvider.editCU(info);
            if (status_code === 0) {
                setTimeout(() => {
                    dispatch(cuBinding.getListOfCU());
                }, 1000);
                dispatch({type: SET_ERROR, payload: undefined})
            } else {
                dispatch({type: SET_ERROR, payload: error});
                dispatch(appAction.closeLoading());
            }
        } catch(e) {
            console.log("Error:", e.message);
            dispatch(appAction.closeLoading());
        }
    },
    deleteCU: (info) => async dispatch => {
        dispatch(appAction.openLoading());
        try {
            const {status_code, error} = await dataProvider.deleteCU(info);
            if (status_code === 0) {
                setTimeout(() => {
                    dispatch(cuBinding.getListOfCU());
                }, 1000);
            } else {
                dispatch({type: SET_ERROR, payload: error});
                dispatch(appAction.closeLoading());
            }
        } catch(e) {
            console.log("Error:", e.message);
            dispatch(appAction.closeLoading());
        }
    },
    unbindCU: (info) =>  async dispatch => {
        dispatch(appAction.openLoading());
        try {
            const {status_code, error} = await dataProvider.unbindCU(info);
            if (status_code === 0) {
                setTimeout(() => {
                    dispatch(cuBinding.getListOfCU());
                }, 1000)
            } else {
                dispatch({type: SET_ERROR, payload: error});
                dispatch(appAction.closeLoading());
            }
        } catch(e) {
            console.log("Error:", e.message);
            dispatch(appAction.closeLoading());
        }
    },
    setError: (error) => dispatch => dispatch({type: SET_ERROR, payload: error}),
    setState: (state) => dispatch => dispatch({type: SET_STATE, payload: state}),
    clearData: () => dispatch => dispatch({type: CLEAR_DATA})
};