export const changeTheme = (theme) => {
    switch (theme) {
        case "red":
            document
                .documentElement
                .style
                .setProperty("--main-color", "#FE7069");
            break;
        case "green":
            document
                .documentElement
                .style
                .setProperty("--main-color", "#43CABD");
            break;
        default:
            document
                .documentElement
                .style
                .setProperty("--main-color", "#43CABD");
    }
};