import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import cx from "classnames";
import {messageWrapper as t} from "../../utils/message-wrapper";
import {ROLES} from "../../constants/roles";
import {changeTheme} from "../../utils/theme";
import {Versions} from "../../constants/versions";
import "./modal.css";
import {LiveViewBtn} from "./LiveTileBtn";

export function SideBar(props) {
    const location = useLocation();

    const [state, setState] = useState({
        isAlert: "false",
    });

    useEffect(() => {
        const interval = setInterval(() => {
            const isAlert = localStorage.getItem("OMNI2_ALERT");
            if (state.isAlert !== isAlert) {
                setState({...state, isAlert: isAlert});
            }
        }, 4000);

        return () => {
            clearInterval(interval);
        }

    }, [state.isAlert]);

    if (!props.isOpen) return <div/>;

    const renderAlertImage = () => {
        return <div className="alert-img" id="alert-btn">
            {
                state.isAlert === "true"
                    ? <LiveViewBtn />
                    : <LiveViewBtn isBlack={true}/>
            }
        </div>
    };

    const renderCCAdminMenu = () => {
        changeTheme("green");
        return <div className="sidebar-body">
            <div className={cx(
                "sidebar-item",
                "hover-white",
                props.hideLiveViewBtn ? "d-none": "",
                location.pathname.indexOf("/cc-admin-live-tile") > -1 ? "active" : ""
            )} onClick={props.clickedLiveView}>
                <Link className="hover-white" to="/cc-admin-live-tile">
                    <span className="sidebar-link-label">{renderAlertImage()}</span>
                </Link>
            </div>
            <div className={cx(
                "sidebar-item",
                location.pathname.indexOf("/cc-admin-dashboard") > -1 ? "active" : ""
            )}>
                <Link to="/cc-admin-dashboard">
                    <span className="sidebar-link-label">{t(`Dashboard`)}</span>
                </Link>
            </div>
            <div className={cx(
                "sidebar-item",
                location.pathname.indexOf("/cc-admin-show-qrcode") > -1 ? "active" : "",
                "line-height-1-3"
            )}>
                <Link to="/cc-admin-show-qrcode">
                    <span className="sidebar-link-label">{t(`Show Bed Group QR Code`)}</span>
                </Link>
            </div>
            <div className={cx(
                "sidebar-item",
                location.pathname.indexOf("/cc-admin-qrcode-download-app") > -1 ? "active" : "",
                "line-height-1-3"
            )}>
                <Link to="/cc-admin-qrcode-download-app">
                    <span className="sidebar-link-label">{t(`Show OAC APP QR Code`)}</span>
                </Link>
            </div>
            {/*<div className={cx(*/}
                {/*"sidebar-item",*/}
                {/*location.pathname.indexOf("/cc-admin-manage-account") > -1 ? "active" : ""*/}
            {/*)}>*/}
                {/*<Link to="/cc-admin-manage-account">*/}
                    {/*<span className="sidebar-link-label">{t(`Manage Account`)}</span>*/}
                {/*</Link>*/}
            {/*</div>*/}

            <div className="sidebar-group">
                <span className={cx(
                    "sidebar-link-label",
                    location.pathname.indexOf("/cc-admin-operation") > -1 ? "active" : "",
                    location.pathname.indexOf("/cc-admin-operation") > -1 ? "active" : ""
                )}>{t(`Operations`)}</span>
                <div className={cx(
                    "sidebar-item",
                    location.pathname.indexOf("/cc-admin-operation-enroll") > -1 ? "active" : "",
                    // props.isConfigurationSaved ? "" : "disabled"
                )}>
                    <Link to="/cc-admin-operation-enroll">
                        <span className="sidebar-link-label">{t(`Enroll Resident`)}</span>
                    </Link>
                </div>
                <div className={cx(
                    "sidebar-item",
                    location.pathname.indexOf("/cc-admin-operation-discharge-history") > -1 ? "active" : "",
                    // props.isConfigurationSaved ? "" : "disabled"
                )}>
                    <Link to="/cc-admin-operation-discharge-history">
                        <span className="sidebar-link-label">{t(`Discharge History`)}</span>
                    </Link>
                </div>
                <div className={cx(
                    "sidebar-item",
                    location.pathname.indexOf("/cc-admin-operation-transfer") > -1 ? "active" : "",
                    // props.isConfigurationSaved ? "" : "disabled"
                )}>
                    <Link to="/cc-admin-operation-transfer">
                        <span className="sidebar-link-label">{t(`Resident Operations`)}</span>
                    </Link>
                </div>
                <div className={cx(
                    "sidebar-item",
                    location.pathname.indexOf("/cc-admin-operation-threshold") > -1 ? "active" : "",
                    // props.isConfigurationSaved ? "" : "disabled"
                )}>
                    <Link to="/cc-admin-operation-thresholds">
                        <span className="sidebar-link-label">{t(`Thresholds`)}</span>
                    </Link>
                </div>
            </div>
        </div>
    };

    const renderOspMGrMenu = () => {
        changeTheme("green");
        return <div className="sidebar-body">
            <div className={cx(
                "sidebar-item",
                location.pathname.indexOf("/cc-admin-dashboard") > -1 ? "active" : ""
            )}>
                <Link to="/cc-admin-dashboard">
                    <span className="sidebar-link-label">{t(`Dashboard`)}</span>
                </Link>
            </div>
            <div className={cx(
                "sidebar-item",
                location.pathname.indexOf("/osp-mgr-show-qrcode") > -1 ? "active" : "",
                "line-height-1-3"
            )}>
                <Link to="/osp-mgr-show-qrcode">
                    <span className="sidebar-link-label">{t(`Show Installer Mode QR Code`)}</span>
                </Link>
            </div>
            <div className={cx(
                "sidebar-item",
                location.pathname.indexOf("/osp-mgr-qrcode-download-app") > -1 ? "active" : "",
                "line-height-1-3"
            )}>
                <Link to="/osp-mgr-qrcode-download-app">
                    <span className="sidebar-link-label">{t(`Show OAC APP QR Code`)}</span>
                </Link>
            </div>

            <div className="sidebar-group">
                <span className={cx(
                    "sidebar-link-label",
                    location.pathname.indexOf("/osp-mgr-setup") > -1 ? "active" : "",
                    location.pathname.indexOf("/cc-admin-manage") > -1 ? "active" : ""
                )}>{t(`Setup & Configuration`)}</span>
                <div className={cx(
                    "sidebar-item",
                    location.pathname.indexOf("/osp-mgr-setup-cu") > -1 ? "active" : "",
                    // props.isConfigurationSaved ? "" : "disabled"
                )}>
                    <Link to="/osp-mgr-setup-cu">
                        <span className="sidebar-link-label">{t(`Bed Management`)}</span>
                    </Link>
                </div>
                <div className={cx(
                    "sidebar-item",
                    location.pathname.indexOf("/cc-admin-manage-account") > -1 ? "active" : ""
                )}>
                    <Link to="/cc-admin-manage-account">
                        <span className="sidebar-link-label">{t(`Manage Bed Groups`)}</span>
                    </Link>
                </div>
            </div>
            {/*<div className="sidebar-group">*/}
                {/*<span className={cx(*/}
                    {/*"sidebar-link-label",*/}
                    {/*location.pathname.indexOf("/osp-mgr-administrative") > -1 ? "active" : "",*/}
                    {/*location.pathname.indexOf("/osp-mgr-administrative") > -1 ? "active" : ""*/}
                {/*)}>{t(`Administrative`)}</span>*/}
                {/*<div className={cx(*/}
                    {/*"sidebar-item",*/}
                    {/*location.pathname.indexOf("/osp-mgr-administrative-na") > -1 ? "active" : "",*/}
                    {/*// props.isConfigurationSaved ? "" : "disabled"*/}
                {/*)}>*/}
                    {/*<Link to="/osp-mgr-administrative-na">*/}
                        {/*<span className="sidebar-link-label">{t(`Nurse-Admin account`)}</span>*/}
                    {/*</Link>*/}
                {/*</div>*/}
            {/*</div>*/}
        </div>
    };

    return (
        <div id="sidebar" className={props.className}>
            {
                sessionStorage.getItem("OMNI2_ROLE") === ROLES.NurseAdmin ?
                    <div className="sidebar-header sidebar-header-left">{t(`NURSE-ADMIN`)}</div>
                    : null
            }
            {
                sessionStorage.getItem("OMNI2_ROLE") === ROLES.OspMgr ?
                    <div className="sidebar-header sidebar-header-left">{t(`SYS-ADMIN`)}</div>
                    : null
            }
            {
                sessionStorage.getItem("OMNI2_ROLE") === ROLES.NurseAdmin
                    ? renderCCAdminMenu()
                    : null
            }
            {
                sessionStorage.getItem("OMNI2_ROLE") === ROLES.OspMgr
                    ? renderOspMGrMenu()
                    : null
            }
            <div className="sidebar-footer">
                {t(`Version`)} {Versions.iresp_build}
            </div>
        </div>
    )
}