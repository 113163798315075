import React, {useEffect, useState} from "react";
import {useAuth} from "../../../connections/net";
import {messageWrapper as t} from "../../../utils/message-wrapper";
import {EspTable} from "../../common/EspTable";
import "./Manage.css";
import _ from "lodash";
import {IrespDropdown} from "../../common/IrespDropdown";
import RightArrowImg from "../../../images/Right_Arrow-Blue.png";
import LeftArrowImg from "../../../images/Left_Arrow-Blue.png";
import BidirectArrowImg from "../../../images/Bidirectional_arrow.png";
import {IrespModal} from "../../common/IrespModal";
import posed, {PoseGroup} from 'react-pose';
import {delayTime} from "../../../utils/delay";
import {compareOACBedName} from "../../../utils/sort";
import {useIntl} from "react-intl";
import {setZoom} from "../../../utils/set-zoom";

const TrLeft = posed.tr({
    enter: {opacity: 1},
    exit: {opacity: 0}
    // enter: {opacity: 1, y: 0, x: 0, applyAtEnd: {display: 'inherit'},},
    // exit: {
    //     opacity: 0,
    //     y: ({animate}) => animate ? 50 : 0,
    //     x: ({selectedItemId, id, animate}) => id === selectedItemId && animate ? "120%" : 0,
    //     applyAtEnd: {display: 'none'},
    // },
});

const TrRight = posed.tr({
    enter: {opacity: 1},
    exit: {opacity: 0}
    // enter: {opacity: 1, y: 0, x: 0, applyAtEnd: {display: 'inherit'},},
    // exit: {
    //     opacity: 0,
    //     y: ({animate}) => animate ? 50 : 0,
    //     x: ({selectedItemId, id, animate}) => id === selectedItemId && animate ? "-120%" : 0,
    //     applyAtEnd: {display: 'none'},
    // },
});

export function Manage(props) {

    useAuth();

    // set zoom
    setZoom();

    const intl = useIntl();

    const [state, setState] = useState({tab: 1, animate: false, assignedBeds: [], unassignedBeds: []});

    useEffect(() => {
        props.getListOfNurses();
        // props.listNurseInArea();
        // props.listUnassignedBed();
    }, []);

    useEffect(() => {
        // props.listNurseInArea();
        // props.listUnassignedBed();
        setState({...state, animate: false});
    }, [state.tab]);

    // useEffect(() => {
    // window.location.replace("/import-csv-config");
    //     setState({
    //         ...state,
    //         nurses: props.nurses,
    //         confirmedNurse: [],
    //         unassignedBeds: [],
    //         assignedBeds: [],
    //         assignedBeds2: [],
    //     });
    // }, [props.nurses, props.unassignedBeds, props.assignedBeds, props.assignedBeds2]);

    useEffect(() => {
        setState({
            ...state,
            nurses: props.nurses,
            assignedBeds: props.beds?.filter(b => b.nurse_id?.indexOf(state.selectedNurse) > -1),
            unassignedBeds: props.beds?.filter(b => b.nurse_id?.indexOf(state.selectedNurse) === -1),
            availableBeds: props.beds?.filter(b => {
                if (!b.nurse_id || b.nurse_id.length === 0) return true;
                const intersection = _.intersection(b.nurse_id, props.nurses.map(n => n.id));
                return intersection.length === 0;
            })
        })
    }, [state.selectedNurse, props.nurses]);

    // useEffect(() => {
    //     props.getListOfNurses();
    // }, [state.selectedNurse2]);

    useEffect(() => {
        if (state.isAllowLeave) {
            props.history.push(state.leaveTo);
        }
        return () => {
        };
    }, [state.isAllowLeave, props.history]);

    props.history.block(targetLocation => {
        if (state.isAllowLeave || !state.isChangedNurse) {
            return true;
        }
        setState({...state, openLeaveModal: true, leaveTo: targetLocation.pathname});
        return false;
    });

    const EnterNewBedGroupMsg = intl.formatMessage({ id: "Enter new Bed Group" });

    // const generateDefaultPassword = (nurse) => {
    //     if (nurse.default_password) {
    //         return Password.Nurse;
    //     }
    //     return <span className="link">
    //         <a className="" onClick={() => resetNursePassword(nurse._id)}>{t(`Reset Password`)}</a>
    //     </span>
    // };

    const generateActions = (isAdd, remove, isAdding) => {
        if (state.editing && remove?.id === state.editing?.id) {
            return <span className="link">
            <a className="" onClick={() => handleEditCancel()}>{t(`Cancel`)}</a>
                &nbsp;/&nbsp;
                <a className="" onClick={() => handleEditConfirm()}>{t(`Confirm`)}</a>
        </span>;
        }
        if (isAdd) {
            if (props.license_count <= props.nurses?.length) {
                return <span className="link disabled">
                    <a className="" onClick={handleAdd}>{t(`Add`)}&nbsp;{t("(Max reached)")}</a>
                </span>;
            }
            return <span className="link">
                    <a className="" onClick={handleAdd}>{t(`Add`)}</a>
                </span>;
        }
        if (isAdding) {
            return <span className="link">
            <a className="" onClick={handleAddCancel}>{t(`Cancel`)}</a>
                &nbsp;/&nbsp;
                <a className="" onClick={handleAddConfirm}>{t(`Confirm`)}</a>
        </span>;
        }

        const disableRemove = props.beds.find(b => b.nurse_id?.indexOf(remove.id) > -1);

        return <span className="link">
            <a className=""
               onClick={() => openRemoveNurseModal(remove)}>{t(`Remove`)}</a>
            &nbsp;/&nbsp;
            <a className="" onClick={() => setState({...state, editing: {...remove}})}>{t(`Edit`)}</a>
        </span>;
    };

    const handleAddConfirm = () => {
        if (state.adding?.nurse_name && state.adding?.nurse_name.length > 0) {
            props.addNurse({nurse_name: state.adding.nurse_name});
        }
        setState({...state, adding: undefined});
    };

    const handleAddCancel = () => {
        setState({...state, adding: undefined});
    };

    const nextNurseNumber = () => {
        if (!state.nurses) return;
        return state.nurses.filter(n => !n.pending).length;
    };

    const handleAdd = () => {
        try {
            // const newNurses = [...state.nurses];
            // const nurse = {...newNurses[newNurses.length - 1]};
            // const tmp = nurse.username.split(".");
            // const reg = /\d+$/;
            // const test1 = tmp[0].match(reg);
            // const inc = Number(test1[0]) + 1;
            // nurse.username = nurse.username.replace(`${test1[0]}.`, `${inc}.`);
            // nurse.needConfirm = true;
            // nurse.default_password = true;
            // newNurses.push(nurse);
            // setState({...state, nurses: newNurses, isChangedNurse: true})
            setState({...state, adding: {}});
        } catch (e) {
            console.log(e);
        }
    };

    const openRemoveNurseModal = (remove) => {
        setState({...state, openRemoveNurseModal: true, remove: remove})
    };

    const openRemoveNurseModalCancel = () => {
        setState({...state, openRemoveNurseModal: false});
    };

    const openRemoveNurseModalConfirm = () => {
        console.log(state);

        props.removeNurse({nurse_id: state.remove.id});
        setState({...state, openRemoveNurseModal: false, remove: undefined});
    };

    const handleEditCancel = () => {
        setState({...state, editing: undefined});
    };

    const handleEditConfirm = () => {
        const {id, name, last_update} = state.editing;
        if (name && name.length > 0) {
            props.editNurse({nurse_id: id, nurse_name: name, nurse_last_update: last_update});
        }
        setState({...state, editing: undefined});
    };

    // const resetNursePassword = (id) => {
    //     props.resetNursePassword({nurse_id: id});
    // };

    const handleAssignUnassign = async () => {
        props.openLoading();

        // exec
        let p_all_1 = Promise.resolve();

        _.keys(state.rightSelected).forEach(id => {
            p_all_1 = p_all_1.then(() => {
                const foundBed = props.beds.find(b => b.id === id);
                return props.assignBed({
                    bed_last_update: foundBed.last_update,
                    bed_id: foundBed.id,
                    nurse_id: state.selectedNurse,
                    is_assign: false
                });
            }).then(() => {
                return delayTime(1000);
            })
        });

        p_all_1.then(() => {
            let p_all_2 = Promise.resolve();
            _.keys(state.leftSelected).forEach(id => {
                p_all_2 = p_all_2.then(() => {
                    const foundBed = props.beds.find(b => b.id === id);
                    return props.assignBed({
                        bed_last_update: foundBed.last_update,
                        bed_id: foundBed.id,
                        nurse_id: state.selectedNurse,
                        is_assign: true
                    });
                }).then(() => {
                    return delayTime(1000);
                })
            });

            return p_all_2;
        }).then(() => {
            setState({
                ...state,
                leftSelectAll: false,
                leftSelected: undefined,
                rightSelectAll: false,
                rightSelected: undefined,
                animate: false,
            });
            props.getListOfNurses();
        });

    };

    // const handleTransfer = async () => {
    //     // open loading
    //     props.openLoading();
    //
    //     // exec
    //     const p_all_1 = [], p_all_2 = [];
    //     //exec
    //     _.keys(state.leftSelectedTransfer).forEach(id => {
    //         p_all_1.push(new Promise(async (res, rej) => {
    //             await props.transferBed({
    //                 from_nurse_id: state.selectedNurse1,
    //                 to_nurse_id: state.selectedNurse2,
    //                 mat_id: id,
    //                 leftNurse: state.selectedNurse1,
    //                 rightNurse: state.selectedNurse2,
    //             });
    //             res();
    //         }));
    //     });
    //
    //     _.keys(state.rightSelectedTransfer).forEach(id => {
    //         p_all_2.push(new Promise(async (res, rej) => {
    //             await props.transferBed({
    //                 from_nurse_id: state.selectedNurse2,
    //                 to_nurse_id: state.selectedNurse1,
    //                 mat_id: id,
    //                 leftNurse: state.selectedNurse1,
    //                 rightNurse: state.selectedNurse2,
    //             });
    //             res();
    //         }));
    //     });
    //
    //     await Promise.all(p_all_1);
    //     await Promise.all(p_all_2);
    //     props.listOfNurses();
    //
    //     // close loading
    //     props.closeLoading();
    //
    //     // update state
    //     setState({
    //         ...state,
    //         leftSelectTransferAll: false,
    //         leftSelectedTransfer: undefined,
    //         rightSelectTransferAll: false,
    //         rightSelectedTransfer: undefined,
    //         animate: true,
    //     });
    // };

    const generateSelectActions = (val, handleChange, m, locked) => {
        return <input className={locked ? "disabled" : ""} type="checkbox" checked={val}
                      onChange={() => handleChange(m)}/>
    };

    const generateSelectAllActions = (isLeft) => {
        const handleChangeAll = () => {
            if (isLeft) {
                const leftSelected = {};
                state.unassignedBeds.forEach(m => {
                    // if (m.baby_id) return;
                    leftSelected[m.id] = !state.leftSelectAll;
                    if (!leftSelected[m.id]) delete leftSelected[m.id];
                });
                setState({...state, leftSelected: leftSelected, leftSelectAll: !state.leftSelectAll});
            } else {
                const rightSelected = {};
                state.assignedBeds.forEach(m => {
                    // if (m.baby_id) return;
                    rightSelected[m.id] = !state.rightSelectAll;
                    if (!rightSelected[m.id]) delete rightSelected[m.id];
                });
                setState({...state, rightSelected: rightSelected, rightSelectAll: !state.rightSelectAll})
            }
        };

        // disable or not
        let disabled = false;
        if (isLeft) {
            disabled = state.unassignedBeds.length < 1;
        } else {
            disabled = state.assignedBeds.length < 1;
        }

        return <input type="checkbox"
                      className={disabled ? "disabled all" : "all"}
                      checked={isLeft ? state.leftSelectAll : state.rightSelectAll}
                      onChange={() => handleChangeAll()}/>;
    };

    // const generateSelectTransferAllActions = (isLeft) => {
    //     const handleChangeAll = () => {
    //         if (isLeft) {
    //             const leftSelected = {};
    {/*state.assignedBeds.forEach(m => {*/
    }
    {/*if (m.locked_by) return;*/
    }
    {/*leftSelected[m.id] = !state.leftSelectTransferAll;*/
    }
    {/*if (!leftSelected[m.id]) delete leftSelected[m.id];*/
    }
    {/*});*/
    }
    //             setState({
    //                 ...state,
    //                 leftSelectedTransfer: leftSelected,
    //                 leftSelectTransferAll: !state.leftSelectTransferAll
    //             });
    //         } else {
    //             const rightSelected = {};
    //             state.assignedBeds2.forEach(m => {
    //                 if (m.locked_by) return;
    //                 rightSelected[m.id] = !state.rightSelectTransferAll;
    //                 if (!rightSelected[m.id]) delete rightSelected[m.id];
    //             });
    //             setState({
    //                 ...state,
    //                 rightSelectedTransfer: rightSelected,
    //                 rightSelectTransferAll: !state.rightSelectTransferAll
    //             })
    //         }
    //     };
    //
    //     // disable or not
    //     let disabled = false;
    //     if (isLeft) {
    //         disabled = state.assignedBeds.filter(m => !m.locked_by).length < 1;
    //     } else {
    //         disabled = state.assignedBeds2.filter(m => !m.locked_by).length < 1;
    //     }
    //
    //     return <input type="checkbox"
    //                   className={disabled ? "disabled" : ""}
    //                   checked={isLeft ? state.leftSelectTransferAll : state.rightSelectTransferAll}
    //                   onChange={() => handleChangeAll()}/>
    // };

    const generateAddNurse = () => {
        const generateHeader = () => {
            return <tr>
                <td>{t(`Bed Group Account ID`)}&nbsp;({nextNurseNumber()})&nbsp;</td>
                {/*<td>{t(`Nurse Password`)}</td>*/}
                <td>{t(`Action`)}</td>
            </tr>
        };
        const generateBody = () => {
            let trs = [];
            if (!props.nurses || props.nurses.length < 1) {

            } else {
                trs = props.nurses.map(n => {
                    return <tr>
                        {
                            state.editing?.id === n.id
                                ? <td>
                                    <input
                                        className={n.name === state.editing.name ? "color-grey" : ""}
                                        type="text"
                                        value={state.editing.name}
                                        onChange={(e) => setState({
                                            ...state,
                                            editing: {...state.editing, name: e.target.value}
                                        })}/>
                                </td>
                                : <td>{n.name}</td>
                        }
                        <td>{generateActions(false, n)}</td>
                    </tr>
                });
            }

            return trs;
        };

        const generateAddRow = () => {
            const trs = [];

            // add blank
            if (state.adding) {
                trs.push(<tr>
                    <td>
                        <input type="text" placeholder={EnterNewBedGroupMsg} value={state.adding.nurse_name}
                               onChange={(e) => setState({...state, adding: {nurse_name: e.target.value}})}/>
                    </td>
                    <td>{generateActions(false, false, true)}</td>
                </tr>);
            } else {
                trs.push(<tr>
                    <td></td>
                    <td>{generateActions(true)}</td>
                </tr>);
            }

            return trs;
        };

        return <>
            <div className="descr">{t(`Add or remove Bed Group accounts.`)}</div>
            <EspTable className="add-nurses" renderHeader={generateHeader} renderBody={generateBody}/>
            <table className="add-row">
                {generateAddRow()}
            </table>
        </>
    };

    const generateAssignUnassign = () => {
        const generateLeftHeader = () => {
            return <tr>
                <td>{t(`Bed Name`)}</td>
                {/*<td>{t(`LiveView Name`)}</td>*/}
                <td>{t(`Select`)}&nbsp;&nbsp;{generateSelectAllActions(true)}</td>
            </tr>
        };

        const generateRightHeader = () => {
            return <tr>
                <td>{t(`Bed Name`)}</td>
                {/*<td>{t(`LiveView Name`)}</td>*/}
                <td>{t(`Select`)}&nbsp;&nbsp;{generateSelectAllActions(false)}</td>
            </tr>
        };

        const handleLeftChange = (m) => {
            const newSelected = {...state.leftSelected} || {};
            newSelected[m.id] = !newSelected[m.id];
            if (!newSelected[m.id]) delete newSelected[m.id];
            setState({...state, leftSelected: newSelected})
        };

        const generateLeftBody = () => {
            if (!state.unassignedBeds || state.unassignedBeds.length < 1) {
                return <tr key={1}>
                    <td className="max">{t(`No record found`)}</td>
                </tr>
            } else {
                return state.unassignedBeds
                    .sort((a, b) => compareOACBedName(a.bed_name, b.bed_name))
                    .map(b => {
                        const tmpSelected = state.leftSelected && state.leftSelected[b.id];
                        return <TrLeft key={b.id} animate={state.animate}>
                            <td>{b.bed_name}</td>
                            <td>{generateSelectActions(tmpSelected, handleLeftChange, b, false)}</td>
                        </TrLeft>
                    });
            }
        };

        const handleRightChange = (m) => {
            const newSelected = {...state.rightSelected} || {};
            newSelected[m.id] = !newSelected[m.id];
            if (!newSelected[m.id]) delete newSelected[m.id];
            setState({...state, rightSelected: newSelected})
        };

        const generateRightBody = () => {
            if (!state.assignedBeds || state.assignedBeds.length < 1 || !state.selectedNurse) {
                return <tr key={1}>
                    <td className="max">{t(`No record found`)}</td>
                </tr>
            } else {
                return state.assignedBeds
                    .sort((a, b) => compareOACBedName(a.bed_name, b.bed_name))
                    .map(m => {
                        const tmpSelected = state.rightSelected && state.rightSelected[m.id];
                        return <TrRight key={m.id} animate={state.animate}>
                            <td>{m.bed_name}</td>
                            {/*<td>{m.live_view_name}</td>*/}
                            <td>{generateSelectActions(tmpSelected, handleRightChange, m, false)}</td>
                        </TrRight>
                    });
            }
        };

        let holder = null;
        if (!state.selectedNurse && (_.size(state.leftSelected) > 0 || _.size(state.rightSelected) > 0)) {
            holder = t(`Select Bed Group to assign to`);
        } else if (_.size(state.leftSelected) > 0 && _.size(state.rightSelected) > 0) {
            holder = <a onClick={handleAssignUnassign} className=""><img src={BidirectArrowImg}/></a>;
        } else if (_.size(state.leftSelected) > 0) {
            holder = <a onClick={handleAssignUnassign} className=""><img src={RightArrowImg}/></a>;
        } else if (_.size(state.rightSelected) > 0) {
            holder = <a onClick={handleAssignUnassign} className=""><img src={LeftArrowImg}/></a>;
        }

        const generateAvailableHeader = () => {
            return <tr>
                {/*<td>{t(`Bed`)}</td>*/}
                {/*<td>{t(`LiveView Name`)}</td>*/}
                <td colSpan={2} className="wMax">{t(`Bed Name`)}</td>
            </tr>
        };

        const generateAvailableBody = () => {
            if (!state.availableBeds || state.availableBeds.length < 1) {
                return <tr key={1}>
                    <td className="max">{t(`No record found`)}</td>
                </tr>
            } else {
                const sortedAvailableBeds = state.availableBeds.sort((a, b) => compareOACBedName(a.bed_name, b.bed_name));
                const nRows = Math.ceil(sortedAvailableBeds.length / 2);
                return _.range(0, nRows).map(idx => {
                    const b1 = sortedAvailableBeds[idx * 2];
                    const b2 = idx * 2 + 1 <= sortedAvailableBeds.length - 1 ? sortedAvailableBeds[idx * 2 + 1] : undefined;
                    return <tr>
                        <td>{b1.bed_name}</td>
                        <td>{b2 ? b2.bed_name : undefined}</td>
                    </tr>
                });
            }
        };

        return <>
            <div
                className="descr">{t(`Place Beds under responsibility of Bed Group. Only unallocated Beds can be assigned/unassigned.`)}</div>
            <div className="inline">
                <div className="table-descr">{t(`Beds available for assignment:`)}</div>
                <EspTable className="unassigned" renderHeader={generateLeftHeader}
                          renderBody={() => (<PoseGroup>{generateLeftBody()}</PoseGroup>)}/>
            </div>
            <div className="inline right">
                <div className="table-descr">
                    {t(`Assigned to Bed Group:`)}
                    <IrespDropdown options={[
                        `Select Bed Group`,
                        ...state.nurses.sort((a, b) => a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1).map(n =>
                            <option
                                value={n.id}>{n.name}</option>)
                    ]} handleSelect={(val) => setState({...state, selectedNurse: val, animate: false})}/>
                </div>
                <EspTable className="unassigned" renderHeader={generateRightHeader}
                          renderBody={() => (<PoseGroup>{generateRightBody()}</PoseGroup>)}/>
            </div>
            <div>
                <div className="table-descr">{t(`Beds unassigned to any Bed Group:`)}</div>
                <EspTable className="available" renderHeader={generateAvailableHeader}
                          renderBody={() => (generateAvailableBody())}/>
            </div>
            <div className="action-holder">
                {holder}
            </div>
        </>;
    };

    // const generateTransfer = () => {
    //     const generateLeftHeader = () => {
    //         return <tr>
    //             <td>{t(`Mat Friendly Name`)}</td>
    //             <td>{t(`LiveView Name`)}</td>
    //             <td>{t(`Select`)}&nbsp;&nbsp;{generateSelectTransferAllActions(true)}</td>
    //         </tr>
    //     };
    //
    //     const generateRightHeader = () => {
    //         return <tr>
    //             <td>{t(`Mat Friendly Name`)}</td>
    //             <td>{t(`LiveView Name`)}</td>
    //             <td>{t(`Select`)}&nbsp;&nbsp;{generateSelectTransferAllActions(false)}</td>
    //         </tr>
    //     };
    //
    //     const handleLeftChange = (m) => {
    //         const newSelected = {...state.leftSelectedTransfer} || {};
    //         newSelected[m.id] = !newSelected[m.id];
    //         if (!newSelected[m.id]) delete newSelected[m.id];
    //         setState({...state, leftSelectedTransfer: newSelected})
    //     };
    //
    //     const generateLeftBody = () => {
    //         if (!state.assignedBeds || state.assignedBeds.length < 1 || !state.selectedNurse1) {
    //             return <tr key={1}>
    //                 <td className="max">{t(`No record found`)}</td>
    //             </tr>
    //         } else {
    //             return state.assignedBeds.map(m => {
    //                 const tmpSelected = state.leftSelectedTransfer && state.leftSelectedTransfer[m.id];
    //                 return <TrLeft key={m.id} animate={state.animate}>
    //                     <td>{m.friendly_name}</td>
    //                     <td>{m.live_view_name}</td>
    //                     <td>{generateSelectActions(tmpSelected, handleLeftChange, m, m.locked_by)}</td>
    //                 </TrLeft>
    //             });
    //         }
    //     };
    //
    //     const handleRightChange = (m) => {
    //         const newSelected = {...state.rightSelectedTransfer} || {};
    //         newSelected[m.id] = !newSelected[m.id];
    //         if (!newSelected[m.id]) delete newSelected[m.id];
    //         setState({...state, rightSelectedTransfer: newSelected})
    //     };
    //
    //     const generateRightBody = () => {
    //         if (!state.assignedBeds2 || state.assignedBeds2.length < 1 || !state.selectedNurse2) {
    //             return <tr key={1}>
    //                 <td className="max">{t(`No record found`)}</td>
    //             </tr>
    //         } else {
    //             return state.assignedBeds2.map(m => {
    //                 const tmpSelected = state.rightSelectedTransfer && state.rightSelectedTransfer[m.id];
    //                 return <TrRight key={m.id} animate={state.animate}>
    //                     <td>{m.friendly_name}</td>
    //                     <td>{m.live_view_name}</td>
    //                     <td>{generateSelectActions(tmpSelected, handleRightChange, m, m.locked_by)}</td>
    //                 </TrRight>
    //             });
    //         }
    //     };
    //
    //     let holder = null;
    //     if ((!state.selectedNurse1 || !state.selectedNurse2) && (_.size(state.leftSelectedTransfer) > 0 || _.size(state.rightSelectedTransfer) > 0)) {
    //         holder = t(`Select Nurse to assign to`);
    //     } else if (_.size(state.leftSelectedTransfer) > 0 && _.size(state.rightSelectedTransfer) > 0) {
    //         holder = <a className=""><img src={BidirectArrowImg}/></a>;
    //     } else if (_.size(state.leftSelectedTransfer) > 0) {
    //         holder = <a className=""><img src={RightArrowImg}/></a>;
    //     } else if (_.size(state.rightSelectedTransfer) > 0) {
    {/*holder = <a className=""><img src={LeftArrowImg}/></a>;*/
    }
    {/*}*/
    }

    {/*return <>*/
    }
    {/*<div className="descr">{t(`Transfer beds between Nurses.`)}</div>*/
    }
    {/*<div className="inline">*/
    }
    //             <div className="table-descr">
    //                 {t(`Allocated to Nurse:`)}
    //                 <IrespDropdown options={[
    {/*`Select Nurse`,*/
    }
    {/*...state.confirmedNurse*/
    }
    {/*.filter(n => n._id !== state.selectedNurse2)*/
    }
    {/*.map(n => <option value={n._id}>{n.username}</option>)*/
    }
    {/*]} handleSelect={(val) => setState({...state, selectedNurse1: val, animate: false})}/>*/
    }
    //             </div>
    //             <EspTable className="tx-unassigned" renderHeader={generateLeftHeader}
    //                       renderBody={() => (<PoseGroup>{generateLeftBody()}</PoseGroup>)}/>
    {/*</div>*/
    }
    {/*<div className="inline right">*/
    }
    {/*<div className="table-descr">*/
    }
    {/*{t(`Allocated to Nurse:`)}*/
    }
    {/*<IrespDropdown options={[*/
    }
    //                     `Select Nurse`,
    //                     ...state.confirmedNurse
    //                         .filter(n => n._id !== state.selectedNurse1)
    //                         .map(n => <option value={n._id}>{n.username}</option>)
    {/*]} handleSelect={(val) => setState({...state, selectedNurse2: val, animate: false})}/>*/
    }
    {/*</div>*/
    }
    //             <EspTable className="tx-unassigned" renderHeader={generateRightHeader}
    //                       renderBody={() => (<PoseGroup>{generateRightBody()}</PoseGroup>)}/>
    //         </div>
    //         <div className="action-holder" onClick={handleTransfer}>
    //             {holder}
    //         </div>
    //     </>
    // };

    return (
        <div className="na-manage">
            <div className="data">
                <div className="tabs">
                    <a onClick={() => setState({
                        ...state,
                        tab: 1,
                        selectedNurse: undefined,
                        selectedNurse1: undefined,
                        selectedNurse2: undefined,
                        unassignedBeds: props.unassignedBeds,
                        assignedBeds: props.assignedBeds,
                        assignedBeds2: props.assignedBeds2
                    })}
                       className={state.tab === 1 ? "selected" : ""}>{t(`Add Bed Group`)}</a>
                    <a onClick={() => setState({
                        ...state, tab: 2,
                        selectedNurse: undefined,
                        selectedNurse1: undefined,
                        selectedNurse2: undefined,
                        unassignedBeds: props.unassignedBeds,
                        assignedBeds: props.assignedBeds,
                        assignedBeds2: props.assignedBeds2
                    })}
                       className={state.tab === 2 ? "selected" : ""}>{t(`Assign/Unassign Beds`)}</a>
                    {/*<a onClick={() => setState({*/}
                    {/*...state, tab: 3,*/}
                    {/*selectedNurse: undefined,*/}
                    {/*selectedNurse1: undefined,*/}
                    {/*selectedNurse2: undefined,*/}
                    {/*unassignedBeds: props.unassignedBeds,*/}
                    {/*assignedBeds: props.assignedBeds,*/}
                    {/*assignedBeds2: props.assignedBeds2*/}
                    {/*})}*/}
                    {/*className={state.tab === 3 ? "selected" : ""}>{t(`Transfer Beds between Nurses`)}</a>*/}
                </div>
                <div className="devider"></div>
            </div>
            {
                state.tab === 1
                    ? <div className="table-content">
                        <div>{generateAddNurse()}</div>
                    </div>
                    : ""
            }
            {
                state.tab === 2
                    ? <div className="table-content">
                        <div class="position-relative">{generateAssignUnassign()}</div>
                    </div>
                    : ""
            }
            {/*{*/}
            {/*state.tab === 3*/}
            {/*? <div className="table-content">*/}
            {/*<div>{generateTransfer()}</div>*/}
            {/*</div>*/}
            {/*: ""*/}
            {/*}*/}
            {
                state.openRemoveNurseModal && <IrespModal
                    clazz="confirm-modal"
                    title={t(`Delete Bed Group Account`)}
                    contents={[
                        <div className="">
                            {t(`Delete Bed Group account:`)}
                        </div>,
                        <div className="mt20 color-red font-weight-bold">
                            {state.remove.name}
                        </div>,
                        <div className="mt20">
                            {t(`Action is permanent and cannot be undone.`)}
                        </div>,
                    ]}
                    buttons={[
                        <button onClick={openRemoveNurseModalCancel}>{t(`Cancel`)}</button>,
                        <button onClick={openRemoveNurseModalConfirm}>{t(`Confirm`)}</button>,
                    ]}/>
            }
            {
                state.openLeaveModal && <IrespModal
                    clazz="confirm-modal"
                    title={t(`Leave page without Saving Data`)}
                    contents={[
                        <div className="mt10">
                            {t(`If you leave this page without saving your data, you will lose this information.`)}
                        </div>,
                        <div className="mt20">
                            {t(`To continue without saving, press Confirm.`)}
                        </div>,
                        <div className="mt20">
                            {t(`To complete data entry, press Cancel.`)}
                        </div>,
                    ]}
                    buttons={[
                        <button onClick={() => setState({...state, openLeaveModal: false})}>
                            {t(`Cancel`)}
                        </button>,
                        <button onClick={() => setState({
                            ...state,
                            openLeaveModal: false,
                            isAllowLeave: true
                        })}>{t(`Confirm`)}</button>,
                    ]}/>
            }
        </div>
    );
}