import {connect} from 'react-redux'
import {CUBinding} from "./CUBinding";
import {cuBinding as cuBindingAction} from "../../../redux/actions/osp-mgr/cuBinding";

const mapStateToProps = state => ({
    listOfCU: state.cuBindingReducer.beds,
    listOfBinding: state.cuBindingReducer.beds?.filter(b => b.bcu_wifi_mac),
    error: state.cuBindingReducer.error,
});

function mapDispatchToProps(dispatch) {
    return ({
        getListOfCU: () => dispatch(cuBindingAction.getListOfCU()),
        addCU: (bed) => dispatch(cuBindingAction.addCU(bed)),
        editCU: (bed) => dispatch(cuBindingAction.editCU(bed)),
        deleteCU: (bed) => dispatch(cuBindingAction.deleteCU(bed)),
        unbindCU: (bed) => dispatch(cuBindingAction.unbindCU(bed)),
        setError: (error) => dispatch(cuBindingAction.setError(error)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(CUBinding);