import {connect} from 'react-redux'
import {EnrollPatient} from "./EnrollPatient";
import {enrollAction} from "../../../redux/actions/enrollAction";

const mapStateToProps = state => ({
    beds: state.enrollReducer.availableBeds,
    error: state.enrollReducer.error,
});

function mapDispatchToProps(dispatch) {
    return ({
        // getOperatingMats: () => dispatch(ccAdminDashboardActions.getOperatingMats()),
        getListOfAvailableBeds: () => dispatch(enrollAction.getListOfAvailableBeds()),
        enrollPatient: async (patient) => dispatch(enrollAction.enrollPatient(patient)),
        setError: async (error) => dispatch(enrollAction.setError(error)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(EnrollPatient);