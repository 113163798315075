import React from "react";
import Calendar from "react-calendar";
import moment from "moment";
import 'react-calendar/dist/Calendar.css';

export function OmniCalendar(props) {
    const plusDays = props.plusDays || 0;

    const tileDisabled = ({activeStartDate, date, view }) => {
        const formatted1 = moment(props.maxDate).add(plusDays, "day").format("YYYYMMDD");
        const formatted2 = moment(date).format("YYYYMMDD");
        const formatted3 = props.minDate ? moment(props.minDate).format("YYYYMMDD") : null;
        let startBorder = true;
        if (formatted3) {
            startBorder = moment(formatted3).diff(moment(formatted2), 'days') > 0;
        }
        const endBorder = moment(formatted1).diff(moment(formatted2), 'days') < 0;

        return startBorder || endBorder;
    };

    return <div className="calendar" tabIndex="1">
        <Calendar
            locale={localStorage.getItem("OMNI2_PRIORITY_LANGUAGE") || localStorage.getItem("OMNI2_LOCALE") || "ja"}
            onChange={props.onChange}
            value={props.value}
            tileDisabled={tileDisabled}
        />
    </div>
}