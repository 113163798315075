import React, {useEffect, useState} from "react";
import {useAuth} from "../../../connections/net";
import {messageWrapper as t} from "../../../utils/message-wrapper";
import QRCode from "react-qr-code";
import "./Dashboard.css";
import "./ShowQRCode.css";
import {setZoom} from "../../../utils/set-zoom";

export function ShowQRCodeDownloadApp(props) {

    useAuth();

    // set zoom
    setZoom();

    const [state, setState] = useState({});

    useEffect(() => {
    }, []);

    return (
        <div className="dashboard-cc-admin">
            {/*<div className="select-nurse">*/}
                {/*<div>*/}
                    {/*{t(`Nurse-Admin/T-APP:`)}*/}
                {/*</div>*/}
                {/*<div>*/}
                    {/*<EspDropdown*/}
                        {/*key={Math.random()}*/}
                        {/*value={state.selectedNurse}*/}
                        {/*placeholder={`Select NURSE-ADMIN/T-APP for login`}*/}
                        {/*options={[*/}
                            {/*{value: 0, label: 'T-APP'},*/}
                            {/*// ...props.listOfNurses?.map(n => ({value: n.id, label: n.name}))*/}
                        {/*]} onChange={handleSelect}*/}
                    {/*/>*/}
                {/*</div>*/}
            {/*</div>*/}
            {
                props.qrData
                    ? <div>
                        <div className="qr-code">
                            <b>{t(`QR Code for OAC APP Download only.`)}</b>
                        </div>
                        <div className="qr-code">
                            {t(`Scan this QR code to download the OAC APP on your mobile device.`)}
                        </div>
                        <div className="qrcode-div">
                            <QRCode
                                className="qrcode-img"
                                value={props.qrData}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                    </div>
                    : undefined
            }
        </div>
    );
}