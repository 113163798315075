import React, {useState, useEffect} from "react";
import {messageWrapper as t, translateInput as ti} from "../../utils/message-wrapper";
import "../login/Login.css";
import {IrespModal} from "../common/IrespModal";
import {EspDropdown} from "../common/EspDropdown";
import AppIcon from "../../images/oac/favicon_io/favicon-512x512.png";
import cx from "classnames";
import {OACPasswordField} from "../common/oac/OACPasswordField";
import PasswordOk from "../../images/oac/passwordOk.png";
import PasswordNOk from "../../images/oac/passwordNOk.png";

const ROLES_NAME_MAP = {
    ADMIN: "NURSE-ADMIN",
    MANAGER: "SYS-ADMIN",
};

const initialState = {
    isForgotPassword: true, step: 3,
    password: "",
    confirm: "",
    role: {value: "ADMIN", label: "NURSE-ADMIN"}
};

export function Reset(props) {
    const [state, setState] = useState({...initialState});

    useEffect(() => {
        // props.getCountryCode();
    }, []);

    const handleSignIn = () => {
        // TODO
        // if (state.username === "123" && state.password === "123") {
        //     localStorage.setItem("token", `Bearer faketoken`);
        //     localStorage.setItem("OMNI2_ROLE", ROLES.NurseAdmin);
        //     localStorage.setItem("OMNI2_USER", "test");
        //     localStorage.removeItem("OMNI2_TILES");
        //     window.location.href = "/nurse-admin-dashboard";
        //     return;
        // }
        // if((state.username !== "csp" && state.username !== "nurse") || state.password !== "test") return;
        // let role = ROLES.Csp;
        // if(state.username === "csp") {
        //     role = ROLES.Csp;
        // } else if(state.username === "nurse") {
        //     role = ROLES.Nurse;
        // }
        // localStorage.setItem('token', "fake");
        // localStorage.setItem("OMNI2_ROLE", role);
        // window.location.href = "/";

        // let esp_login = state.esp_login;

        // validate
        if (!state.username || !state.password) {
            props.setError("User_ID & Password cannot be empty");
            return;
        }

        // select phone or email
        // if (state.esp_login.indexOf("@") === -1) {
        //     if (!props.esp_country_code) {
        //         props.setError("Country code is required for login by phone number");
        //         return;
        //     }
        //     const pn = new PhoneNumber(state.esp_login, props.esp_country_code);
        //     esp_login = pn.getNumber();
        // }

        // trigger action
        props.checkLogin({
            username: state.username,
            password: state.password,
            role: state.role?.value
        }, props.history.push);
    };


    // const onSelectFlag = (countryCode) => {
    //     props.setCountryCode(countryCode)
    // };

    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    };

    const handleDuplicateLoginCancel = () => {
        props.duplicateLoginResponse(false);
    };

    const handleDuplicateLoginConfirm = () => {
        props.duplicateLoginResponse(false);
        // props.doLogin({username: state.username, password: state.password, oah_user: "ADMIN"}, props.history.push);
        props.doLogin({
            username: state.username,
            password: state.password,
            oah_user: state.role?.value
        }, props.history.push);
    };

    const handleForgot = () => {
        setState({...state, isForgotPassword: true, step: 1});
    };

    const handleForgotStep1 = async () => {
        const res = await props.requestResetPassword({
            "username": state.email,
            "oah_user": state.role?.value,
        });
        if (res) {
            setState({...state, isForgotPassword: true, step: 2});
            props.setError(undefined);
        }
    };

    const handleForgotStep2 = () => {
        setState({...state, isForgotPassword: true, step: 3});
    };

    const handleForgotStep3 = async () => {
        if (state.password !== state.confirm) {
            props.setError("No match");
            return;
        }

        const urlParams = new URLSearchParams(props.location.search);
        const token = urlParams.get('token');

        const res = await props.doReset({
            "token": token,
            "new_password": state.password
        });

        if (res !== false) {
            setState({...state, isForgotPassword: true, step: 4});
        } else {
            setState({...state, isRetry: true});
        }
    };

    const checkPassword = (cond) => {
        switch (cond) {
            case 1:
                const reg1 = /(.){8,}/gm;
                return reg1.test(state.password);
            case 2:
                const reg2 = /[A-Z]+/gm;
                return reg2.test(state.password);
            case 3:
                const reg3 = /[a-z]+/gm;
                return reg3.test(state.password);
            case 4:
                const reg4 = /[0-9]+/gm;
                return reg4.test(state.password);
            case 5:
                const reg5 = /[!@#\$%\^\&*\)\(+=._-]+/gm;
                return reg5.test(state.password);
        }
    };

    // if (!props.esp_country_code) {
    //     return <div/>
    // }
    const UsernameInput = ti({type: "text", name: "username"}, `Enter User_ID`, handleChange);
    const PasswordInput = ti({type: "password", name: "password"}, `Enter new password`, handleChange);
    const ConfirmPasswordInput = ti({type: "confirm", name: "confirm"}, `Confirm password`, handleChange);
    const EmailInput = ti({type: "email", name: "email"}, `Enter User_ID`, handleChange);
    const isJa = localStorage.getItem("OMNI2_PRIORITY_LANGUAGE") !== "en";

    return (
        <div id="login-container">
            {
                !state.isForgotPassword
                    ? <div id="login-form">
                        <div className="login-header">
                            <img className="logo1" src={AppIcon}/>
                            {/*<span className="logo-text">*/}
                            {/*{Configs.APP_TYPE === 1 ? "OAC (OLD AGED CARE)" : "OAC (OLD AGED CARE)"}*/}
                            {/*</span>*/}
                            {/*<img className="logo1" src={LogoImage}/>*/}
                            {/*<img className="logo2" src={LoganImage} width="100px"/>*/}
                            {/*<img className="logo1" src={IrespLogo} width="200px"/>*/}
                        </div>
                        <div className="login-title">
                            <span>{t(`Sign-in`)}</span>
                        </div>
                        <div className="login-body">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSignIn();
                            }}>
                                <div className="group-label"><span>{t(`Select role to login in:`)}</span></div>
                                <div>
                                    <EspDropdown
                                        key={Math.random()}
                                        value={state.role}
                                        placeholder={`Select Role`}
                                        options={props.roles?.map(b => ({value: b, label: ROLES_NAME_MAP[b]}))}
                                        // noOptionsMessage={}
                                        onChange={(val) => setState({...state, role: val})}
                                    />
                                </div>

                                <div className="group-label"><span>{t(`Enter credentials to login`)}</span></div>
                                <div>
                                    {UsernameInput}
                                </div>
                                <div>
                                    {PasswordInput}
                                </div>
                            </form>
                        </div>
                        <div className="login-footer">
                            <div>
                                <button className="visible" onClick={() => {
                                    handleForgot()
                                }}>{t(`Forgot Password`)}</button>
                                <button onClick={() => {
                                    handleSignIn()
                                }}>{t(`Sign-in`)}</button>
                            </div>
                        </div>
                    </div>
                    : undefined
            }
            {
                state.isForgotPassword && state.step === 1
                    ? <div id="login-form">
                        <div className="login-header">
                            <img className="logo1" src={AppIcon}/>
                        </div>
                        <div className="login-title">
                            <span>{t(`Forgot password`)}</span>
                        </div>
                        <div className="login-body">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSignIn();
                            }}>
                                <div className="group-label"><span>{t(`Select role to login in:`)}</span></div>
                                <div>
                                    <EspDropdown
                                        key={Math.random()}
                                        value={state.role}
                                        placeholder={`Select Role`}
                                        options={props.roles?.map(b => ({value: b, label: ROLES_NAME_MAP[b]}))}
                                        // noOptionsMessage={}
                                        onChange={(val) => setState({...state, role: val})}
                                    />
                                </div>

                                <div className="group-label"><span>{t(`Enter email address:`)}</span></div>
                                <div>
                                    {EmailInput}
                                </div>
                            </form>
                        </div>
                        <div className="login-footer">
                            <div>
                                <button className={cx(
                                    "proceed",
                                    !state.email ? "disabled" : ""
                                )} onClick={() => {
                                    handleForgotStep1()
                                }}>{t(`Proceed`)}</button>
                            </div>
                        </div>
                    </div>
                    : undefined
            }
            {
                state.isForgotPassword && state.step === 2
                    ? <div id="login-form">
                        <div className="login-header">
                            <img className="logo1" src={AppIcon}/>
                        </div>
                        <div className="login-title">
                            <span>{t(`Enter verification code`)}</span>
                        </div>
                        <div className="login-body">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSignIn();
                            }}>
                                <div className="group-label"><span>{t(`Verification code:`)}</span></div>
                                <div>
                                    <input className="verification-code" maxLength={1} name="code1"
                                           onChange={handleChange}/>
                                    <input className="verification-code" maxLength={1} name="code2"
                                           onChange={handleChange}/>
                                    <input className="verification-code" maxLength={1} name="code3"
                                           onChange={handleChange}/>
                                    <input className="verification-code" maxLength={1} name="code4"
                                           onChange={handleChange}/>
                                    <input className="verification-code" maxLength={1} name="code5"
                                           onChange={handleChange}/>
                                    <input className="verification-code" maxLength={1} name="code6"
                                           onChange={handleChange}/>
                                </div>
                            </form>
                        </div>
                        <div className="login-footer">
                            <div>
                                <button className={cx(
                                    "proceed",
                                    (!state.code1 || !state.code2 || !state.code3 || !state.code4 || !state.code5 || !state.code6) ? "disabled" : ""
                                )} onClick={() => {
                                    handleForgotStep2()
                                }}>{t(`Re-send code`)}</button>
                            </div>
                        </div>
                    </div>
                    : undefined
            }
            {
                state.isForgotPassword && state.step === 3
                    ? <div id="login-form">
                        <div className="login-header">
                            <img className="logo1" src={AppIcon}/>
                        </div>
                        <div className="login-title">
                            <span>{t(`Reset password`)}</span>
                        </div>
                        <div className="login-body">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSignIn();
                            }}>
                                <div className="mt10">
                                    <OACPasswordField
                                        maxLength={32}
                                        placeholder="Enter new password"
                                        value={state.password}
                                        onChange={(val) => setState({...state, password: val})}/>
                                    <OACPasswordField
                                        maxLength={32}
                                        placeholder="Confirm password"
                                        value={state.confirm}
                                        onChange={(val) => setState({...state, confirm: val})}/>
                                </div>
                                <div className="div-cond">
                                    <img className="password-cond" src={checkPassword(1) ? PasswordOk : PasswordNOk}/>
                                    <span className={checkPassword(1) ? "cond-ok" : "cond-nok"}>
                                        {t("At least 8 alphanumeric characters")}
                                    </span>
                                </div>
                                <div className="div-cond">
                                    <img className="password-cond" src={checkPassword(2) ? PasswordOk : PasswordNOk}/>
                                    <span className={checkPassword(2) ? "cond-ok" : "cond-nok"}>
                                        {t("At least 1 capital letter")}
                                        </span>
                                </div>
                                <div className="div-cond">
                                    <img className="password-cond" src={checkPassword(3) ? PasswordOk : PasswordNOk}/>
                                    <span
                                        className={checkPassword(3) ? "cond-ok" : "cond-nok"}>
                                         {t("At least 1 small letter")}
                                         </span>
                                </div>
                                <div className="div-cond">
                                    <img className="password-cond" src={checkPassword(4) ? PasswordOk : PasswordNOk}/>
                                    <span
                                        className={checkPassword(4) ? "cond-ok" : "cond-nok"}>
                                        {t("At least 1 number")}
                                        </span>
                                </div>
                                <div className="div-cond">
                                    <img className="password-cond" src={checkPassword(5) ? PasswordOk : PasswordNOk}/>
                                    <span className={checkPassword(5) ? "cond-ok" : "cond-nok"}>
                                        {t("At least 1 printable special character")}
                                        </span>
                                </div>
                            </form>
                        </div>
                        <div className="login-footer reset">
                            <div>
                                {
                                    !state.isRetry
                                        ? <button className={cx(
                                        "proceed",
                                        (
                                            !state.password
                                            || !state.confirm
                                            || !checkPassword(1)
                                            || !checkPassword(2)
                                            || !checkPassword(3)
                                            || !checkPassword(4)
                                            || !checkPassword(5)
                                        ) ? "disabled" : ""
                                        )} onClick={() => {
                                            handleForgotStep3()
                                        }}>{t(`Proceed`)}</button>
                                        : undefined
                                }
                                {
                                    state.isRetry
                                        ? <button className={cx(
                                        "proceed",
                                        )} onClick={() => {
                                            window.location.href = "/login"
                                        }}>{t(`Retry`)}</button>
                                        : undefined
                                }
                            </div>
                        </div>
                    </div>
                    : undefined
            }
            {
                state.isForgotPassword && state.step === 4
                    ? <div id="login-form">
                        <div className="login-header">
                            <img className="logo1" src={AppIcon}/>
                        </div>
                        <div className="login-title">
                            <span>{t(`Password updated`)}</span>
                        </div>
                        <div className="login-body">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSignIn();
                            }}>
                                <div className="mt40">
                                    {t(`Password change notification for:`)}
                                </div>
                                <div className="mt10">
                                    <b>{ROLES_NAME_MAP[props.role]}</b>
                                </div>
                                <div className="mt10">
                                    {t(`has been sent to email address below:`)}
                                    {
                                        isJa
                                            ? <>
                                                <b>{props.email}</b>
                                                {t(`has been sent to email address below 2`)}
                                            </>
                                            : undefined
                                    }
                                </div>
                                {
                                    !isJa
                                        ? <div className="mt10">
                                            <b>{props.email}</b>
                                        </div>
                                        : undefined

                                }
                            </form>
                        </div>
                        <div className="login-footer">
                            <div>
                                <button className={cx(
                                    "proceed final",
                                )} onClick={() => {
                                    if (sessionStorage.getItem(`${sessionStorage.getItem("OMNI2_ROLE")}_token`)) {
                                        window.close();
                                    } else {
                                        window.location.href = "/login";
                                    }
                                }}>{t(`Proceed`)}</button>
                            </div>
                        </div>
                    </div>
                    : undefined
            }
            {
                props.error
                    ? <div className="login-error">
                        {t(`${props.error}`)}
                    </div>
                    : null
            }
            <a id="link-hidden" onClick={handleSignIn}></a>
            {
                props.isDuplicatedLogin
                    ? <IrespModal
                        clazz="duplicate-login-modal"
                        title={t(`Duplicate sign-in attempt`)}
                        contents={[
                            <div>
                                {t(`This will sign you out on other devices and you may lose any unsaved edits.`)}
                            </div>,
                        ]}
                        buttons={[
                            <button onClick={handleDuplicateLoginCancel}>{t(`Cancel`)}</button>,
                            <button onClick={handleDuplicateLoginConfirm}>{t(`Confirm`)}</button>,
                        ]}
                    />
                    : null
            }
        </div>
    );
}