import {connect} from 'react-redux'
import {BedAssignment} from "./BedAssignment";
import {dashboardAction} from "../../../redux/actions/dashboard";
import {appAction} from "../../../redux/actions/appAction";
import {reportAction} from "../../../redux/actions/reportAction";

const mapStateToProps = state => ({
    patients: state.dashboardReducer.patients || [],
    bedAssignmentMap: state.dashboardReducer.bedAssignmentMap,
    beds: state.dashboardReducer.beds,
    nurses: state.dashboardReducer.nurses,
    nurseMap: state.dashboardReducer.nurseMap,
    patientMap: state.dashboardReducer.patientMap,
    listOfCsv: state.reportReducer.listOfCsv,
});

function mapDispatchToProps(dispatch) {
    return ({
        getBedAllocation: () => dispatch(dashboardAction.getBedAllocation()),
        getBedAssignment: () => dispatch(dashboardAction.getBedAssignment()),
        setLiveTile: (view) => dispatch(appAction.setLiveTile(view)),
        listCsv: (info) => dispatch(reportAction.listCsv(info)),
        downloadCsv: (info) => dispatch(reportAction.downloadCsv(info)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(BedAssignment);