import React, {useState, useRef, useEffect} from "react";
import "./OACNumberSelection.css";
import cx from "classnames";
import _ from "lodash";
import {useOutsideDetect} from "../../../utils/hooks";
import {formatSelectNum} from "../../../utils/format";
import {v4 as uuidv4} from 'uuid';
import $ from "jquery";
import {disableTouchMove, enableTouchMove} from "../../../utils/windows-listener";

export function OACNumberSelection(props) {
    const {value, start, end} = props;

    const uuid = uuidv4();

    const [state, setState] = useState({
        focusValue: value < start || value > end ? Math.floor((start + end) / 2 - 2) : Number(props.value) - 2,
    });

    const [isOpen, setIsOpen] = useState(false);

    const zRef = useRef(null);

    let initStart = props.start - 2;
    if (value !== 0) {
        initStart = Number(props.value) - 2;
    }

    const numRef = useRef({
        startNum: value !== 0 && (value < start || value > end) ? Math.floor((start + end) / 2 - 2) : initStart,
        previousOffset: 0
    });

    useOutsideDetect(zRef, isOpen, () => {
        setIsOpen(false);
        enableTouchMove();
    });

    useEffect(() => {
        if (props.hasZero && numRef.current.startNum + 2 === props.start) {
            props.onChange(0);
        } else {
            props.onChange(numRef.current.startNum + 2);
        }
    }, [numRef.current.startNum]);

    useEffect(() => {
        let touchmoveY = 0;
        let touchstartY = 0;

        const el = document.getElementById(`${uuid}-drag-change`);
        if (el) {
            let dragTimeout;
            el.onwheel = function (e) {
                clearTimeout(dragTimeout);
                dragTimeout = setTimeout(function () {
                    updateSelection(e.deltaY);
                }, 15);
            };

            el.ontouchstart = function (event) {
                touchstartY = event.changedTouches[0]?.screenY;
                disableTouchMove();
            };

            let gesuredTimeout;
            el.ontouchmove = function (event) {
                touchmoveY = event.changedTouches[0]?.screenY;
                clearTimeout(gesuredTimeout);
                gesuredTimeout = setTimeout(function () {
                    if (touchstartY > touchmoveY) updateSelection(1);
                    if (touchstartY < touchmoveY) updateSelection(-1);
                }, 10);
            };

            el.ontouchend = function (event) {
                enableTouchMove();
            };

            el.ontouchcancel = function (event) {
                enableTouchMove();
            };
        }
        // if (!state.isOpen) {
        //     numRef.current = {startNum: props.value};
        //     setState({...state, focusValue: props.value, cornerColor: [props.value - 2, props.value + 2]})
        // }
    }, []);

    const updateSelection = (deltaY, isOpen = true) => {
        const offsetY = deltaY;
        const VISIBLE_OFFSET = 4;
        let startNum = numRef.current.startNum;
        if (offsetY > 0) {
            startNum = startNum + 1;
        } else if (offsetY < 0) {
            startNum = startNum - 1;
        }
        const endNum = startNum + VISIBLE_OFFSET;

        if (startNum < props.start - 2 || startNum > props.end - 2) return;

        numRef.current = {startNum};
        setState({...state, focusValue: (endNum + startNum) / 2, isOpen: isOpen});
        // props.onChange((endNum + startNum) / 2);
    };

    const handleClick = (e) => {
        const val = e.target.innerText;

        if (Number(val) === 0) {
            updateSelection(-1, false);
        } else if (!isNaN(val)) {
            updateSelection(Number(val) - state.focusValue, false);
        }
    };

    const openSelection = () => {
        setIsOpen(true);
    };

    // let scrollTimer;
    // const onScroll = (ev) => {
    //     const scrollTop = ev.target.scrollTop;
    //     // console.log(scrollTop, ev.scrollY, ev.deltaY, ev.target.scrollY,  ev.target.deltaY)
    //     // const scrollHeight = ev.target.scrollHeight;
    //     // const start = props.start || 1;
    //     // const end = props.end || 101;
    //     const VISIBLE_OFFSET = 4;
    //     clearTimeout(scrollTimer);
    //     scrollTimer = setTimeout(function () {
    //         let startNum = state.focusValue - 2;
    //         if (scrollTop > 0 && state.focusValue < 100) {
    //             startNum = startNum + 1;
    //         } else if (scrollTop < 0 && state.focusValue < 100) {
    //             startNum = startNum - 1;
    //         }
    //         const endNum = startNum + VISIBLE_OFFSET;
    //         setState({...state, focusValue: (endNum + startNum) / 2});
    //     }, 100);
    // };

    const renderNumbers = () => {
        return <ul id={`${uuid}-drag-change`}>
            {
                _.range(numRef.current.startNum, numRef.current.startNum + 5).map((num, idx) => {
                    if (num === numRef.current.startNum || num === numRef.current.startNum + 4) {
                        return <li className="corner-color"
                                   key={num}>{formatSelectNum(num, props.start, props.end, props.hasZero)}</li>
                    } else if (numRef.current.startNum + 2 === num) {
                        return <li className="focus-color"
                                   key={num}>{formatSelectNum(num, props.start, props.end, props.hasZero)}</li>;
                    }

                    return <li key={num} onClick={handleClick}>{formatSelectNum(num, props.start, props.end, props.hasZero)}</li>;
                })
            }
        </ul>;
    };

    return (
        <div ref={zRef} className="number-select">
            <input className={
                cx(
                    props.className,
                    "number-select-input",
                )}
                   readOnly={true}
                   value={props.value}
                   onClick={openSelection}/>
            <div className={
                cx(
                    "number-select-block",
                    !isOpen ? "d-none" : ""
                )}>
                {renderNumbers(props.start, props.end)}
            </div>
        </div>
    )
}