import $ from "jquery";

export const changeWidth = (width = 88, margin = 25) => {
    document
        .documentElement
        .style
        .setProperty("--main-body-width", width + "%");
    document
        .documentElement
        .style
        .setProperty("--main-body-margin", margin + "px");
};

export const zoom = () => {
    let offset = 0;
    let places = 100;
    const  width = window.innerWidth;

    if(width >= 1800 && width < 1900) {
        offset = -0.009;
        places = 1000;
    } else if(width >= 1700) {
        offset = -0.0085;
        places = 1000;
    } else if(width >= 1600) {
        offset = -0.0080;
        places = 1000;
    } else if(width >= 1500) {
        offset = -0.0075;
        places = 1000;
    } else if(width >= 1400) {
        offset = -0.0070;
        places = 1000;
    } else if(width >= 1300) {
        offset = -0.0060;
        places = 1000;
    } else if(width >= 1200) {
        offset = -0.0055;
        places = 1000;
    } else if(width >= 1100) {
        offset = -0.0050;
        places = 1000;
    } else if(width >= 1000) {
        offset = -0.0045;
        places = 1000;
    } else if(width >= 900) {
        offset = -0.004;
        places = 1000;
    } else if(width >= 800) {
        offset = -0.0035;
        places = 1000;
    } else if(width >= 700) {
        offset = -0.003;
        places = 1000;
    } else if(width >= 600) {
        offset = -0.0025;
        places = 1000;
    } else if(width >= 500) {
        offset = -0.002;
        places = 1000;
    } else if(width >= 400) {
        offset = -0.003;
        places = 1000;
    }

    let rate = width / 1904 + offset + 0.005;

    rate = Math.floor(rate * places) / places;

    document
        .documentElement
        .style
        .setProperty("--main-zoom", rate);

    // scrollbar width
    let scrollbarWidth = getScrollBarWidth();

    if (rate === 1 && scrollbarWidth !== 0) {
        scrollbarWidth = 17;
    } else if(rate > 0.9) {
        scrollbarWidth = 19;
    } else if(rate > 0.85) {
        scrollbarWidth = 20;
    } else if(rate > 0.8) {
        scrollbarWidth = 21;
    } else if(rate > 0.75) {
        scrollbarWidth = 22;
    } else if(rate > 0.7) {
        scrollbarWidth = 23;
    } else if(rate > 0.65) {
        scrollbarWidth = 24;
    } else if(rate > 0.6) {
        scrollbarWidth = 25;
    } else if(rate > 0.55) {
        scrollbarWidth = 29;
    } else if(rate > 0.5) {
        scrollbarWidth = 32;
    } else if(rate > 0.45) {
        scrollbarWidth = 35;
    } else if(rate > 0.4) {
        scrollbarWidth = 37;
    } else if(rate > 0.3) {
        scrollbarWidth = 50;
    }

    document
        .documentElement
        .style
        .setProperty("--scrollbar-width", `${scrollbarWidth}px`);
};

function getScrollBarWidth () {
    const $outer = $('<div>').css({visibility: 'hidden', width: 100, overflow: 'scroll'}).appendTo('body'),
        widthWithScroll = $('<div>').css({width: '100%'}).appendTo($outer).outerWidth();
    $outer.remove();
    return 100 - widthWithScroll;
}

export const changeEditZIndex = (zIndex) => {
    document
        .documentElement
        .style
        .setProperty("--edit-index", zIndex);
};

export const changeOverflow = (overflow) => {
    document
        .documentElement
        .style
        .setProperty("--overflow", overflow);
};