import React, {useEffect, useState} from "react";
import {useAuth} from "../../../connections/net";
import {messageWrapper as t, translateInput as ti, translateValueOption} from "../../../utils/message-wrapper";
import {IrespSortForTable} from "../../common/IrespSortForTable";
import {IrespDropdown} from "../../common/IrespDropdown";
import {EspTable} from "../../common/EspTable";
import _ from "lodash";
import {IrespFilterDate} from "../../common/IrespFilterDate";
import {Sort} from "../../../constants/sort";
import "./PatientTransfer.css";
import moment from "moment";
import {IrespModal} from "../../common/IrespModal";
import {emptyToDash, formatDisplayDate} from "../../../utils/format";
import {EspDropdown} from "../../common/EspDropdown";
import {compareOACBedName} from "../../../utils/sort";
import {InputDatePicker} from "../../common/InputDatePicker";
import cx from "classnames";
import {setZoom} from "../../../utils/set-zoom";
import ReportImg from "../../../images/oac/report-icon.png";
import CsvImg from "../../../images/oac/OAC_FIle-CSV-2B-1464f6_png.png";

const TextFields = ["Resident's Name", "Transfer Date", "Transfer Time", "Transfer From", "Transfer To"];

export function PatientTransfer(props) {

    useAuth();

    // set zoom
    setZoom();

    const [state, setState] = useState({tab: 1});

    useEffect(() => {
        if (state.tab === 1) {
            props.getListAssignedBeds();
        } else {
            props.getTransferHistory();
        }
    }, [state.tab]);

    useEffect(() => {
        const filteredHistory = props.history;
        setState({...state, filteredHistory: filteredHistory});
    }, [props.history]);

    useEffect(() => {
        let filteredHistory;
        if (!state.filterValue) {
            filteredHistory = props.history;
        }
        else if (state.filterField?.indexOf("time") > -1) {
            filteredHistory = props.history?.filter(h => {
                return moment(h[state.filterField]).format("DDMMYYYY") === moment(state.filterValue).format("DDMMYYYY");
            });
        } else {
            filteredHistory = props.history?.filter(h => {
                return !state.filterField || state.filterValue === "ALL" || h[state.filterField] === state.filterValue;
            });
        }
        setState({...state, filteredHistory: filteredHistory});
    }, [state.filterValue]);

    const generatePatientTransfer = () => {
        const generateHeader = () => {
            return <tr>
                <td onClick={() => sortProcess("patient_name")}>
                    {t(`Resident's Name`)}
                    <IrespSortForTable name="patient_name" sortState={state.sort}/>
                </td>
                <td onClick={() => sortProcess("bed")}>
                    {t(`Bed`)}
                    <IrespSortForTable name="bed" sortState={state.sort}/>
                </td>
                <td>
                    {t(`Resident Actions`)}
                </td>
            </tr>
        };
        const generateBody = () => {
            if (!props.assignedBeds || props.assignedBeds.length < 1) {
                return <tr>
                    <td className="max">{t(`No record found`)}</td>
                </tr>
            } else {
                return props.assignedBeds
                    .sort((a,b) => compareOACBedName(a.bed_name, b.bed_name))
                    .map(b => {
                    return <tr>
                        <td>
                            {props.patientMap[b.patient_id]?.name}
                        </td>
                        <td>{b.bed_name}</td>
                        <td>{generateAction(b)}</td>
                    </tr>
                });
            }
        };

        const generateArchiveHeader = () => {
            return <tr>
                <td>
                    {t(`Date`)}
                </td>
                <td>
                    {t(`Download Status`)}
                </td>
            </tr>
        };

        const generateArchiveBody = () => {

        };

        return <>
            <div className="descr">
                {/*{t(`Transfer Resident`)}*/}
            </div>
            <div className="inline">
                <EspTable className="patient-list mt-lg-5 mt-md-5 mt-sm-5" renderHeader={generateHeader}
                          renderBody={generateBody}/>
            </div>
            {/*<div className="inline csv-archive">*/}
                {/*<div>*/}
                    {/*<span>{t(`CSV Archive for:`)}</span>*/}
                    {/*<b></b>*/}
                {/*</div>*/}
                {/*<EspTable className="csv-archive-table" renderHeader={generateArchiveHeader}*/}
                          {/*renderBody={generateArchiveBody}/>*/}
            {/*</div>*/}
        </>
    };

    const generateTransferHistory = () => {
        const generateHeader = () => {
            return <tr>
                <td onClick={() => sortProcess("patient_name")}>
                    {t(`Resident's Name`)}
                    <IrespSortForTable name="patient_name" sortState={state.sort}/>
                </td>
                <td onClick={() => sortProcess("transfer_date")}>
                    {t(`Transfer Date`)}
                    <IrespSortForTable name="bed" sortState={state.sort}/>
                </td>
                <td onClick={() => sortProcess("transfer_time")}>
                    {t(`Transfer Time`)}
                    <IrespSortForTable name="bed" sortState={state.sort}/>
                </td>
                <td onClick={() => sortProcess("transfer_from")}>
                    {t(`Transfer from`)}
                    <IrespSortForTable name="bed" sortState={state.sort}/>
                </td>
                <td onClick={() => sortProcess("transfer_to")}>
                    {t(`Transfer to`)}
                    <IrespSortForTable name="bed" sortState={state.sort}/>
                </td>
            </tr>
        };
        const generateBody = () => {
            if (!state.filteredHistory || state.filteredHistory.length < 1) {
                return <tr>
                    <td className="max">{t(`No record found`)}</td>
                </tr>
            } else {
                return state.filteredHistory.map(b => {
                    return <tr>
                        <td>{b.patient_name}</td>
                        <td>{formatDisplayDate(b.transfer_datetime)}</td>
                        <td>{moment(b.transfer_datetime).format("HH:mm")}</td>
                        <td>{b.src_bed_name}</td>
                        <td>{b.dest_bed_name}</td>
                    </tr>
                });
            }
        };

        return <>
            <div className="descr">
                {/*{t(`Transfer history`)}*/}
            </div>
            <div className="table-descr">
                <div className="inline">
                    {t(`Filter Field:`)}
                    <IrespDropdown options={[
                        `Select Field`,
                        translateValueOption("Resident's Name", "patient_name"),
                        translateValueOption("Transfer Date", "transfer_datetime"),
                        translateValueOption("Transfer from", "src_bed_name"),
                        translateValueOption("Transfer to", "dest_bed_name"),
                    ]} handleSelect={(val) => setState({
                        ...state,
                        filterField: val,
                        filterValue: val.indexOf("time") > -1 ? undefined : "ALL"
                    })}/>
                </div>
                <div className="inline ml90">
                    {t(`Filter Value:`)}
                    {generateValues()}
                </div>
            </div>
            <EspTable className="transfer-history" renderHeader={generateHeader} renderBody={generateBody}/>
        </>
    };

    const generateAction = (bed) => {
        return <span className="link">
            <a onClick={() => setState({...state, isOpenTransferModal: true, currentBed: bed})}>{t(`Transfer`)}</a>
            &nbsp;/&nbsp;
            <a onClick={() => setState({...state, isOpenEditModal: true, dob: moment(props.patientMap[bed.patient_id].dob, "YYYY-MM-DD").toDate(), patient_name: props.patientMap[bed.patient_id].name,  patient: props.patientMap[bed.patient_id]})}>{t(`Edit`)}</a>
            &nbsp;/&nbsp;
            <a onClick={() => setState({...state, isOpenDischargeModal: true, patient: props.patientMap[bed.patient_id]})}>{t(`Discharge`)}</a>
        </span>
    };

    const dropdownValues = () => {
        if (!state.filterField) return [];
        return _.uniq(props.history.map(b => b[state.filterField]), state.filterField).sort((a, b) => a.toUpperCase() > b.toUpperCase() ? 1 : -1);
    };

    const generateValues = () => {
        if (TextFields.indexOf(state.filterField) > -1) {
            return <input className="filter" value={state.filterValue || ""}
                          onChange={e => setState({...state, filterValue: e.target.value})}/>
        }

        if (state.filterField && state.filterField.indexOf("time") > -1) {
            return <IrespFilterDate value={state.filterValue}
                                    handleSelect={(val) => setState({...state, filterValue: val})}/>;
        }

        return <IrespDropdown
            value={state.filterValue}
            options={[
                `Select Value`,
                translateValueOption("All", "ALL"),
                ...dropdownValues().map(val => {
                    if (state.filterField === "response_status") {
                        return translateValueOption(val, val);
                    }
                    return <option value={val}>{val}</option>;
                })
            ]} handleSelect={(val) => setState({...state, filterValue: val})}/>;
    };

    const sortProcess = (name) => {
        if (!state.sort || state.sort.name !== name) {
            return setState({...state, sort: {name: name, value: Sort.Descending}});
        } else if (state.sort.name === name && state.sort.value === Sort.Descending) {
            return setState({...state, sort: {name: name, value: Sort.Ascending}});
        }
        setState({...state, sort: undefined});
    };

    const handleTransferModalConfirm = async () => {
        const res = await props.transferPatient({
            "src_bed_id": state.currentBed?.id,
            "src_bed_timestamp": state.currentBed?.last_update,
            "dest_bed_id": state.selectedDestBed?.value?.id,
            "dest_bed_timestamp": state.selectedDestBed?.value?.last_update,
            "patient_id": state.currentBed?.patient_id
        });
        if (res) {
            setState({...state, isOpenTransferModal: false, currentBed: undefined, selectedDestBed: undefined});
        }
    };

    const handleTransferModalCancel = () => {
        setState({...state, isOpenTransferModal: false, currentBed: undefined, selectedDestBed: undefined});
        props.setError(undefined);
    };

    const handleEditModalCancel = () => {
        setState({...state, isOpenEditModal: false});
    };

    const handleEditModalConfirm = async () => {
        await props.editPatient({
            patient_id: state.patient?.id,
            name: state.patient_name,
            dob: moment(state.dob).format("YYYY-MM-DD"),
            patient_last_update: state.patient?.last_update
        });
        props.getListAssignedBeds();
        setState({...state, isOpenEditModal: false});
    };

    const handleDischargeModalCancel = () => {
        setState({...state, isOpenDischargeModal: false});
    };

    const handleDischargeModalConfirm = () => {
        setState({...state, isOpenDischargeReConfirmModal: true});
    };

    const handleDischargeModalReconfirmConfirm = async () => {
        await props.dischargePatient({
            patient_id: state.patient?.id,
        });

        setState({...state, isOpenDischargeModal: false, isOpenDischargeReConfirmModal: false});
        props.getListAssignedBeds();
    };

    const handleDischargeModalReconfirmCancel = () => {
        setState({...state, isOpenDischargeReConfirmModal: false});
    };

    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    };

    const PatientName = ti({
        type: "text",
        name: "patient_name",
        value: state.patient_name,
        className: cx(
            "enroll-input-sm w330",
            state.patient_name === state.patient?.name ? " color-grey" : ""
        ),
        maxLength: 20
    }, `Name of Resident`, handleChange);

    return (
        <div className={localStorage.getItem("OMNI2_PRIORITY_LANGUAGE") !== "en" ? "patient-transfer ja" : "patient-transfer"}>
            <div className="data">
                <div className="tabs">
                    <a onClick={() => setState({...state, tab: 1, filterField: undefined})}
                       className={state.tab === 1 ? "selected" : ""}>{t(`Resident Operations`)}</a>
                    <a onClick={() => setState({...state, tab: 2, filterField: undefined})}
                       className={state.tab === 2 ? "selected" : ""}>{t(`Transfer History`)}</a>
                </div>
                <div className="devider"></div>
            </div>
            {
                state.tab === 1
                    ? <div className="table-content">
                        <div>{generatePatientTransfer()}</div>
                    </div>
                    : ""
            }
            {
                state.tab === 2
                    ? <div className="table-content">
                        <div>{generateTransferHistory()}</div>
                    </div>
                    : ""
            }
            {
                state.isOpenTransferModal
                    ? <IrespModal
                        clazz="transfer-request-modal"
                        title={t(`Transfer Resident - Select Destination`)}
                        contents={[
                            <div className="mt10">
                                <b>{t(`Transfer`)}:&nbsp;</b>
                            </div>,
                            <div className="">
                                <span className="ml-3">{t(`Resident`)}:&nbsp;</span>
                                <b className="color-red">{emptyToDash(props.patientMap[state.currentBed?.patient_id]?.name)}</b>
                                <br/>
                                <span className="ml-3">{t(`from Bed`)}:&nbsp;</span>
                                <b className="color-red">{emptyToDash(state.currentBed?.bed_name)}</b>
                            </div>,
                            <div className="m35">
                                {t(`Destination bed:`)}
                            </div>,
                            <div className="">
                                <EspDropdown
                                    key={Math.random()}
                                    value={state.selectedDestBed}
                                    placeholder={`Select bed to transfer to`}
                                    options={
                                        props.availableBeds?.map(n => ({value: n, label: n.bed_name}))
                                    } onChange={(val) => {
                                    setState({...state, selectedDestBed: val});
                                    props.setError(undefined);
                                }}
                                />
                            </div>,
                            <div className="error">
                                {props.error ? <div>{t(`${props.error}`)}</div> : undefined}
                            </div>,
                            <div className="">
                                {t(`Please ensure bed has been prepared to receive the resident.`)}
                            </div>,
                        ]}
                        buttons={[
                            <button onClick={handleTransferModalCancel}>{t(`Cancel`)}</button>,
                            <button className={!state.selectedDestBed ? "disabled" : ""}
                                    onClick={handleTransferModalConfirm}>{t(`Confirm`)}</button>,
                        ]}
                    />
                    : null
            }

            {
                state.isOpenDischargeModal
                    ? <IrespModal
                        clazz="discharge-modal"
                        title={t(`Discharge Resident`)}
                        contents={[
                            <div className="mt10">
                                <b>{t(`Resident's name:`)}&nbsp;</b>
                                <span className="red">{emptyToDash(state.patient?.name)}</span>
                            </div>,
                            <div className="">
                                <b>{t(`Resident's birthdate:`)}&nbsp;</b>
                                <span className="red">{moment(state.patient?.dob).format("YYYY-MM-DD")}</span>
                            </div>,
                            <div className="m35">
                                {t(`Confirming discharge will:`)}
                            </div>,
                            <div className="">
                                {t(`* Stop resident monitoring`)}
                            </div>,
                            <div className="">
                                {t(`* Unallocate bed`)}
                            </div>,
                            <div className="m35 mb10">
                                {t(`This action cannot be undone.`)}
                            </div>,
                        ]}
                        buttons={[
                            <button onClick={handleDischargeModalCancel}>{t(`Cancel`)}</button>,
                            <button onClick={handleDischargeModalConfirm}>{t(`Confirm`)}</button>,
                        ]}
                    />
                    : null
            }

            {
                state.isOpenDischargeReConfirmModal
                    ? <IrespModal
                        clazz="discharge-reconfirm-modal"
                        title={t(`Are you sure?`)}
                        contents={[
                            <div className="red font-weight-bold">
                                {t(`This action cannot be undone.`)}
                            </div>,
                        ]}
                        buttons={[
                            <button onClick={handleDischargeModalReconfirmCancel}>{t(`Cancel`)}</button>,
                            <button onClick={handleDischargeModalReconfirmConfirm}>{t(`YES`)}</button>,
                        ]}
                    />
                    : null
            }

            {
                state.isOpenEditModal
                    ? <IrespModal
                        clazz="edit-modal"
                        title={t(`Edit Resident's Details`)}
                        contents={[
                            <div className="one-line mt-lg-5 mt-md-5 mt-sm-5">
                                <div>
                                    {t(`Resident's Name:`)}
                                </div>
                                <div>
                                    {PatientName}
                                    <br/>
                                    <span className="descr ml-4">{t(`Max. 20 characters`)}</span>
                                </div>
                            </div>,
                            <div className="one-line">
                                <div>
                                    {t(`Resident's Birthdate:`)}
                                </div>
                                <div>
                                    <InputDatePicker
                                        value={state.dob}
                                        showAll={true}
                                        minDate={moment().add(-365, 'days')}
                                        className={
                                            cx(
                                                "enroll-input-sm",
                                                moment(state.dob).format("YYYYMMDD") === moment(state.patient?.dob).format("YYYYMMDD") ? " color-grey" : ""
                                            )
                                        }
                                        onChange={(v) => setState({
                                            ...state, dob: v
                                        })}
                                    />
                                </div>
                            </div>
                        ]}
                        buttons={[
                            <button onClick={handleEditModalCancel}>{t(`Cancel`)}</button>,
                            <button onClick={handleEditModalConfirm}>{t(`Confirm`)}</button>,
                        ]}
                    />
                    : null
            }
        </div>
    );
}