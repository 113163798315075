import React, {useEffect, useState} from "react";
import {useAuth} from "../../../connections/net";
import {messageWrapper as t, translateValueOption} from "../../../utils/message-wrapper";
import {EspTable} from "../../common/EspTable";
import "./CUBinding.css";
import moment from "moment";
import {IrespModal} from "../../common/IrespModal";
import cx from "classnames";
import {useIntl} from "react-intl";
import {setZoom} from "../../../utils/set-zoom";
import {compareOACBedName} from "../../../utils/sort";

export function CUBinding(props) {

    useAuth();

    // set zoom
    setZoom();

    const intl = useIntl();

    const [state, setState] = useState({tab: 1});

    useEffect(() => {
        if (state.tab === 1) {
            props.getListOfCU();
        } else {
            // props.getTransferHistory();
        }
    }, [state.tab]);

    useEffect(() => {
        const filteredHistory = props.history;
        setState({...state, filteredHistory: filteredHistory});
    }, [props.history]);

    useEffect(() => {
        let filteredHistory;
        if (!state.filterValue) {
            filteredHistory = props.history;
        }
        else if (state.filterField?.indexOf("time") > -1) {
            filteredHistory = props.history?.filter(h => {
                return moment(h[state.filterField]).format("DDMMYYYY") === moment(state.filterValue).format("DDMMYYYY");
            });
        } else {
            filteredHistory = props.history?.filter(h => {
                return !state.filterField || state.filterValue === "ALL" || h[state.filterField] === state.filterValue;
            });
        }
        setState({...state, filteredHistory: filteredHistory});
    }, [state.filterValue]);

    const EnterBedNameMsg = intl.formatMessage({ id: "Enter Bed Name" });

    const generateCUName = () => {
        const generateHeader = () => {
            return <tr>
                <td>{t(`Bed Name`)}</td>
                <td>{t(`Action`)}</td>
            </tr>
        };
        const generateBody = () => {
            if (!props.listOfCU || props.listOfCU.length < 1) {
                return <tr>
                    <td className="max">{t(`No record found`)}</td>
                </tr>
            } else {
                const trs = props.listOfCU
                    .sort((a, b) => compareOACBedName(a.bed_name, b.bed_name))
                    .map(cu => {
                    return <tr>
                        {
                            state.editing && state.editing?.id === cu.id
                                ? <td>
                                    <input
                                        className={cu.bed_name === state.editing.bed_name_editing ? "color-grey" : ""}
                                        type="text"
                                        value={state.editing.bed_name_editing}
                                        onChange={(e) => setState({
                                            ...state,
                                            editing: {...state.editing, bed_name_editing: e.target.value}
                                        })}/>
                                </td>
                                : <td>{cu.bed_name}</td>
                        }
                        <td>{generateAction(cu)}</td>
                    </tr>
                });

                return trs;
            }
        };

        const generateAddRow = () => {
            const trs = [];
            if (state.adding) {
                trs.push(<tr>
                    <td>
                        <input type="text" placeholder={EnterBedNameMsg} value={state.adding.bed_name}
                               onChange={(e) => setState({...state, adding: {bed_name: e.target.value}})}/>
                    </td>
                    <td>{generateAction(false, false, true)}</td>
                </tr>);
            } else {
                trs.push(<tr>
                    <td></td>
                    <td>{generateAction(false, true, false)}</td>
                </tr>);
            }

            return trs;
        };

        return <>
            <EspTable className="cu-name-tbl mt-lg-5 mt-md-5 mt-sm-5" renderHeader={generateHeader}
                      renderBody={generateBody}/>
            <table className="add-row">
                {generateAddRow()}
            </table>
            {
                props.error
                    ? <div className="error">
                        <div>{t(`${props.error}`)}</div>
                    </div>
                    : undefined
            }
        </>
    };

    const generateBinding = () => {
        const generateHeader = () => {
            return <tr>
                <td>{t(`Bed Name`)}</td>
                <td>{t(`BCU MAC`)}</td>
                <td>{t(`Action`)}</td>
            </tr>
        };
        const generateBody = () => {
            if (!props.listOfBinding || props.listOfBinding.length < 1) {
                return <tr>
                    <td className="max">{t(`No record found`)}</td>
                </tr>
            } else {
                return props.listOfBinding
                    .sort((a, b) => compareOACBedName(a.bed_name, b.bed_name))
                    .map(cu => {
                    return <tr>
                        <td>{cu.bed_name}</td>
                        <td>{cu.bcu_wifi_mac}</td>
                        <td>{generateUnbindAction(cu)}</td>
                    </tr>
                });
            }
        };

        return <>
            <EspTable className="unbind-tbl mt-md-5 mt-sm-5" renderHeader={generateHeader} renderBody={generateBody}/>
        </>
    };

    const generateUnbindAction = (cu) => {
        return <span className="link">
             <a className=""
                onClick={() => setState({...state, isUnbindModalOpen: true, unbind: cu})}>{t(`Unbind`)}</a>
        </span>
    };

    const generateAction = (cu, isAdd, isAdding) => {
        if (isAdd) {
            return <span className="link">
            <a className="" onClick={handleAdd}>{t(`Add`)}</a>
        </span>;
        }

        if (isAdding) {
            return <span className="link">
            <a className="" onClick={handleAddCancel}>{t(`Cancel`)}</a>
                &nbsp;/&nbsp;
                <a className="" onClick={handleAddConfirm}>{t(`Confirm`)}</a>
        </span>;
        }

        if (state.editing?.id === cu.id) {
            return <span className="link">
            <a className=""
               onClick={() => {
                   setState({...state, editing: undefined});
                   props.setError(undefined);
               }}>{t(`Cancel`)}</a>
                &nbsp;/&nbsp;
                <a className="" onClick={handleEditConfirm}>{t(`Confirm`)}</a>
        </span>
        }

        return <span className="link">
            <a className={
                cx(
                    "",
                    cu.bcu_wifi_mac ? "disabled" : ""
                )
            }
               onClick={() => setState({...state, isDeleteModal: true, currentCU: cu})}>{t(`Delete`)}</a>
            &nbsp;/&nbsp;
            <a className=""
               onClick={() => setState({...state, editing: {...cu, bed_name_editing: cu.bed_name}})}>{t(`Edit`)}</a>
        </span>
    };

    const handleAdd = () => {
        try {
            setState({...state, adding: {}});
        } catch (e) {
            console.log(e);
        }
    };

    const handleAddCancel = () => {
        setState({...state, adding: undefined});
    };

    const handleAddConfirm = () => {
        const found = props.listOfCU.find(cu => cu.bed_name?.toLowerCase() === state.adding.bed_name?.toLowerCase());

        if (found) {
            props.setError('BED_EXIST');
            return;
        }

        if (state.adding?.bed_name) {
            props.addCU({bed_name: state.adding.bed_name});
        }
        setState({...state, adding: undefined});
    };

    const handleEditConfirm = () => {
        const {id, bed_name, last_update, bed_name_editing} = state.editing;
        const found = props.listOfCU.find(cu => cu.bed_name?.toLowerCase() === bed_name_editing?.toLowerCase() && cu.id !== id);

        if (found) {
            props.setError('BED_EXIST');
            return;
        }

        if (bed_name_editing && bed_name_editing.length > 0) {
            props.editCU({
                "bed_id": id,
                "new_bed_name": state.editing?.bed_name_editing,
                "bed_last_update": last_update
            });
        }
        setState({...state, editing: undefined});
    };

    // const dropdownValues = () => {
    //     if (!state.filterField) return [];
    //     return _.uniq(props.history.map(b => b[state.filterField]), state.filterField);
    // };
    //
    // const generateValues = () => {
    //     if (TextFields.indexOf(state.filterField) > -1) {
    //         return <input className="filter" value={state.filterValue || ""}
    //                       onChange={e => setState({...state, filterValue: e.target.value})}/>
    //     }
    //
    //     if (state.filterField && state.filterField.indexOf("time") > -1) {
    //         return <IrespFilterDate value={state.filterValue}
    //                                 handleSelect={(val) => setState({...state, filterValue: val})}/>;
    //     }
    //
    //     return <IrespDropdown
    //         value={state.filterValue}
    //         options={[
    //             `Select Value`,
    //             translateValueOption("All", "ALL"),
    //             ...dropdownValues().map(val => {
    //                 if (state.filterField === "response_status") {
    //                     return translateValueOption(val, val);
    //                 }
    //                 return <option value={val}>{val}</option>;
    //             })
    //         ]} handleSelect={(val) => setState({...state, filterValue: val})}/>;
    // };
    //
    // const sortProcess = (name) => {
    //     if (!state.sort || state.sort.name !== name) {
    //         return setState({...state, sort: {name: name, value: Sort.Descending}});
    //     } else if (state.sort.name === name && state.sort.value === Sort.Descending) {
    //         return setState({...state, sort: {name: name, value: Sort.Ascending}});
    //     }
    //     setState({...state, sort: undefined});
    // };

    const handleDeleteModalCancel = async () => {
        setState({...state, isDeleteModal: false, currentBed: undefined});
    };

    const handleDeleteModalConfirm = () => {
        const {bed_name, last_update, id} = state.currentCU;
        props.deleteCU({
            "bed_id": id,
            "bed_last_update": last_update
        });
        setState({...state, isDeleteModal: false, currentBed: undefined});
    };

    const handleUnbindModalCancel = async () => {
        setState({...state, isUnbindModalOpen: false, unbind: undefined});
    };

    const handleUnbindModalConfirm = () => {
        const {bed_name, last_update, id} = state.unbind;
        props.unbindCU({
            "bed_id": id,
            "bed_last_update": last_update
        });
        setState({...state, isUnbindModalOpen: false, unbind: undefined});
    };

    return (
        <div className="cu-binding">
            <div className="data">
                <div className="tabs">
                    <a onClick={() => setState({...state, tab: 1, filterField: undefined})}
                       className={state.tab === 1 ? "selected" : ""}>{t(`Bed Name`)}</a>
                    <a onClick={() => setState({...state, tab: 2, filterField: undefined})}
                       className={state.tab === 2 ? "selected" : ""}>{t(`Bed Unbinding`)}</a>
                </div>
                <div className="devider"></div>
            </div>
            {
                state.tab === 1
                    ? <div className="table-content">
                        <div>{generateCUName()}</div>
                    </div>
                    : ""
            }
            {
                state.tab === 2
                    ? <div className="table-content">
                        <div>{generateBinding()}</div>
                    </div>
                    : ""
            }
            {
                state.isDeleteModal
                    ? <IrespModal
                        clazz="delete-cu-modal"
                        title={t(`Delete Bed`)}
                        contents={[
                            <div className="mt10">
                                {t(`Are you sure you want to delete this bed ?`)}
                                <br/>
                                &nbsp;&nbsp;<b>{state.currentCU?.bed_name}</b>
                            </div>,
                            <div className="note-red m35">
                                {t(`Note: This action cannot be undone.`)}
                            </div>,
                        ]}
                        buttons={[
                            <button onClick={handleDeleteModalCancel}>{t(`Cancel`)}</button>,
                            <button onClick={handleDeleteModalConfirm}>{t(`Confirm`)}</button>,
                        ]}
                    />
                    : null
            }
            {
                state.isUnbindModalOpen
                    ? <IrespModal
                        clazz="delete-cu-modal"
                        title={t(`Unbind Bed Name`)}
                        contents={[
                            <div className="m35">
                                {t(`Unbind the following Bed Name:`)}
                                <br/>
                                &nbsp;&nbsp;<b>{state.unbind?.bed_name}</b>
                            </div>,
                            <div className="note-red m35">
                                {t(`Note: This action cannot be undone.`)}
                            </div>,
                        ]}
                        buttons={[
                            <button onClick={handleUnbindModalCancel}>{t(`Cancel`)}</button>,
                            <button onClick={handleUnbindModalConfirm}>{t(`Confirm`)}</button>,
                        ]}
                    />
                    : null
            }
        </div>
    );
}