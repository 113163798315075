import {connect} from 'react-redux'
import {ShowQRCodeDownloadApp} from "./ShowQRCode";
import {Configs} from "../../../constants/configs";

const mapStateToProps = state => ({
    qrData: Configs.OAC_APK_LINK,
});

function mapDispatchToProps(dispatch) {
    return ({
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowQRCodeDownloadApp);