import {GET_HISTORY} from "../actions/dischargeAction";
import moment from "moment";

const initialState = {};

const dischargeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HISTORY:
            return {...state, history: action.payload?.sort((a, b) => moment(a.discharged).isBefore(moment(b.discharged)) ? 1 : -1)};
        default:
            return state
    }
};

export default dischargeReducer;