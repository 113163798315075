import moment from "moment";

export const formatTmp = (tmp) => {
    if (isNaN(tmp)) return tmp;

    return Number(tmp).toFixed(1);
};

export const emptyToDash = (str) => {
    if (!str || str === "") {
        return "--";
    }

    return str;
};

export const formatTime = (n, start, end) => {
    if (n < 0) {
        return "-";
    }

    if(n > end || n < start) return "-";

    return n > 9 ? n : "0" + n;
};

export const formatSelectNum = (num, start = 0, end = 101, hasZero = false) => {
    if (hasZero && num === start) {
        return 0;
    }

    if (num < start || num > end) {
        return "-";
    }
    return num;
};

export const monthMomentToReal = (m) => {
    return m + 1 > 12 ? 1 : m + 1;
};

export const formatDisplayDate = (date) => {
    const year = moment(date).format("YYYY");
    const month = moment(date).format("MM");
    const day = moment(date).format("DD");

    if (localStorage.getItem('OMNI2_PRIORITY_LANGUAGE') !== "en") {
        return year + "年" + month + "月" + day + "日";
    } else {
        return moment(date).format("DD MMM YYYY");
    }
};