import {GET_DATA, LIST_CSV, SET_PATIENT} from "../actions/reportAction";
import moment from "moment";

const initialState = {
    listOfCsv: {}
};

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DATA:
            const {sleeplogs, oah, patient_id, sl_last_update} = action.payload;
            const {sl, vitalSign} = procesSleeplog(sleeplogs, sl_last_update);
            // console.log(sl);
            return {...state, sleeplogs: sleeplogs, patient: extractPatient(oah, patient_id), sl, vitalSign};
        case SET_PATIENT:
            const {oah: oah2, patient_id: patient_id_2} = action.payload;
            return {...state, patient: extractPatient(oah2, patient_id_2)};
        case LIST_CSV:
            return {...state, listOfCsv: action.payload};
        default:
            return state
    }
};

function extractPatient(oah, patient_id) {
    const {patients, beds, info} = oah;
    const patientFound = patients.find(p => p.id === patient_id);
    const bedFound = beds.find(b => b.patient_id === patient_id);

    return {...patientFound, bed_name: bedFound?.bed_name, oah_name: info.oah_name};
}

function procesSleeplog(sleeplogs, sl_last_update) {
    // console.log("sleeplogs", sleeplogs)
    const timezone = localStorage.getItem("ADMIN_OAH_TIMEZONE");
    const timeDownloadReport = moment().utcOffset(Number(timezone));
    const timeDownloadInMinute = timeDownloadReport.hours() * 60 + timeDownloadReport.minutes();
    const nowDay = timeDownloadReport.format("YYYY_M_D");
    const today = moment().utcOffset(Number(timezone)).hour(0).minute(0).second(0);
    const nextDay = moment().utcOffset(Number(timezone)).add(1, "days").hour(0).minute(0).second(0);

    // flat events
    const result = [], vitalSign = [];
    const proceedData = {};
    const days = Object.keys(sleeplogs).sort((a, b) => moment(a, "YYYY_M_D").isAfter(moment(b, "YYYY_M_D")) ? -1 : 1);

    days.forEach((day, idx) => {
        if (days.length > 7 && idx === days.length - 1) return;

        // copy data
        const dataInThisDay = sleeplogs[day];
        const configThisDay = sleeplogs[day].config_history;

        // create data points
        const dataPoints = {};
        for (let i = 720; i < 2159; i++) {
            dataPoints[i-720] = dataInThisDay?.data && dataInThisDay?.data[i] ? dataInThisDay?.data[i] :
                {m: 0, n: 0, b: 255, h: 255, e: [], s: (moment(day, "YYYY_M_D").format("YYYY-MM-DD") !== today.format("YYYY-MM-DD") || i < timeDownloadInMinute) ? 4 : -1};
        }

        // create threshold
        const configHistory = {};
        Object.keys(configThisDay || {}).forEach(key => {
            configHistory[Number(key)] = configThisDay[key];
        });

        proceedData[days[idx]] = {
            ...sleeplogs[day[idx]],
            data: dataPoints,
            configThisDay,
            config_history: configHistory,
        };
    });

    Object.keys(proceedData)
        .sort((a, b) => moment(a, "YYYY_M_D").isAfter(moment(b, "YYYY_M_D")) ? -1 : 1)
        .forEach(key => {
            const oneDaySleeplog = proceedData[key];

            const flattedEvents = [];
            const eolFirstEvents = [];
            // total
            let total = {
                no_sleep: 0,
                sleep_time: 0,
                awake_on_bed: 0,
                tts: 0,
                ls: 0,
                ds: 0,
                off_pillow: 0,
            };

            // check data
            if (Object.keys(oneDaySleeplog).length === 0) {
                // push day by day
                // result.push({
                //     day: key,
                //     events: [],
                //     total
                // });

                // process vital sign
                // vitalSign.push({
                //     day: key,
                //     dataPointsBr: [],
                //     dataPointsHr: [],
                //     exceedEventsBr: [],
                //     exceedEventsHr: [],
                //     apneaEvents: [],
                //     config_history: {},
                //     highBpmCnt: 0,
                //     lowBpmCnt: 0,
                //     highRpmCnt: 0,
                //     lowRpmCnt: 0,
                //     apneaCnt: 0,
                //     minHr: "--",
                //     minBr: "--",
                //     maxHr: "--",
                //     maxBr: "--",
                // });

                return;
            }

            // event ls, ds
            let sleepEventName, sleepStartTime;
            let lsEvents = [];
            let dsEvents = [];
            let dataPointsBr = [];
            let dataPointsHr = [];
            for (let i = 0; i < 1440; i++) {
                dataPointsBr[i] = undefined;
                dataPointsHr[i] = undefined;
            }

            // // create data points
            const dataPoints = oneDaySleeplog?.data;

            let awake_events = [];
            let tts_events = [];
            let op_events = [];
            const lengthOfKeys = Object.keys(dataPoints || {}).length - 1;

            let previousS;
            Object.keys(dataPoints || {}).forEach((minuteKey, idx) => {
                const minuteData = dataPoints[minuteKey];

                dataPointsBr[Number(minuteKey)] = (minuteData?.s !== 1 && minuteData?.s !== 2 && (minuteData?.s !== 3 || (minuteData?.s === 3 && previousS === 4))) || minuteData?.b > 100 ? null : minuteData.b;
                dataPointsHr[Number(minuteKey)] = (minuteData?.s !== 1 && minuteData?.s !== 2 && (minuteData?.s !== 3 || (minuteData?.s === 3 && previousS === 4))) || minuteData?.h > 200 || minuteData?.h === 0 ? null : minuteData.h;

                // previousS
                previousS = minuteData?.s;

                if (idx === 0) {
                    sleepEventName = minuteData.s;
                    sleepStartTime = Number(minuteKey);
                }

                switch (minuteData.s) {
                    case 0:
                        total.awake_on_bed += 1 * 60;
                        break;
                    case 1:
                        total.ls += 1 * 60;
                        break;
                    case 2:
                        total.ds += 1 * 60;
                        break;
                    case 3:
                        total.tts += 1 * 60;
                        break;
                    case 4:
                        total.off_pillow += 1 * 60;
                        break;
                }

                if (sleepEventName !== minuteData.s && sleepEventName === 1 || (idx === lengthOfKeys && minuteData.s === 1)) {
                    lsEvents.push({
                        type: "LIGHT_SLEEP",
                        startTime: sleepStartTime * 60,
                        duration: (Number(minuteKey) - sleepStartTime) * 60,
                    });
                    sleepEventName = minuteData.s;
                    sleepStartTime = Number(minuteKey);
                } else if (sleepEventName !== minuteData.s && sleepEventName === 2 || (idx === lengthOfKeys && minuteData.s === 2)) {
                    dsEvents.push({
                        type: "DEEP_SLEEP",
                        startTime: sleepStartTime * 60,
                        duration: (Number(minuteKey) - sleepStartTime) * 60,
                    });
                    sleepEventName = minuteData.s;
                    sleepStartTime = Number(minuteKey);
                } else if (sleepEventName !== minuteData.s && sleepEventName === 3 || (idx === lengthOfKeys && minuteData.s === 3)) {
                    tts_events.push({
                        type: EVENT_NAME_MAP_TO_LIB["TTS"],
                        startTime: sleepStartTime * 60,
                        duration: (Number(minuteKey) - sleepStartTime) * 60,
                    });
                    sleepEventName = minuteData.s;
                    sleepStartTime = Number(minuteKey);
                } else if (sleepEventName !== minuteData.s && sleepEventName === 0 || (idx === lengthOfKeys && minuteData.s === 0)) {
                    awake_events.push({
                        type: EVENT_NAME_MAP_TO_LIB["AWAKE"],
                        startTime: sleepStartTime * 60,
                        duration: (Number(minuteKey) - sleepStartTime) * 60,
                    });
                    sleepEventName = minuteData.s;
                    sleepStartTime = Number(minuteKey);
                } else if (sleepEventName !== minuteData.s && sleepEventName === 4 || (idx === lengthOfKeys && minuteData.s === 4)) {
                    op_events.push({
                        type: EVENT_NAME_MAP_TO_LIB["OFF_PILLOW"],
                        startTime: sleepStartTime * 60,
                        duration: (Number(minuteKey) - sleepStartTime) * 60,
                    });
                    sleepEventName = minuteData.s;
                    sleepStartTime = Number(minuteKey);
                } else if (sleepEventName === -1 && !(minuteData.b === 0 && minuteData.h === 0 && minuteData.m === 0 && minuteData.n === 0)) {
                    sleepEventName = minuteData.s;
                    sleepStartTime = Number(minuteKey);
                }

                // if (minuteData.s === 0 && minuteData.b <= 100 && minuteData.b >= 0 && minuteData.h <= 200 && minuteData.h >= 0) {
                //     console.log("awake", minuteData)
                //     awake_events.push({
                //         type: EVENT_NAME_MAP_TO_LIB["AWAKE"],
                //         startTime: minuteKey * 60,
                //         lastTime: minuteKey * 60 + 60,
                //         duration: 60,
                //     })
                // } else if(minuteData.s === 3 && minuteData.b <= 100 && minuteData.b >= 0 && minuteData.h <= 200 && minuteData.h >= 0) {
                //     console.log("tts", minuteData)
                //     tts_events.push({
                //         type: EVENT_NAME_MAP_TO_LIB["TIME_TO_SLEEP"],
                //         startTime: minuteKey * 60,
                //         lastTime: minuteKey * 60 + 60,
                //         duration: 60,
                //     })
                // }

                minuteData.e && minuteData.e.forEach(ev => {
                    if (ev && ev.length > 1 && ev[0] === 9) {
                        eolFirstEvents.push({
                            type: EVENTS[ev[0]],
                            num: ev[0],
                            startTime: minuteKey * 60,
                            lastTime: minuteKey * 60,
                            startTimeOrigin: Number(minuteKey) < 720 ? (Number(minuteKey) + 720) * 60 : (Number(minuteKey) - 720) * 60,
                        });
                    } else if (ev && ev.length > 1 && ev[0] !== 4 && ev[0] !== 3 && ev[0] !== 1 && ev[0] !== 2) {
                        flattedEvents.push({
                            type: EVENTS[ev[0]],
                            num: ev[0],
                            startInSeconds: minuteKey * 60,
                            offsetInSeconds: minuteKey * 60,
                        });
                    }
                });
            });

            // console.log("flattedEvents", flattedEvents)
            // calculate events

            let events = [];
            let eventName, eventNum, lastTime, startTime;
            flattedEvents.forEach((ev, idx) => {
                if (idx === flattedEvents.length - 1 && ev.type?.indexOf("_START") > -1) {
                    events.push({
                        type: EVENT_NAME_MAP_TO_LIB[ev.type?.replace("_START", "")],
                        startTime: ev.offsetInSeconds,
                        // lastTime: (key === nowDay ? timeDownloadInMinute : 1439) *  60,
                        // duration: (key === nowDay ? timeDownloadInMinute : 1439) *  60 - ev.offsetInSeconds,
                        lastTime: (key === nowDay ? (timeDownloadInMinute > 720 ? timeDownloadInMinute - 720 : timeDownloadInMinute + 720) : 1439) * 60,
                        duration: (key === nowDay ? (timeDownloadInMinute > 720 ? timeDownloadInMinute - 720 : timeDownloadInMinute + 720) : 1439) * 60 - ev.offsetInSeconds,
                    });
                } else if (idx === 0 && ev.type?.indexOf("_END") > -1) {
                    events.push({
                        type: EVENT_NAME_MAP_TO_LIB[ev.type?.replace("_END", "")],
                        startTime: 0,
                        lastTime: ev.offsetInSeconds,
                        duration: ev.offsetInSeconds,
                        noCount: true,
                    });
                }

                if (ev.type?.indexOf("_START") > -1) {
                    eventName = ev.type?.replace("_START", "");
                    eventNum = ev.num;
                    lastTime = ev.offsetInSeconds;
                    startTime = ev.offsetInSeconds;
                } else if (ev.type?.indexOf("_END") > -1 && eventNum === ev.num - 1) {
                    lastTime = ev.offsetInSeconds;
                    events.push({
                        type: EVENT_NAME_MAP_TO_LIB[eventName],
                        startTime: startTime,
                        lastTime: lastTime,
                        duration: lastTime - startTime,
                    });
                    eventName = ev.type;
                    eventNum = ev.num;
                    startTime = ev.offsetInSeconds;
                }
            });

            // OFF-BED
            total.off_pillow_count = events.filter(ev => {
                return ev.type === EVENT_NAME_MAP_TO_LIB["OFF_BED"] && ev.startTime >= 6 * 3600 && ev.startTime <= 18 * 3600 && !ev.noCount;
            }).length;

            // filter awake
            // awake_events = awake_events.filter(awake => {
            //     const found = events.find(e => awake.startTime > e.startTime && awake.startTime < e.lastTime);
            //     return !found;
            // });

            // calc total for sleep states
            total.sleep_time = total.ls + total.ds;

            // calc total for events
            const apneaEvents = [];
            events.forEach(ev => {
                if (ev.type === "APNEA") {
                    if (ev.startTime < 720 * 60) {
                        ev.startTimeOrigin = ev.startTime + (720 * 60);
                    } else {
                        ev.startTimeOrigin = ev.startTime - (720 * 60);
                    }
                    apneaEvents.push(ev);
                }
                total[EVENTS_TOTAL_MAP[ev.type]] += ev.duration;
            });

            // push day by day
            result.push({
                day: key,
                events: [...events, ...awake_events, ...tts_events, ...op_events, ...lsEvents, ...dsEvents].sort((a, b) => a.startTime > b.startTime ? 1 : -1),
                total
            });

            // exceed events
            const exceedEventsBr = [], exceedEventsHr = [];
            const config_history = oneDaySleeplog?.config_history;

            const revert_keys_config_history = Object.keys(config_history).sort((a, b) => a > b ? -1 : 1);
            const brThresholdLow = [], hrThresholdLow = [], brThresholdHigh = [], hrThresholdHigh = [], vital_enabled = [];
            for (let i = 0; i < 1440; i++) {
                const findKey = revert_keys_config_history.find(k => Number(k) <= i);
                if (findKey) {
                    brThresholdLow.push(config_history[findKey].rpm_low);
                    brThresholdHigh.push(config_history[findKey].rpm_high);
                    hrThresholdLow.push(config_history[findKey].bpm_low);
                    hrThresholdHigh.push(config_history[findKey].bpm_high);
                    vital_enabled.push(config_history[findKey].vital_enabled);
                }
            }

            let flatEventBr = [], flatEventHr = [];
            dataPointsBr.forEach((d, idx) => {
                if (d !== null && d >= 0 && d < Number(brThresholdLow[idx]) && vital_enabled[idx]) {
                    flatEventBr.push({type: "LOW_RESP", startInSeconds: idx * 60, val: d, th: brThresholdLow[idx]});
                } else if (d > Number(brThresholdHigh[idx]) && Number(brThresholdHigh[idx]) !== 0 && vital_enabled[idx]) {
                    flatEventBr.push({type: "HIGH_RESP", startInSeconds: idx * 60, val: d, th: brThresholdHigh[idx]});
                }
            });
            dataPointsHr.forEach((d, idx) => {
                // console.log(d, hrThresholdLow[idx], hrThresholdHigh[idx])
                if (d !== null && d > 0 && d < Number(hrThresholdLow[idx]) && vital_enabled[idx]) {
                    flatEventHr.push({type: "LOW_PR", startInSeconds: idx * 60, val: d, th: hrThresholdLow[idx]});
                } else if (d > Number(hrThresholdHigh[idx])&& Number(hrThresholdHigh[idx]) !== 0 && vital_enabled[idx]) {
                    flatEventHr.push({type: "HIGH_PR", startInSeconds: idx * 60, val: d, th: hrThresholdHigh[idx]});
                }
            });

            // calc flatEvent
            // let brEventName, brStartTime, brLastTime;
            // flatEventBr.forEach((br, idx) => {
            //     if (idx === 0) {
            //         brEventName = br.type;
            //         brStartTime = br.startInSeconds;
            //         brLastTime = br.startInSeconds;
            //     } else if (brEventName !== br.type || br.startInSeconds - brLastTime > 60) {
            //         exceedEventsBr.push({
            //             type: brEventName,
            //             startTime: brStartTime,
            //             duration: brLastTime !== brStartTime ? brLastTime - brStartTime : 60
            //         });
            //         brEventName = br.type;
            //         brStartTime = br.startInSeconds;
            //         brLastTime = br.startInSeconds;
            //     } else if (idx === flatEventBr.length - 1) {
            //         exceedEventsBr.push({
            //             type: brEventName,
            //             startTime: brStartTime,
            //             duration: br.startInSeconds - brStartTime
            //         });
            //     } else {
            //         brLastTime = br.startInSeconds;
            //     }
            // });
            //
            // let hrEventName, hrStartTime, hrLastTime;
            // flatEventHr.forEach((hr, idx) => {
            //     if (idx === 0) {
            //         hrEventName = hr.type;
            //         hrStartTime = hr.startInSeconds;
            //         hrLastTime = hr.startInSeconds;
            //     } else if (hrEventName !== hr.type || hr.startInSeconds - hrLastTime > 60) {
            //         exceedEventsHr.push({
            //             type: hrEventName,
            //             startTime: hrStartTime,
            //             duration: hrLastTime !== hrStartTime ? hrLastTime - hrStartTime : 60
            //         });
            //         hrEventName = hr.type;
            //         hrStartTime = hr.startInSeconds;
            //         hrLastTime = hr.startInSeconds;
            //     } else if (idx === flatEventHr.length - 1) {
            //         exceedEventsHr.push({
            //             type: hrEventName,
            //             startTime: hrStartTime,
            //             duration: hr.startInSeconds - hrStartTime
            //         });
            //     } else {
            //         hrLastTime = hr.startInSeconds;
            //     }
            // });

            // count
            const highBpmCnt = flatEventBr.filter(br => br.type === "HIGH_RESP").length;
            const lowBpmCnt = flatEventBr.filter(br => br.type === "LOW_RESP").length;
            const highRpmCnt = flatEventHr.filter(br => br.type === "HIGH_PR").length;
            const lowRpmCnt = flatEventHr.filter(br => br.type === "LOW_PR").length;
            const apneaCnt = apneaEvents.filter(ev => !ev.noCount).length;

            // max, min
            let maxBr = -1, maxHr = -1, minBr = -1, minHr = -1;
            let totalBr = 0, totalPointBr = 0, aveBr, totalHr = 0, totalPointHr = 0, aveHr;
            dataPointsBr.forEach(d => {
                if (d !== null && d !== 0 && (maxBr < d || maxBr === -1) && d >= 0 && d <= 100) {
                    maxBr = d;
                }

                if (d !== null && d !== 0 && (minBr > d || minBr === -1) && d >= 0 && d <= 100) {
                    minBr = d;
                }

                if(d !== null && d !== 0 && d >= 0 && d <= 100) {
                    totalBr += d;
                    totalPointBr += 1;
                }
            });

            dataPointsHr.forEach(d => {
                if (d !== null && d !== 0 && (maxHr < d || maxHr === -1) && d >= 0 && d <= 200) {
                    maxHr = d;
                }

                if (d !== null && d !== 0 && (minHr > d || minHr === -1) && d >= 0 && d <= 200) {
                    minHr = d;
                }

                if(d !== null && d !== 0 && d >= 0 && d <= 200) {
                    totalHr += d;
                    totalPointHr += 1;
                }
            });

            // cal ave
            aveBr = totalPointBr !== 0 ? Math.round(totalBr / totalPointBr) : "--";
            aveHr = totalPointHr !== 0 ? Math.round(totalHr / totalPointHr) : "--";

            // process vital sign
            vitalSign.push({
                day: key,
                dataPointsBr,
                dataPointsHr,
                exceedEventsBr,
                exceedEventsHr,
                apneaEvents,
                config_history,
                highBpmCnt,
                lowBpmCnt,
                highRpmCnt,
                lowRpmCnt,
                apneaCnt,
                minHr,
                minBr,
                maxHr,
                maxBr,
                aveBr,
                aveHr,
                eolFirstEvents,
            })
        });

    return {sl: result, vitalSign: vitalSign};
}

const EVENTS = {
    1: "TTS_START",
    2: "TTS_END",
    3: "AWAKE_START",
    4: "AWAKE_END",
    5: "OFF_BED_START",
    6: "OFF_BED_END",
    7: "APNEA_START",
    8: "APNEA_END",
    9: "EOL",
    // 10: "EOL_END",
};

const EVENTS_TOTAL_MAP = {
    "TIME_TO_SLEEP": "tts",
    "AWAKE_ON_BED": "awake_on_bed",
    "OFF_PILLOW": "off_pillow",
};

const EVENT_NAME_MAP_TO_LIB = {
    "TTS": "TIME_TO_SLEEP",
    "SLEEP_TIME": "SLEEP_TIME",
    "AWAKE": "AWAKE_ON_BED",
    "LIGHT_SLEEP": "LIGHT_SLEEP",
    "DEEP_SLEEP": "DEEP_SLEEP",
    "APNEA": "APNEA",
    "OFF_PILLOW": "OFF_PILLOW",
    "OFF_BED": "OFF_BED",
};

export default reportReducer;