import {connect} from 'react-redux'
import {PatientTransfer} from "./PatientTransfer";
import {transferPatientAction} from "../../../redux/actions/transferPatientAction";
import {reportAction} from "../../../redux/actions/reportAction";

const mapStateToProps = state => ({
    assignedBeds: state.transferPatientReducer.assignedBeds,
    history: state.transferPatientReducer.history,
    patientMap: state.transferPatientReducer.patientMap,
    availableBeds: state.transferPatientReducer.availableBeds,
    error: state.transferPatientReducer.error
});

function mapDispatchToProps(dispatch) {
    return ({
        getListAssignedBeds: () => dispatch(transferPatientAction.getListAssignedBeds()),
        transferPatient: (info) => dispatch(transferPatientAction.transferPatient(info)),
        getTransferHistory: () => dispatch(transferPatientAction.getTransferHistory()),
        setError: (error) => dispatch(transferPatientAction.setError(error)),
        editPatient: async (update) => dispatch(reportAction.editPatient(update)),
        dischargePatient: async (patient) => dispatch(reportAction.dischargePatient(patient)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientTransfer);