import { dataProvider } from "../../connections/net";
import { appAction } from "./appAction";

export const SET_ERROR = "dashboard/action/SET_ERROR";
export const SET_STATE = "dashboard/action/SET_STATE";
export const GET_DATA = "dashboard/action/GET_DATA";
export const GET_STATUS_DATA = "dashboard/action/GET_STATUS_DATA";
export const CLEAR_DATA = "dashboard/action/CLEAR_DATA";
export const SET_POLICY_VERSION = "dashboard/action/SET_POLICY_VERSION";

export const dashboardAction = {
  getBedAllocation: () => async (dispatch) => {
    dispatch(appAction.openLoading());
    try {
      const { status_code, oah } = await dataProvider.getBedAllocation();
      if (status_code === 0 && oah) {
        // set time zone
        const { info } = oah;
        const role = sessionStorage.getItem("OMNI2_ROLE");
        localStorage.setItem(`ADMIN_OAH_TIMEZONE`, info.timezone);
        localStorage.setItem(
          `${role}_OAH_CENTRE_NAME`,
          info.display_name || info.oah_name
        );

        // dispatch data
        dispatch({ type: GET_DATA, payload: oah });
      }
    } catch (e) {
      console.log("Error:", e.message);
    }
    dispatch(appAction.closeLoading());
  },
  getBedAssignment: () => async (dispatch) => {
    dispatch(appAction.openLoading());
    try {
      const { status_code, beds } = await dataProvider.getBedAssignment();
      if (status_code === 0 && beds) {
        dispatch({ type: GET_STATUS_DATA, payload: beds });
        dispatch(appAction.reconnect(false, true));
      } else {
        dispatch(appAction.reconnect(true));
      }
    } catch (e) {
      console.log("Error: ", e.message);
      if (e.message === "Disconnect") {
        dispatch(appAction.reconnect(true));
      } else if (e.message === "Unauthorize") {
        dispatch(appAction.showLogoutModal());
      }
    }
    dispatch(appAction.closeLoading());
  },
  pollingByBedStatus: () => async (dispatch) => {
    try {
      const { status_code } = await dataProvider.authCheck();
      if (status_code === 0) {
        // dispatch({type: GET_STATUS_DATA, payload: beds});
        dispatch(appAction.reconnect(false, true));
      } else {
        dispatch(appAction.reconnect(true));
      }
    } catch (e) {
      console.log("Error: ", e.message);
      if (e.message === "Disconnect") {
        dispatch(appAction.reconnect(true));
      } else if (e.message === "Unauthorize") {
        dispatch(appAction.showLogoutModal());
      }
    }
  },
  setPolicyAckVersion:
    (version, setToServer = true) =>
    async (dispatch) => {
      dispatch(appAction.openLoading());
      try {
        if (setToServer) {
          const { status_code } = await dataProvider.setPolicyAckVersion(
            version
          );
          if (status_code === 0) {
            sessionStorage.setItem(
              `OAC_POLICY_VERSION`,
              version.policy_acked_version
            );
            dispatch({
              type: SET_POLICY_VERSION,
              payload: version.policy_acked_version,
            });
          }
        } else if (!setToServer) {
          sessionStorage.setItem(
            `OAC_POLICY_VERSION`,
            version.policy_acked_version
          );
          dispatch({
            type: SET_POLICY_VERSION,
            payload: version.policy_acked_version,
          });
        }
      } catch (e) {
        console.log("Error: ", e.message);
      }
      dispatch(appAction.closeLoading());
    },
  openPP: () => async (dispatch) => {
    dispatch({ type: SET_POLICY_VERSION, payload: -1 });
  },
  checkServerUp: () => async (dispatch) => {
    const { web_version } = await dataProvider.getVersions();
    if (web_version) {
      dispatch(appAction.reconnect(false, true));
    } else {
      dispatch(appAction.reconnect(true));
    }
  },
  setError: (error) => (dispatch) =>
    dispatch({ type: SET_ERROR, payload: error }),
  setState: (state) => (dispatch) =>
    dispatch({ type: SET_STATE, payload: state }),
  clearData: () => (dispatch) => dispatch({ type: CLEAR_DATA }),
};
