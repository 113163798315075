import {dataProvider} from "../../connections/net";
import {appAction} from "./appAction";

export const SET_ERROR = "report/action/SET_ERROR";
export const SET_STATE = "report/action/SET_STATE";
export const SET_PATIENT = "report/action/SET_PATIENT";
export const GET_DATA = "report/action/GET_DATA";
export const CLEAR_DATA = "report/action/CLEAR_DATA";
export const DISCHARGE_PATIENT = "report/action/DISCHARGE_PATIENT";
export const EXPORT_CSV = "report/action/EXPORT_CSV";
export const LIST_CSV = "report/action/LIST_CSV";

let timer, retry = 0;

export const reportAction = {
    getSleepLog: (search) => async dispatch => {
        retry = retry + 1;
        dispatch(appAction.openLoading());
        try {
            const {status_code: status_code_2, oah} = await dataProvider.getAvailableBeds();
            const {status_code, sleeplogs, sl_last_update} = await dataProvider.getSleepLog(search);
            if (status_code === 0 && status_code_2 === 0) {
                dispatch({type: GET_DATA, payload: {sleeplogs, oah, patient_id: search.patient_id, sl_last_update}});
                dispatch(appAction.closeLoading());
                retry = 0;
            } else {
                if (retry < 3) {
                    timer = setTimeout(() => {
                        dispatch(reportAction.getSleepLog(search));
                    }, 10000);
                } else {
                    dispatch(appAction.closeLoading());
                    clearTimeout(timer);
                    retry = 0;
                }
            }
        } catch (e) {
            console.log("Error:", e.stack);
        }
    },
    editPatient: (update) => async dispatch => {
        dispatch(appAction.openLoading());
        try {
            const {status_code, oah} = await dataProvider.editPatient(update);
            if (status_code === 0 && oah) {
                dispatch({type: SET_PATIENT, payload: {oah, patient_id: update.patient_id}});
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
        dispatch(appAction.closeLoading());
    },
    dischargePatient: (patient) => async dispatch => {
        dispatch(appAction.openLoading());
        try {
            const {status_code} = await dataProvider.dischargePatient(patient);
            if (status_code === 0) {
                // dispatch({type: DISCHARGE_PATIENT, payload: patient});
            }
            setTimeout(() => {
                dispatch(appAction.closeLoading());
            }, 5000);
        } catch (e) {
            console.log("Error:", e.message);
        }
        return true;
    },
    setPatient: (patient) => async dispatch => {
        dispatch({type: SET_PATIENT, payload: patient});
    },
    exportCsv: (info) => async dispatch => {
        try {
            const {status_code} = await dataProvider.exportCsv(info);
            if (status_code === 0) {
                // dispatch({type: DISCHARGE_PATIENT, payload: patient});
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
    },
    listCsv: (info) => async dispatch => {
        dispatch(appAction.openLoading());
        try {
            const {reports, status_code} = await dataProvider.listCsv(info);
            if (status_code === 0) {
                dispatch({type: LIST_CSV, payload: reports});
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
        dispatch(appAction.closeLoading());
    },
    downloadCsv: (info) => async dispatch => {
        try {
            const {status_code} = await dataProvider.downloadCsv(info);
            if (status_code === 0) {
                dispatch(reportAction.listCsv({patient_id: info.patient_id}));
            }
        } catch (e) {
            console.log("Error:", e.message);
        }
    },
    setError: (error) => dispatch => dispatch({type: SET_ERROR, payload: error}),
    setState: (state) => dispatch => dispatch({type: SET_STATE, payload: state}),
    clearData: () => dispatch => dispatch({type: CLEAR_DATA})
};