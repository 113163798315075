import {connect} from 'react-redux'
import {ShowQRCode} from "./ShowQRCode";
import {showQRCodeAction} from "../../../redux/actions/showQRCodeAction";

const mapStateToProps = state => ({
    listOfNurses: state.showQRCodeReducer.listOfNurses || [],
    qrData: state.showQRCodeReducer.qrData,
});

function mapDispatchToProps(dispatch) {
    return ({
        getListOfNurses: () => dispatch(showQRCodeAction.getListOfNurses()),
        getQRData: (nurse) => dispatch(showQRCodeAction.getQRData(nurse)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowQRCode);