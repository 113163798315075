import {connect} from 'react-redux'
import {DischargeHistory} from "./DischargeHistory";
import {dischargeAction} from "../../../redux/actions/dischargeAction";

const mapStateToProps = state => ({
    history: state.dischargeReducer.history,
});

function mapDispatchToProps(dispatch) {
    return ({
        getDischargeHistory: () => dispatch(dischargeAction.getDischargeHistory()),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(DischargeHistory);