import {dataProvider} from "../../../connections/net";

export const SET_ERROR = "setupWifiAction/action/SET_ERROR";
export const SET_STATE = "setupWifiAction/action/SET_STATE";
export const GET_ENCRYPTION_LIST = "setupWifiAction/action/GET_ENCRYPTION_LIST";
export const GET_WIFI = "setupWifiAction/action/GET_WIFI";
export const CLEAR_DATA = "setupWifiAction/action/CLEAR_DATA";

export const setupWifiAction = {
    getWifiEncryptionList: () => async dispatch => {
        try {
            const {status_code, encryption} = await dataProvider.getWifiEncryptionList();
            if (status_code === 0 && encryption) {
                dispatch({type: GET_ENCRYPTION_LIST, payload: encryption});
            }
        } catch(e) {
            console.log("Error:", e.message);
        }
    },
    getAddedWifi: () => async dispatch => {
        try {
            const {status_code, wifi} = await dataProvider.getAddedWifi();
            if (status_code === 0 && wifi) {
                dispatch({type: GET_WIFI, payload: wifi});
            }
        } catch(e) {
            console.log("Error:", e.message);
        }
    },
    addWifi: (info) => async dispatch => {
        try {
            const {status_code} = await dataProvider.addWifi(info);
            if (status_code === 0) {
                dispatch(setupWifiAction.getAddedWifi());
            }
        } catch(e) {
            console.log("Error:", e.message);
        }
    },
    deleteWifi: (info) => async dispatch => {
        try {
            const {status_code} = await dataProvider.deleteWifi(info);
            if (status_code === 0) {
                dispatch(setupWifiAction.getAddedWifi());
            }
        } catch(e) {
            console.log("Error:", e.message);
        }
    },
    setError: (error) => dispatch => dispatch({type: SET_ERROR, payload: error}),
    setState: (state) => dispatch => dispatch({type: SET_STATE, payload: state}),
    clearData: () => dispatch => dispatch({type: CLEAR_DATA})
};