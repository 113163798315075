import {connect} from 'react-redux'
import {appAction} from "../../../redux/actions/appAction";
import {liveTileAction} from "../../../redux/actions/liveTileAction";
import {TypeWrapper} from "./TypeWrapper";
import {dashboardAction} from "../../../redux/actions/dashboard";

const mapStateToProps = state => ({
    liveTileList: state.liveTileReducer.tiles,
    time: state.liveTileReducer.time,
    hasSound: state.liveTileReducer.hasSound,
    audio: state.appReducer.audio,
    isReconnect: state.appReducer.isReconnect,
    loading: state.appReducer.loading,
    versions: state.appReducer.versions,
    role: sessionStorage.getItem("OMNI2_ROLE"),
    policyAckVersion: state.dashboardReducer.policyAckVersion || sessionStorage.getItem("OAC_POLICY_VERSION"),
    clickedLiveView: state.appReducer.clickedLiveView,
});

function mapDispatchToProps(dispatch) {
    // alert(window.screen.height)
    return ({
        onOffAudio: () => {
            dispatch(appAction.onOffAudio())
        },
        playAlert: (play) => {
            dispatch(appAction.playAlert(play))
        },
        handleLogout: () => {
            dispatch(appAction.logout())
        },
        // processMessage: (message) => {
        //     dispatch(liveTileAction.processMessage(message));
        // },
        clearMessage: () => {
            dispatch(liveTileAction.clearData());
        },
        openLoading: () => {
            dispatch(appAction.openLoading());
        },
        closeLoading: () => {
            dispatch(appAction.closeLoading());
        },
        pollingData: () => dispatch(liveTileAction.pollingData()),
        turnOffSoundForTile: (info) => dispatch(liveTileAction.turnOffSoundForTile(info)),
        openPP: (version) => dispatch(dashboardAction.openPP(version)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(TypeWrapper);